import { ReactNode } from "react";
import { useDraggable } from "@dnd-kit/core";

export default function Draggable({
  children,
  id,
  isDraggable = true,
}: {
  children: ReactNode;
  id: string;
  isDraggable?: boolean;
}) {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id,
    });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: isDragging ? 9999 : 0,
      }
    : undefined;

  return (
    <div
      ref={setNodeRef}
      style={{ touchAction: "none", ...style }}
      {...(isDraggable ? listeners : {})}
      {...(isDraggable ? attributes : {})}
    >
      {children}
    </div>
  );
}
