import { animated, useSpring } from "@react-spring/web";

import cn from "../../../utils/cn";

export default function AnimatedCard({
  image,
  animationConfig,
  className,
}: {
  image: string;
  animationConfig?: any;
  className?: string;
}) {
  const animation = useSpring(animationConfig);

  return (
    <animated.div
      className={cn(
        "absolute -top-[96px] right-3 z-20 h-[122px] w-[270px]",
        className,
      )}
      style={animation}
    >
      <img src={image} alt="main card" className="h-full w-full" />
    </animated.div>
  );
}
