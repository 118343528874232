import { UserInformation } from "../../types";

/**
 * ctaExperienceTypeId: 4
 * After preview locked videos 3 times
 * Awareness, Interest
 */
export default function ctaHandler4(
  userInformation: UserInformation | null,
  lockedVideosCount: number,
  setLockedVideosCount: (count: number) => void,
  setShowingCtaETId: (data: string | null) => void
): boolean {
  const { trialUserSegmentation: seg } = userInformation || {};

  if (seg !== "Awareness" && seg !== "Interest") return false;

  const newCount = lockedVideosCount + 1;

  setLockedVideosCount(newCount);

  if (newCount % 3 === 0) {
    setShowingCtaETId("4");

    return true;
  }

  return false;
}
