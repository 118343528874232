import { Dispatch, SetStateAction, useState } from "react";
import ReactPlayer from "react-player";

import closeIcon from "../../../assets/images/demoGame/closeIcon.png";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import { GameType } from "../../../types";

export const titleMapping: {
  [key in GameType]: string;
} = {
  magicPenHome: "",
  magicPen: "Magic Pen",
  talkAlongCards: "Talk Along Card",
  QACards: "Q&A Card",
};

export const vimeoNameMapping: {
  [key in GameType]: string;
} = {
  magicPenHome: "",
  magicPen: "magicPenIntro",
  talkAlongCards: "talkAlongCardsIntro",
  QACards: "qaCardsIntro",
};

export default function DemoGameVideo({
  gameType,
  setShouldOpenIntroVideo,
}: {
  gameType: GameType;
  setShouldOpenIntroVideo: Dispatch<SetStateAction<boolean>>;
}) {
  const [isReady, setIsReady] = useState(false);

  const videoUrl = `https://service.worldfamilyenglish.com/${process.env.REACT_APP_BLOB_FOLDER}/data/wfe-demo/${vimeoNameMapping[gameType]}.mp4`;

  return (
    <div className="absolute left-0 top-0 z-50 flex h-screen w-screen flex-col bg-black">
      <div className="relative flex h-11 w-full shrink-0 items-center justify-center">
        <Touchable
          className="absolute left-5 top-2.5 size-6"
          onClick={() => setShouldOpenIntroVideo(false)}
        >
          <img src={closeIcon} alt="closeIcon" />
        </Touchable>

        <Text className="text-[11px] font-medium text-white">
          {titleMapping[gameType]}
        </Text>
      </div>

      <div className="flex w-full flex-1 items-center justify-center">
        {!isReady ? (
          <div className="absolute left-0 top-0 z-[1] h-full w-full bg-black" />
        ) : null}

        <ReactPlayer
          width="100%"
          height="100%"
          style={{ maxHeight: "calc(100vh - 44px)" }}
          url={videoUrl}
          playing
          playsinline
          controls
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
                preload: "auto",
              },
            },
          }}
          onReady={() => setIsReady(true)}
        />
      </div>
    </div>
  );
}
