export default async function generateTokenHK() {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL_BOOKDEMO_HK}/auth/token/generate`,
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: process.env.REACT_APP_HK_EMAIL,
        password: process.env.REACT_APP_HK_PASSWORD,
      }),
    },
  );

  if (!response.ok) throw new Error("Network response was not ok");

  return response.json();
}
