import { useTranslation } from "react-i18next";
import mickeyIcon from "../../../assets/images/magicPen/mickeyIcon.png";
import minnieIcon from "../../../assets/images/magicPen/minnieIcon.png";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import useStore from "../../../hooks/store/useStore";
import { MagicPenMapping } from "../../../services/EventTracking/mapping";
import useEventTracking from "../../../services/EventTracking/useEventTracking";
import { MagicPenType } from "../../../types";

export default function DisneyButton({ type }: { type: MagicPenType }) {
  const { t } = useTranslation();

  const { setSelectedGame, setSelectedPen } = useStore((state) => state.global);

  const mutation = useEventTracking();

  const image = type === "mickey" ? mickeyIcon : minnieIcon;

  const handleClick = () => {
    mutation.mutate({
      pageId: "Magic Pen",
      eventId: MagicPenMapping[type],
    });

    setSelectedPen(type);
    setSelectedGame("magicPen");
  };

  return (
    <Touchable
      className="relative flex w-[132px] items-center justify-between rounded-full border-2 border-solid border-[#0BBEFF4C] bg-white py-1 pl-6 pr-2"
      onClick={handleClick}
    >
      <Text lg className="text-skyBlue">
        {t(type)}
      </Text>

      <img
        src={image}
        alt="pen"
        className="absolute bottom-1 right-1 w-[50px]"
      />
    </Touchable>
  );
}
