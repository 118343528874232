import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import playmate from "../../../assets/images/gameShared/playmate.png";
import tutorialCard from "../../../assets/images/talkAlongCards/tutorialCard.png";
import GameHelpModel from "../../../components/game/GameHelpModel";

export default function HelpModel({
  setShowHelp,
}: {
  setShowHelp: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();

  return (
    <GameHelpModel
      title={t("talkAlongCardsHelpText")}
      setShowHelp={setShowHelp}
    >
      <div className="relative h-[196px] w-[266px]">
        <div className="blue-frame absolute right-0 top-0 h-20 w-[194px]" />
        <img
          src={playmate}
          alt="playmate"
          className="absolute left-0 top-[67px] w-[220px]"
        />
        <img
          src={tutorialCard}
          alt="tutorialCard"
          className="absolute bottom-0 left-0 w-[150px]"
        />
      </div>
    </GameHelpModel>
  );
}
