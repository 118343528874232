import { UserInformation } from "../../types";

/**
 * ctaExperienceTypeId: 1
 * Try to re-listen unlocked songs
 * Awareness
 */
export default function ctaHandler2(
  userInformation: UserInformation | null,
  songIndex: number,
  unlockedSongsCount: number[],
  setUnlockedSongsCount: (array: number[]) => void,
  setShowingCtaETId: (data: string | null) => void
): boolean {
  const { trialUserSegmentation: seg } = userInformation || {};

  if (seg !== "Awareness") return false;

  const newCount = [...unlockedSongsCount];

  if (newCount[songIndex] === undefined) {
    newCount[songIndex] = 1;
  } else {
    newCount[songIndex] += 1;
  }

  setUnlockedSongsCount(newCount);

  if (newCount[songIndex] % 2 === 0) {
    setShowingCtaETId("1");

    return true;
  }

  return false;
}
