import { MagicPenType } from "../../../types";
import {
  bubbleSize,
  cardPaddingTop,
  penLeft as pLeft,
  penTop as pTop,
} from "../constants";
import { PositionType } from "../types";

export default function isPenPointOnABubble(
  selectedPen: MagicPenType,
  penPosition: PositionType,
  top: number,
  left: number,
): boolean {
  const isMickey = selectedPen === "mickey";
  const penLeft = penPosition.x + pLeft + (isMickey ? 36 : 32);
  const penTop = penPosition.y + pTop + 183;
  const bubbleLeft = left;
  const bubbleTop = top + cardPaddingTop;

  return (
    bubbleLeft < penLeft &&
    penLeft < bubbleLeft + bubbleSize &&
    bubbleTop < penTop &&
    penTop < bubbleTop + bubbleSize
  );
}
