export default function getHeaders({
  accessKey,
  jwtToken,
}: {
  accessKey: string;
  jwtToken: string;
}): HeadersInit {
  return {
    accept: "text/plain",
    Authorization: `Bearer ${jwtToken}`,
    "X-Project-Id": "TRIALMODE",
    "X-Project-Key":
      "&TYwdKvPHjVQpzeVJVTkt$PQQ72jw%xuUFLU%QbXxLQ#48gc#yinMk44cWc88N4TZhTFLWcKT9h@Hi^*r%$9a*ePU2LE6Cxz^m%4jp^ex2hxiPv6CTBdhHv4fHAW3Ef8",
    "X-Authorization-Provider": "WorldFamily",
    "X-Access-Key": accessKey,
  };
}
