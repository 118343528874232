import q1 from "../../../assets/audios/magicPen/P16-17_A18_L24.mp3";
import a1 from "../../../assets/audios/magicPen/P16-17_A64_L25_DO20008 copy.mp3";
import q2 from "../../../assets/audios/magicPen/P16-17_MN20042 copy_A22_L31.mp3";
import a2 from "../../../assets/audios/magicPen/P16-17_A77_L32_MN20019 copy.mp3";
import q3 from "../../../assets/audios/magicPen/P16-17_A27_L38.mp3";
import a3 from "../../../assets/audios/magicPen/P16-17_A70_L39_DE20004.mp3";
import q4 from "../../../assets/audios/magicPen/P16-17_A29_L42.mp3";
import a4 from "../../../assets/audios/magicPen/P16-17_A62_L43_MK20008 copy.mp3";
import q5 from "../../../assets/audios/magicPen/P16-17_GR20022a_03kpr_A31_L46.mp3";
import a5 from "../../../assets/audios/magicPen/P16-17_A41_L48_GR20023F.mp3";
import q6 from "../../../assets/audios/magicPen/P16-17_GO20019_A63_L82.mp3";
import a6 from "../../../assets/audios/magicPen/P16-17_A42_L60_GO20007.mp3";
import q7 from "../../../assets/audios/magicPen/P16-17_DA20035 copy_A65_L87.mp3";
import a7 from "../../../assets/audios/magicPen/P16-17_A30_L45_DA20036 copy.mp3";
import q8 from "../../../assets/audios/magicPen/P16-17_CL20016_A68_L93.mp3";
import a8 from "../../../assets/audios/magicPen/P16-17_A26_L37_CL20006 copy.mp3";
import q9 from "../../../assets/audios/magicPen/P16-17_A69_L96.mp3";
import a9 from "../../../assets/audios/magicPen/P16-17_A24_L35_LO20003 copy.mp3";

export const penTop = 90;
export const penLeft = 40;
export const bubbleSize = 84;

const originalMagicPenGameData = [
  {
    question: {
      audio: q1,
      top: 37,
      left: 200,
      text: "Donald wants to fish. He needs a fishing pole.",
    },
    answer: {
      audio: a1,
      top: 216,
      left: 548,
      text: "A fishing pole. Aw, thank you!",
    },
  },
  {
    question: {
      audio: q2,
      top: 117,
      left: 153,
      text: "I want to dry off. Minnie needs a towel.",
    },
    answer: {
      audio: a2,
      top: 90,
      left: 426,
      text: "A towel. Oh, thank you!",
    },
  },
  {
    question: {
      audio: q3,
      top: 198,
      left: 81,
      text: "Dewey is digging a hole with his hands. He needs a shovel.",
    },
    answer: {
      audio: a3,
      top: 68,
      left: 685,
      text: "A shovel. Thank you!",
    },
  },
  {
    question: {
      audio: q4,
      top: 211,
      left: 155,
      text: "Mickey wants to light the fire. He needs a match.",
    },
    answer: {
      audio: a4,
      top: 312,
      left: 557,
      text: "A match. Thanks, pal!",
    },
  },
  {
    question: {
      audio: q5,
      top: 235,
      left: 259,
      text: "I want to eat soup. Grandma Duck needs a spoon.",
    },
    answer: {
      audio: a5,
      top: 403,
      left: 252,
      text: "A spoon. Thank you, dear!",
    },
  },
  {
    question: {
      audio: q6,
      top: 202,
      left: 472,
      text: "I'm going to eat spagetti. Goofy needs a fork.",
    },
    answer: {
      audio: a6,
      top: 396,
      left: 273,
      text: "A fork. Gawrsh! Thank you!",
    },
  },
  {
    question: {
      audio: q7,
      top: 229,
      left: 623,
      text: "I want to cut up the chicken. Daisy needs a knife.",
    },
    answer: {
      audio: a7,
      top: 275,
      left: 175,
      text: "A knife. Thank you!",
    },
  },
  {
    question: {
      audio: q8,
      top: 97,
      left: 618,
      text: "I'm going to chop wood. Clarabelle needs a hatchet.",
    },
    answer: {
      audio: a8,
      top: 214,
      left: 202,
      text: "A hatchet. Thank you!",
    },
  },
  {
    question: {
      audio: q9,
      top: 106,
      left: 674,
      text: "Louie is carrying water with his hands. He needs a bucket.",
    },
    answer: {
      audio: a9,
      top: 144,
      left: 295,
      text: "A bucket. Thanks!",
    },
  },
];

const halfBubbleSize = bubbleSize / 2;
const originalBoardWidth = 785;
const originalBoardHeight = 493;
const boardWidth = 600;
const boardHeight = 365;

function calcTop(n: number) {
  return (
    ((n + halfBubbleSize) * boardHeight) / originalBoardHeight - halfBubbleSize
  );
}

function calcLeft(n: number) {
  return (
    ((n + halfBubbleSize) * boardWidth) / originalBoardWidth - halfBubbleSize
  );
}

export const magicPenGameData = originalMagicPenGameData.map(
  ({ question, answer }) => {
    /**
     * 132 = dialog:40 + gap:32 + padding:60
     * 60 = padding:60
     */
    return {
      question: {
        ...question,
        top: calcTop(question.top) + 132,
        left: calcLeft(question.left) + 60,
      },
      answer: {
        ...answer,
        top: calcTop(answer.top) + 132,
        left: calcLeft(answer.left) + 60,
      },
    };
  },
);
