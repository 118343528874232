import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useStore from "../../hooks/store/useStore";
import useEventTracking from "../../services/EventTracking/useEventTracking";
import { MenuTypeMapping } from "../../services/EventTracking/mapping";
import cn from "../../utils/cn";
import { MenuItemType } from "../../types";
import Text from "../ui/Text";
import Touchable from "../ui/Touchable";
import scrollMenuItem from "./utils/scrollMenuItem";
import { menuItemData, actionUserMenuItemData } from "./constants";

export default function Menu({
  selectedMenuItem,
  setSelectedMenuItem,
}: {
  selectedMenuItem: MenuItemType;
  setSelectedMenuItem: Dispatch<SetStateAction<MenuItemType>>;
}) {
  const { t } = useTranslation();

  const [canScroll, setCanScroll] = useState<boolean>(true);

  const navRef = useRef<HTMLElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);

  const { userInformation } = useStore((state) => state.user);
  const { trialUserSegmentation } = userInformation || {};

  const mutation = useEventTracking();

  useEffect(() => {
    if (trialUserSegmentation)
      scrollMenuItem(menuRef, selectedMenuItem, trialUserSegmentation);
  }, [selectedMenuItem, trialUserSegmentation]);

  useEffect(() => {
    if (menuRef.current && navRef.current) {
      const newCanScroll =
        menuRef.current.scrollWidth > navRef.current.clientWidth;

      setCanScroll(newCanScroll);
    }
  }, []);

  const menuItems =
    trialUserSegmentation === "Action" ? actionUserMenuItemData : menuItemData;
  const navStyle = canScroll
    ? "hide-scrollbar overflow-y-auto"
    : "flex justify-center";

  return (
    <nav ref={navRef} className={cn(navStyle, "w-full")}>
      <ul ref={menuRef} className="flex w-fit gap-1">
        {menuItems.map(({ type, icon }) => {
          const selectedStyle = type === selectedMenuItem ? "bg-pink2" : null;

          return (
            <Touchable
              key={type}
              className={cn(
                "flex flex-none items-center gap-1 rounded-3xl px-3 py-2 duration-300 ease-in-out",
                selectedStyle,
              )}
              onClick={() => {
                mutation.mutate({
                  pageId: "Experience Menu",
                  eventId: MenuTypeMapping[type],
                });
                setSelectedMenuItem(type);
              }}
            >
              <img className="size-6" src={icon} alt={type} />

              <Text className="text-nowrap">{t(type)}</Text>
            </Touchable>
          );
        })}
      </ul>
    </nav>
  );
}
