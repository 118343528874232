import { TACardType } from "../../../../types";
import { tacAudioData } from "../../../talkAlongCards/constants";
import { TACGameState } from "../../../talkAlongCards/types";
import DemoGameDialog from "../../components/DemoGameDialog";

export default function TACDialog({
  currentCard,
  gameState,
  backAudioIndex,
}: {
  currentCard: TACardType | null;
  gameState: TACGameState;
  backAudioIndex: number;
}) {
  if (
    currentCard === null ||
    (gameState !== "frontPlaying" &&
      gameState !== "waitingForFlipping" &&
      gameState !== "backPlaying" &&
      gameState !== "waitingForReplay")
  )
    return null;

  const { frontAudio, backAudio } = tacAudioData[currentCard];
  const { text } =
    gameState === "frontPlaying" || gameState === "waitingForFlipping"
      ? frontAudio
      : backAudio[backAudioIndex];

  return <DemoGameDialog text={text} className="relative z-20" />;
}
