export const bgColorMapping: {
  [key: string]: string;
} = {
  "Sing Along! 1": "bg-blue",
  "Sing Along! 2": "bg-blue",
  "Sing Along! 3": "bg-blue",
  "Sing Along! 4": "bg-green",
  "Sing Along! 5": "bg-green",
  "Sing Along! 6": "bg-green",
  "Sing Along! 7": "bg-lightGreen",
  "Sing Along! 8": "bg-lightGreen",
  "Sing Along! 9": "bg-lightGreen",
  "Play Along! 1": "bg-lightBlue",
  "Play Along! 2": "bg-lightBlue",
  "Play Along! 3": "bg-lightBlue",
};
