import Event from "../../pages/event/Event";
import Listen from "../../pages/listen/Listen";
import Watch from "../../pages/watch/Watch";
import Playing from "../../pages/playing/Playing";
import StoryHome from "../../pages/story/StoryHome";
import { MenuItemType } from "../../types";

export const pageMapping: {
  [key in MenuItemType]: React.ReactNode;
} = {
  event: <Event />,
  listen: <Listen />,
  watch: <Watch />,
  playing: <Playing />,
  story: <StoryHome />,
};
