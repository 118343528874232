import { Dispatch, SetStateAction } from "react";
import { LanguageType } from "../../types";

export default function importFont(
  language: LanguageType,
  setFontLoaded: Dispatch<SetStateAction<boolean>>,
) {
  const href =
    language === "zh-HK" || language === "zh-TW"
      ? "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600;700&display=swap"
      : "https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;600;700&display=swap";

  // avoid creating duplicate links
  const existingLink = document.querySelector(`link[href="${href}"]`);

  if (existingLink) {
    setFontLoaded(true);
  } else {
    const link = document.createElement("link");

    link.href = href;
    link.rel = "stylesheet";
    link.onload = () => setFontLoaded(true);

    document.head.appendChild(link);
  }
}
