import Touchable from "../../components/ui/Touchable";
import Text from "../../components/ui/Text";
import useStore from "../../hooks/store/useStore";
import useEventTracking from "../../services/EventTracking/useEventTracking";
import cn from "../../utils/cn";
import openDownloadPage from "../webLogin/utils/openDownloadPage";
import { BookButtonWebHeight } from "./constants";

export default function BookButtonWeb({
  leftButtonText,
  rightButtonText,
  onClick,
}: {
  leftButtonText: string;
  rightButtonText: string;
  onClick?: () => void;
}) {
  const { userInformation } = useStore((state) => state.user);
  const { market } = userInformation || {};

  const mutation = useEventTracking();

  const handleEventTracking = () => {
    mutation.mutate({
      pageId: "App Download",
      eventId: "Bottom Button",
    });
  };

  const handleDownloadClick = () => {
    handleEventTracking();

    if (market) openDownloadPage(market);
  };

  const buttonStyle = "flex w-1/2 items-center justify-center";

  return (
    <div className="fixed bottom-0 left-0 z-[5] flex w-screen max-w-[480px] xs:left-1/2 xs:-translate-x-1/2 xs:transform">
      <Touchable
        className={cn(
          buttonStyle,
          BookButtonWebHeight,
          "rounded-tl-2xl bg-disneyGold",
        )}
        style={{ boxShadow: "-2px 2px 32px rgba(29, 29, 29, 0.10)" }}
        onClick={handleDownloadClick}
      >
        <Text className="text-nowrap text-white">{leftButtonText}</Text>
      </Touchable>

      <Touchable
        className={cn(
          buttonStyle,
          BookButtonWebHeight,
          "rounded-tr-2xl bg-white/25 backdrop-blur",
        )}
        style={{ boxShadow: "2px 2px 32px rgba(29, 29, 29, 0.10)" }}
        onClick={onClick}
      >
        <Text className="text-nowrap text-black8">{rightButtonText}</Text>
      </Touchable>
    </div>
  );
}
