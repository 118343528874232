import { Dispatch, SetStateAction, useState } from "react";

import VideoModel from "./VideoModel";

export default function VimeoVideo({
  videoTitle,
  videoUrl,
  setShowVideo,
}: {
  videoTitle: string;
  videoUrl: string;
  setShowVideo: Dispatch<SetStateAction<boolean>>;
}) {
  const [isReady, setIsReady] = useState(false);

  return (
    <div>
      <VideoModel
        videoTitle={videoTitle}
        videoUrl={videoUrl}
        setShowVideo={setShowVideo}
      >
        {!isReady ? (
          <div className="absolute left-0 top-0 z-[1] h-full w-full bg-black" />
        ) : null}

        <iframe
          title={videoTitle}
          src={`${videoUrl}&autoplay=1`}
          width="100%"
          className="aspect-[16/9]"
          allow="autoplay; fullscreen"
          allowFullScreen
          onLoad={() => setIsReady(true)}
        />
      </VideoModel>
    </div>
  );
}
