import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

import page1 from "../../../assets/images/story/page1.png";
import page2 from "../../../assets/images/story/page2.png";
import Touchable from "../../../components/ui/Touchable";
import cn from "../../../utils/cn";

export default function FlipBook() {
  const [page, setPage] = useState(1);
  const [isFlipping, setIsFlipping] = useState(false);

  const handleFlipping = () => {
    setIsFlipping(true);

    setTimeout(() => {
      setIsFlipping(false);
    }, 800);
  };

  const prevImg = () => {
    setPage(1);
    handleFlipping();
  };

  const nextImg = () => {
    setPage(2);
    handleFlipping();
  };

  const rotateY = page === 1 ? 0 : -180;
  const activeStyle = "bg-[#FED241] text-black8";
  const inactiveStyle = "bg-[#959191]/30 text-[#959191]";

  return (
    <>
      {/* 543 = pt:88 + height:420 + pb:35 */}
      <div className="hide-scrollbar pointer-events-none absolute left-0 top-0 h-[543px] w-screen overflow-auto pl-4 pt-[88px]">
        <div className="flip-container">
          <div className="flip-page" id="first">
            <div className="flip-back">
              <div className="flip-outer">
                <div className="flip-content">
                  <img
                    src={page1}
                    alt="page1"
                    className="rounded-bl-lg rounded-tl-lg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="flip-page"
            id="second"
            style={{
              transform: `rotateY(${rotateY}deg)`,
            }}
          >
            <div className="flip-front">
              <div className="flip-outer">
                <div className="flip-content">
                  <img
                    src={page1}
                    alt="page1"
                    className="rounded-bl-lg rounded-tl-lg"
                  />
                </div>
              </div>
            </div>
            <div className="flip-back" id="third">
              <div className="flip-outer">
                <div className="flip-content">
                  <div className="helper-class-to-make-bug-visbile">
                    <img
                      src={page2}
                      alt="page2"
                      className="rounded-bl-lg rounded-tl-lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-page" id="fourth">
            <div className="flip-front">
              <div className="flip-outer">
                <div className="flip-content">
                  <img
                    src={page2}
                    alt="page2"
                    className="rounded-bl-lg rounded-tl-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 524 = pt:88 + height:420 + pb:16 */}
      <div className="absolute left-[278px] top-[524px] z-10 flex gap-3">
        <Touchable
          className={cn(
            page === 2 && !isFlipping ? activeStyle : inactiveStyle,
            "flex size-9 items-center justify-center rounded-full",
          )}
          onClick={() => prevImg()}
        >
          <ChevronLeft />
        </Touchable>
        <Touchable
          className={cn(
            page === 1 && !isFlipping ? activeStyle : inactiveStyle,
            "flex size-9 items-center justify-center rounded-full",
          )}
          onClick={() => nextImg()}
        >
          <ChevronRight />
        </Touchable>
      </div>
    </>
  );
}
