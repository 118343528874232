import { RefObject } from "react";

import Image from "../../../components/ui/Image";
import Text from "../../../components/ui/Text";
import cn from "../../../utils/cn";
import { SongDataType } from "../types";

export default function Display({
  audioRef,
  progressBarRef,
  currentSong,
  showLyrics,
  repeat,
  handleNext,
  handleEnd,
}: {
  audioRef: RefObject<HTMLAudioElement>;
  progressBarRef: RefObject<HTMLInputElement>;
  currentSong: SongDataType;
  showLyrics: boolean;
  repeat: boolean;
  handleNext: () => void;
  handleEnd: () => void;
}) {
  const onLoadedMetadata = () => {
    const { duration } = audioRef?.current || { duration: 0 };

    if (progressBarRef?.current) progressBarRef.current.max = String(duration);
  };

  const {
    audio: { fileUrl },
    thumbnailUrl,
    categoryName,
    songTitle,
  } = currentSong;
  const imgStyle = showLyrics ? "h-16 mr-4" : "h-52";
  const titleStyle = showLyrics ? "w-full opacity-100" : "w-0 opacity-0";

  return (
    <div className="flex w-full items-center justify-center">
      <audio
        src={fileUrl}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onEnded={repeat ? handleNext : handleEnd}
      />

      <Image
        className={cn(
          imgStyle,
          "flex-none rounded-lg duration-300 ease-in-out",
        )}
        src={thumbnailUrl}
        alt={categoryName}
      />

      <div
        className={cn(titleStyle, "overflow-hidden duration-300 ease-in-out")}
      >
        <Text lg>{songTitle}</Text>
        <Text normal className="text-black6">
          {categoryName}
        </Text>
      </div>
    </div>
  );
}
