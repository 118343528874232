import { format } from "date-fns";
import { UTCDate } from "@date-fns/utc";

export default function getBodyKR(name: string, phone: string) {
  const today = new UTCDate();
  const formattedDate = format(today, "yyyy-MM-dd");
  const formattedTime = format(today, "hh:mm aa");
  const formData = new URLSearchParams();

  formData.append(
    "data.json",
    JSON.stringify({
      time_submitted: [`${formattedTime} UTC`],
      page_uuid: [""],
      MobilePhone: [phone],
      CustomerName: [name],
      Address1: [""],
      Address2: [""],
      KAddress: [""],
      ZIP: [""],
      page_name: [""],
      ROAD_CD: [""],
      date_submitted: [formattedDate],
      SampleKind: ["10"],
      EMD_CD: [""],
      ChildName: [""],
      ChildBirthdate: [""],
      ChildName2: [""],
      ChildBirthdate2: [""],
      ChildName3: [""],
      ChildBirthdate3: [""],
      DeviceType: [""],
      BULD_NO: [""],
      SGG_CD: [""],
      mdsource: ["M00143_TMA_Demo"],
      HowYouKnowDWE: [""],
      ip_address: ["1.1.1.1"],
      page_url: [""],
      message: [""],
      requestType: ["TMA_Demo"],
      variant: [""],
    }),
  );

  return formData;
}
