import { QACardType } from "../../../../types";
import { QACGameState } from "../../../qaCards/types";

export function isRect(card: QACardType) {
  return card === "qa39";
}

export function showPlaymate(gameState: QACGameState) {
  return (
    gameState === "waitingForFlipping" ||
    gameState === "waitingForDragging" ||
    gameState === "waitingForNewDragging" ||
    gameState === "frontPlaying" ||
    gameState === "backPlaying"
  );
}

export function isWaiting(gameState: QACGameState) {
  return (
    gameState === "waitingForDragging" ||
    gameState === "waitingForNewDragging" ||
    gameState === "waitingForFlipping" ||
    gameState === "waitingForRotating"
  );
}

export function showBackText(gameState: QACGameState) {
  return (
    gameState === "backPlaying" ||
    gameState === "cardCentering" ||
    gameState === "waitingForNewDragging"
  );
}
