import { Dispatch, SetStateAction } from "react";

import { CarouselItem } from "../../../components/shadcn/carousel";
import Card from "../../../components/ui/Card";
import Image from "../../../components/ui/Image";
import useEventTracking from "../../../services/EventTracking/useEventTracking";
import { getVideoUrl } from "../utils/getVideoUrl";
import { FeaturedEventDataType } from "../types";

export default function FeaturedEventCard({
  data,
  index,
  setCurrentVideoIndex,
}: {
  data: FeaturedEventDataType;
  index: number;
  setCurrentVideoIndex: Dispatch<SetStateAction<number>>;
}) {
  const { navigationUrl, imageUrl } = data;
  const videoUrl = getVideoUrl(navigationUrl);

  const mutation = useEventTracking();

  return (
    <CarouselItem>
      <Card
        url={navigationUrl}
        className="relative aspect-[35/19] w-full"
        isLink={!videoUrl}
        onClick={() => {
          mutation.mutate({
            pageId: "Featured Event",
            eventId: navigationUrl,
          });

          if (videoUrl) setCurrentVideoIndex(index);
        }}
      >
        <Image src={imageUrl} alt="featured events" className="centered-img" />
      </Card>
    </CarouselItem>
  );
}
