import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "lucide-react";

import headerBackground from "../../assets/images/menu/background2x430x122.png";
import Text from "../../components/ui/Text";
import Menu from "../../components/menu/Menu";
import Touchable from "../../components/ui/Touchable";
import useCtaStore from "../../hooks/store/useCtaStore";
import useStore from "../../hooks/store/useStore";
import CaringCta from "../../pages/caringCta/CaringCta";
import DisneyMenu from "../../pages/disneyMenu/DisneyMenu";
import DisneyMenuWeb from "../../pages/disneyMenu/DisneyMenuWeb";
import { appBgColorMapping } from "../../styles/constants";
import cn from "../../utils/cn";
import ctaHandler8, {
  IntervalMsTime,
  hasCtaTimer,
} from "../../utils/ctaHandlers/ctaHandler8";
import ctaHandler12to14 from "../../utils/ctaHandlers/ctaHandler12to14";
import { MenuItemType, SourceType } from "../../types";
import { pageMapping } from "./constants";

export default function HomeLayout({
  selectedMenuItem,
  setSelectedMenuItem,
  showDisneyMenu,
  setShowDisneyMenu,
}: {
  selectedMenuItem: MenuItemType;
  setSelectedMenuItem: Dispatch<SetStateAction<MenuItemType>>;
  showDisneyMenu: boolean | null;
  setShowDisneyMenu: Dispatch<SetStateAction<boolean | null>>;
}) {
  const { t } = useTranslation();

  const { showingCtaETId, setShowingCtaETId } = useCtaStore(
    (state) => state.cta,
  );
  const {
    ctaIntervalCount,
    setCtaIntervalCount,
    ctaIntervalBlock,
    ctaIntervalDisplayCount,
    setCtaIntervalDisplayCount,
  } = useCtaStore((state) => state.ctaInterval);

  const { source } = useStore((state) => state.global);
  const { showBookDemoDrawer, showBookDemoThanks, showWannaUnlockModel } =
    useStore((state) => state.bookDemo);
  const { showAppDownloadModel } = useStore((state) => state.webVersion);
  const { userInformation } = useStore((state) => state.user);
  const shouldSetTimer = hasCtaTimer(userInformation);

  useEffect(() => {
    if (shouldSetTimer) {
      const handleTouch = () => {
        if (ctaIntervalCount !== 0) setCtaIntervalCount(0);
      };

      window.addEventListener("touchstart", handleTouch);

      return () => {
        window.removeEventListener("touchstart", handleTouch);
      };
    }
  }, [ctaIntervalCount, setCtaIntervalCount, shouldSetTimer]);

  useEffect(() => {
    if (shouldSetTimer) {
      const interval = setInterval(() => {
        ctaHandler8(
          ctaIntervalBlock,
          ctaIntervalCount,
          setCtaIntervalCount,
          showingCtaETId,
          setShowingCtaETId,
          ctaIntervalDisplayCount,
          setCtaIntervalDisplayCount,
          showBookDemoDrawer,
          showBookDemoThanks,
          showWannaUnlockModel,
          showAppDownloadModel,
        );
      }, IntervalMsTime);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    shouldSetTimer,
    ctaIntervalBlock,
    ctaIntervalCount,
    setCtaIntervalCount,
    showingCtaETId,
    setShowingCtaETId,
    ctaIntervalDisplayCount,
    setCtaIntervalDisplayCount,
    showBookDemoDrawer,
    showBookDemoThanks,
    showWannaUnlockModel,
    showAppDownloadModel,
  ]);

  useEffect(() => {
    ctaHandler12to14(userInformation, setShowingCtaETId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const touchAction =
    showingCtaETId ||
    showBookDemoDrawer ||
    showBookDemoThanks ||
    showWannaUnlockModel ||
    showAppDownloadModel
      ? "touch-none"
      : "";

  if (source === SourceType.DemoApp) return <div>Page Not Found</div>;

  if (showDisneyMenu)
    return (
      <>
        {source === SourceType.ClubApp ? (
          <DisneyMenu
            setSelectedMenuItem={setSelectedMenuItem}
            setShowDisneyMenu={setShowDisneyMenu}
          />
        ) : (
          <DisneyMenuWeb
            setSelectedMenuItem={setSelectedMenuItem}
            setShowDisneyMenu={setShowDisneyMenu}
          />
        )}

        {showingCtaETId ? (
          <CaringCta ctaExperienceTypeId={showingCtaETId} />
        ) : null}
      </>
    );

  return (
    <div
      className={cn(
        appBgColorMapping[selectedMenuItem],
        "relative flex min-h-screen w-full justify-center",
      )}
    >
      <div className="relative z-10 flex min-h-screen w-screen min-w-[320px] max-w-[480px] flex-col">
        <div
          className="flex h-[122px] w-full flex-col-reverse bg-cover bg-center bg-no-repeat px-6 pb-[26px]"
          style={{ backgroundImage: `url(${headerBackground})` }}
        >
          <div>
            <div className="relative mb-2.5 flex h-11 items-center justify-center">
              {source === SourceType.Web ? (
                <Touchable
                  className="absolute -left-5 top-2.5 "
                  onClick={() => {
                    const queryParams = window.location.search;

                    window.location.href = `/${queryParams}`;
                  }}
                >
                  <ChevronLeft className="size-6 text-orange" />
                </Touchable>
              ) : null}

              <Text lg>{t("digitalExperience")}</Text>
            </div>

            <Menu
              selectedMenuItem={selectedMenuItem}
              setSelectedMenuItem={setSelectedMenuItem}
            />
          </div>
        </div>

        <div className={cn("flex flex-1 flex-col", touchAction)}>
          {pageMapping[selectedMenuItem]}
        </div>

        {showingCtaETId ? (
          <CaringCta ctaExperienceTypeId={showingCtaETId} />
        ) : null}
      </div>
    </div>
  );
}
