export default function convertCharacterSrc(url: string): string {
  if (url.includes(".png")) return url;

  if (url.includes("FroggyBunny?"))
    return url.replace("FroggyBunny?", "FroggyBunny-conversation.png?");
  if (url.includes("Coco?")) return url.replace("Coco?", "Coco-encourage.png?");
  if (url.includes("Zippy?")) return url.replace("Zippy?", "Zippy-Crown.png?");

  return url;
}
