import { useTranslation } from "react-i18next";

import reverse from "../../../assets/images/gameShared/reverse.svg";
import rotatePentagon from "../../../assets/images/qaCards/rotatePentagon.png";
import GameButton from "../../../components/game/GameButton";
import { QACGameState } from "../types";

export default function ButtonHandler({
  gameState,
  onFlip,
  onRotate,
  onOk,
}: {
  gameState: QACGameState;
  onFlip: () => void;
  onRotate: () => void;
  onOk: () => void;
}) {
  const { t } = useTranslation();

  if (gameState === "waitingForFlipping")
    return (
      <GameButton
        blue
        image={reverse}
        text={t("flipCard")}
        onClick={onFlip}
        className="absolute top-[100px]"
      />
    );

  if (gameState === "waitingForRotating") {
    return (
      <div className="absolute top-[100px] flex gap-5">
        <GameButton
          image={rotatePentagon}
          text={t("rotateCard")}
          onClick={onRotate}
        />
        <GameButton blue text={t("ok")} onClick={onOk} />
      </div>
    );
  }

  return null;
}
