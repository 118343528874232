import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import Text from "../../../components/ui/Text";
import cn from "../../../utils/cn";
import lyricsScrollHandler from "./utils/lyricsScrollHandler";
import parseLrc from "./utils/parseLrc";
import LyricsLoader from "./LyricsLoader";
import LyricsError from "./LyricsError";
import { LrcDataType } from "./types";

export default function Lyrics({
  lrc,
  currentMillisecond,
  isLoading,
  isError,
  currentLyricIndex,
  setCurrentLyricIndex,
}: {
  lrc: string;
  currentMillisecond: number;
  isLoading: boolean;
  isError: boolean;
  currentLyricIndex: number;
  setCurrentLyricIndex: Dispatch<SetStateAction<number>>;
}) {
  const [lyrics, setLyrics] = useState<LrcDataType[]>([]);

  const lyricsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLyrics(parseLrc(lrc));
    setCurrentLyricIndex(-1);
  }, [lrc, setCurrentLyricIndex]);

  useEffect(() => {
    lyricsScrollHandler(
      lyricsRef,
      lyrics,
      currentMillisecond,
      currentLyricIndex,
      setCurrentLyricIndex,
    );
  }, [lyrics, currentMillisecond, currentLyricIndex, setCurrentLyricIndex]);

  if (isLoading) return <LyricsLoader />;
  if (isError) return <LyricsError />;

  return (
    <div
      ref={lyricsRef}
      className="hide-scrollbar flex flex-col gap-6 overflow-y-auto pb-8"
      style={{ height: "calc(100vh - 421px)" }}
    >
      {lyrics.map((lyric, index) => {
        const style =
          currentLyricIndex === index ? "text-yellow" : "text-white";
        const marks = currentLyricIndex > index ? "played-lyric" : "";

        return (
          <Text key={index} className={cn(style, marks, "text-3xl")}>
            {lyric.text}
          </Text>
        );
      })}
    </div>
  );
}
