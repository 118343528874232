import f32 from "../../../assets/images/qaCards/f32.png";
import f34 from "../../../assets/images/qaCards/f34.png";
import f39 from "../../../assets/images/qaCards/f39.png";
import groove1 from "../../../assets/images/qaCards/groove1.png";
import groove2 from "../../../assets/images/qaCards/groove2.png";
import Draggable from "../../../components/game/Draggable";
import { QACardType } from "../../../types";
import { isWaiting } from "../utils/judgements";
import { QACGameState } from "../types";

export default function CardPanel({
  gameState,
  currentCard,
}: {
  gameState: QACGameState;
  currentCard: QACardType | null;
}) {
  const isWaitingPhase = isWaiting(gameState);

  return (
    <div className="relative flex h-[184px] w-full flex-none justify-center">
      <div className="ml-4 mt-7 flex h-[184px] w-[390px] gap-2">
        <img
          src={groove1}
          alt="groove1"
          className="absolute left-[15px] top-[45px] h-[92px] w-[96px] rotate-[-41deg]"
        />
        {currentCard !== "qa34" ? (
          <Draggable id="qa34" isDraggable={isWaitingPhase}>
            <img
              src={f34}
              alt="f34"
              className="relative -left-1.5 top-4 z-10 h-[92px] w-[96px] rotate-[-22deg]"
            />
          </Draggable>
        ) : (
          <div className="relative -left-1.5 top-4 z-10 h-[92px] w-[96px]" />
        )}

        <img
          src={groove2}
          alt="groove2"
          className="absolute left-[124px] top-[25px] size-20 rotate-[-1deg]"
        />
        {currentCard !== "qa39" ? (
          <Draggable id="qa39" isDraggable={isWaitingPhase}>
            <img
              src={f39}
              alt="f39"
              className="relative -top-1 left-0.5 z-10 size-20 rotate-[1deg]"
            />
          </Draggable>
        ) : (
          <div className="relative -top-1 left-0.5 z-10 size-20" />
        )}

        <img
          src={groove1}
          alt="groove1"
          className="absolute left-[222px] top-[27px] h-[92px] w-[96px]"
        />
        {currentCard !== "qa32" ? (
          <Draggable id="qa32" isDraggable={isWaitingPhase}>
            <img
              src={f32}
              alt="f32"
              className="relative -top-1 left-3 z-10 h-[92px] w-[96px] rotate-[20deg]"
            />
          </Draggable>
        ) : (
          <div className="relative -top-1 left-3 z-10 h-[92px] w-[96px]" />
        )}
      </div>
    </div>
  );
}
