import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import VimeoPlayer from "@vimeo/player";

import playIcon from "../../../assets/images/watch/play.png";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import VimeoVideo from "../../../components/ui/VimeoVideo";
import useStore from "../../../hooks/store/useStore";
import { VideoDataType } from "../types";

export default function MainCard({
  firstVideo,
  currentVideo,
}: {
  firstVideo: VideoDataType;
  currentVideo: VideoDataType | null;
}) {
  const { t } = useTranslation();

  const iframeRef = useRef(null);

  const { userInformation } = useStore((state) => state.user);
  const { trialUserSegmentation: seg } = userInformation || {};

  const { videoId, videoTitle, videoUrl, introVideoUrl } =
    currentVideo || firstVideo;

  const mainRef = useRef<HTMLDivElement>(null);

  const [showButton, setShowButton] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [isStickyMode, setIsStickyMode] = useState(false);

  const stickyStyle = isStickyMode ? "sticky top-0 z-10 pb-5 bg-pink1" : "mb-4";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleVideoEnd = () => {
    if (seg === "Awareness" || seg === "Interest") setShowButton(true);
  };

  useEffect(() => {
    const handleTouchMove = () => {
      if (mainRef.current) {
        const distance = mainRef.current.getBoundingClientRect().top;

        if (distance <= 20 && !isStickyMode) setIsStickyMode(true);
        if (distance > 20 && isStickyMode) setIsStickyMode(false);
      }
    };

    window.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [isStickyMode]);

  useEffect(() => {
    if (iframeRef.current) {
      const player = new VimeoPlayer(iframeRef.current);

      player.on("ended", handleVideoEnd);

      return () => {
        player.off("ended", handleVideoEnd);
      };
    }
  }, [handleVideoEnd]);

  return (
    <div ref={mainRef} className={stickyStyle}>
      <div key={videoId} className="overflow-hidden rounded-lg bg-white shadow">
        <div className="relative aspect-[7/4] w-full overflow-hidden">
          <iframe
            ref={iframeRef}
            title={videoTitle}
            src={`${introVideoUrl}&controls=0&autoplay=1&muted=1`}
            width="100%"
            height="100%"
            className="relative -top-[3px]"
          />

          {showButton ? (
            <div className="absolute bottom-3 right-4 cursor-pointer rounded-full bg-disneyGold px-3 py-2">
              <Text className="text-white">{t("getADemoForMore")}</Text>
            </div>
          ) : null}
        </div>

        <div className="flex h-10 items-center justify-between bg-white1 px-4 py-2.5">
          <Text>{videoTitle}</Text>

          {firstVideo && !currentVideo ? (
            <Touchable
              onClick={() => {
                setShowVideo(true);
              }}
            >
              <img src={playIcon} alt="playIcon" className="size-6" />
            </Touchable>
          ) : null}
        </div>

        {showVideo ? (
          <VimeoVideo
            videoTitle={videoTitle}
            videoUrl={videoUrl}
            setShowVideo={setShowVideo}
          />
        ) : null}
      </div>
    </div>
  );
}
