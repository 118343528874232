import { videoUrlMapping, introVideoUrlMapping } from "../constants";
import { VideoDataType } from "../types";

export default function convertToVideoDataWithVimeoUrl(
  videoData: VideoDataType[],
) {
  return videoData.map((video) => {
    const lowerCaseTitle: string = video.videoTitle.toLowerCase();
    const vimeoUrl = videoUrlMapping[lowerCaseTitle];
    const introVimeoUrl = introVideoUrlMapping[lowerCaseTitle];

    if (vimeoUrl) {
      video.videoUrl = `${vimeoUrl.replace("vimeo.com", "player.vimeo.com/video")}?title=0&byline=0&portrait=0`;
      video.introVideoUrl = `${introVimeoUrl.replace("vimeo.com", "player.vimeo.com/video")}?title=0&byline=0&portrait=0`;
    }

    return video;
  });
}
