import { Dispatch, SetStateAction } from "react";

import play3 from "../../../assets/images/shared/play-3.svg";
import videoLock from "../../../assets/images/watch/video-lock.svg";
import { VideoDataType, VideoListType } from "../types";
import VideoCard from "./VideoCard";

export default function VideoList({
  listType,
  data,
  setCurrentVideo,
}: {
  listType: VideoListType;
  data: VideoDataType[];
  setCurrentVideo: Dispatch<SetStateAction<VideoDataType | null>>;
}) {
  return (
    <div className="grid w-full grid-cols-2 gap-3">
      {data.map((video: VideoDataType, index) => {
        const { videoId, locked } = video;
        const icon = locked ? videoLock : play3;

        return (
          <VideoCard
            key={videoId}
            video={video}
            index={index}
            icon={icon}
            listType={listType}
            setCurrentVideo={setCurrentVideo}
          />
        );
      })}
    </div>
  );
}
