import { ReactNode, useEffect, useState } from "react";

export default function DemoGameWrapper({ children }: { children: ReactNode }) {
  const [deviceHeight, setDeviceHeight] = useState<number>(window.innerHeight);
  const [paddingTop, setPaddingTop] = useState<number>(0);

  useEffect(() => {
    const paddingTop = (deviceHeight - 600) / 2;

    setPaddingTop(paddingTop);
  }, [deviceHeight]);

  useEffect(() => {
    const handleResize = () => {
      setDeviceHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!paddingTop) return null;

  return (
    <div className="relative flex h-screen w-screen">
      <div
        className="absolute left-0 top-0 z-[5] flex w-screen"
        style={{
          height: `${paddingTop + 330}px`,
          background:
            "linear-gradient(176deg, #FEF2E9 0%, #FDE6D2 38%, #F8B379 100%)",
        }}
      />

      <div
        className="absolute left-0 flex h-[600px] w-screen"
        style={{ top: `${paddingTop}px` }}
      >
        <div
          className="absolute left-0 top-0 flex w-screen"
          style={{
            height: `${paddingTop + 600}px`,
            background: "linear-gradient(342deg, #FEF2E9 0%, #FBCDA6 100%)",
          }}
        />
      </div>

      <div className="relative z-10 flex h-screen w-screen">{children}</div>
    </div>
  );
}
