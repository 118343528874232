import { useEffect } from "react";
import ReactPlayer from "react-player";

import froggyToy from "../../assets/images/caringCta/froggyToy.png";
import CtaBg from "../../assets/images/caringCta/CtaBg.png";
import Mask from "../../components/ui/Mask";
import useEventTracking from "../../services/EventTracking/useEventTracking";
import useQueryCustom from "../../services/utils/useQueryCustom";
import { ctaZIndex } from "../../styles/zIndex";
import cn from "../../utils/cn";

import CtaButton from "./components/CtaButton";
import CtaContentText from "./components/CtaContentText";
import CtaTitle from "./components/CtaTitle";
import MaybeLater from "./components/MaybeLater";
import convertCharacterSrc from "./utils/convertCharacterSrc";
import { ctaDataType } from "./types";
import Text from "../../components/ui/Text";

export default function CaringCta({
  ctaExperienceTypeId,
}: {
  ctaExperienceTypeId: string;
}) {
  const { data, isLoading, isError } = useQueryCustom(
    `useCaringCta${ctaExperienceTypeId}`,
    `${ctaExperienceTypeId}/encourage-booking`,
  );

  const mutation = useEventTracking();

  useEffect(() => {
    mutation.mutate({
      pageId: "Call to Action",
      eventId: ctaExperienceTypeId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return null;
  if (isError) return <p>Error</p>;

  const {
    title,
    content,
    template,
    imageUrl,
    imageUrl: photoUrl,
    videoUrl,
    navigationUrl,
    navigationWording,
  } = data as ctaDataType;

  const textColor = template === "Video" ? "text-white1" : "text-grey8";
  const wrapperBgStyle =
    template === "Video"
      ? "bg-grey075"
      : template === "Feature"
        ? "bg-[#232D4AB2]"
        : "";
  const bgStyle = template === "Feature" ? "bg-pink1" : "";
  const height = template === "Feature" ? "w-fit h-fit pt-10 pb-6 px-6" : "";
  const contentMargin = template === "Feature" ? "mt-4 mb-6" : "mt-4 mb-10";

  return (
    <>
      <Mask />

      <div
        className={cn(
          "slide-in-from-bottom fixed bottom-0 left-0 flex h-screen w-screen touch-none items-center justify-center opacity-0",
          ctaZIndex,
          wrapperBgStyle,
        )}
        style={{ transform: "translateY(100%)" }}
      >
        <div
          className={cn(
            "relative z-20 flex h-full w-full items-center justify-center rounded-2xl",
            height,
            bgStyle,
          )}
        >
          <div className="flex flex-col items-center">
            <CtaTitle ctaTitle={title} template={template} />

            {template === "Image" ? (
              <img
                src={convertCharacterSrc(imageUrl)}
                alt="character"
                className="size-[200px]"
              />
            ) : null}
            {template === "Photo" && photoUrl ? (
              <div className="mx-4 mt-2 overflow-hidden rounded-md">
                <img
                  src={photoUrl}
                  alt={photoUrl}
                  className="max-h-[380px] w-[358px]"
                />
              </div>
            ) : null}
            {template === "Video" && videoUrl ? (
              <div className="mx-4 mt-2 overflow-hidden rounded-md">
                <ReactPlayer
                  width="358px"
                  height="264px"
                  url={videoUrl}
                  playing
                  playsinline
                  muted
                  controls
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                />
              </div>
            ) : null}

            {template === "Photo" ? (
              <div
                className={cn(
                  "relative flex w-[390px] flex-row-reverse",
                  photoUrl ? "-top-8 mb-8" : "top-0 mb-16",
                )}
              >
                <div className="relative mr-2 w-[310px] rounded-3xl bg-white p-6 shadow-md">
                  <CtaContentText ctaContent={content} className={textColor} />

                  <img
                    src={froggyToy}
                    alt="froggy toy"
                    className="absolute -bottom-12 -left-12 w-20"
                  />
                </div>
              </div>
            ) : (
              <div className={cn("w-[310px]", contentMargin)}>
                {template === "Video" ? (
                  <Text
                    className={cn(
                      "mb-4 text-center text-[22px] font-bold",
                      textColor,
                    )}
                  >
                    {title}
                  </Text>
                ) : null}

                <CtaContentText ctaContent={content} className={textColor} />
              </div>
            )}

            <CtaButton
              baseUrl={navigationUrl}
              text={navigationWording}
              ctaExperienceTypeId={ctaExperienceTypeId}
              template={template}
            />

            <MaybeLater template={template} />
          </div>
        </div>

        {template === "Image" || template === "Photo" ? (
          <img
            src={CtaBg}
            alt="cta bg"
            className="absolute left-0 top-0 h-full w-full"
          />
        ) : null}
      </div>
    </>
  );
}
