import { Dispatch, SetStateAction, ReactNode } from "react";

import scan from "../../../assets/images/demoGame/scan.png";
import magicPenThumbnail from "../../../assets/images/demoGame/magicPenThumbnail.png";
import talkAlongCardsThumbnail from "../../../assets/images/demoGame/talkAlongCardsThumbnail.png";
import qaCardsThumbnail from "../../../assets/images/demoGame/qaCardsThumbnail.png";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import { GameType } from "../../../types";

export const thumbnailMapping: {
  [key in GameType]: string;
} = {
  magicPenHome: magicPenThumbnail,
  magicPen: magicPenThumbnail,
  talkAlongCards: talkAlongCardsThumbnail,
  QACards: qaCardsThumbnail,
};

export const durationMapping: {
  [key in GameType]: string;
} = {
  magicPenHome: "",
  magicPen: "00:15",
  talkAlongCards: "00:20",
  QACards: "00:11",
};

export default function DemoGameMenu({
  children,
  gameType,
  setShouldOpenIntroVideo,
  setShouldOpenQRCodeModel,
}: {
  children: ReactNode;
  gameType: GameType;
  setShouldOpenIntroVideo: Dispatch<SetStateAction<boolean>>;
  setShouldOpenQRCodeModel: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="flex h-full w-[218px] flex-col items-center justify-center bg-white/60 backdrop-blur-md">
      <div className="h-[360px] w-full pt-[120px]">{children}</div>

      <div className="relative h-60 w-full">
        <div className="flex h-[134px] justify-center pt-11">
          <Touchable
            className="relative h-[90px] w-40 overflow-hidden rounded-lg"
            onClick={() => setShouldOpenIntroVideo(true)}
          >
            <img src={thumbnailMapping[gameType]} alt="thumbnail" />

            <div className="absolute bottom-1 right-1 rounded-sm bg-[#3F434F] px-1 py-0.5">
              <Text typography="footnote" className="text-white">
                {durationMapping[gameType]}
              </Text>
            </div>
          </Touchable>
        </div>

        {/* 154 = gap:44 + thumbnail:90 + 20 */}
        <Touchable
          className="absolute left-[29px] top-[154px] w-[56px] rounded-full"
          style={{ boxShadow: "0px 0px 14px rgba(251, 205, 166, 0.30)" }}
          onClick={() => setShouldOpenQRCodeModel(true)}
        >
          <img src={scan} alt="scan" />
        </Touchable>
      </div>
    </div>
  );
}
