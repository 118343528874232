import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import playmate from "../../../assets/images/gameShared/playmate.png";
import blueFrame1 from "../../../assets/images/qaCards/blueFrame1.png";
import helpVector from "../../../assets/images/qaCards/helpVector.png";
import helpPolygon from "../../../assets/images/qaCards/helpPolygon.png";
import helpPolygon2 from "../../../assets/images/qaCards/helpPolygon2.svg";
import GameHelpModel from "../../../components/game/GameHelpModel";
import { QACGameState } from "../types";

export default function HelpModel({
  gameState,
  setShowHelp,
}: {
  gameState: QACGameState;
  setShowHelp: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const isModel2 = gameState === "waitingForRotating";
  const title = isModel2 ? t("qaCardsHelpText2") : t("qaCardsHelpText1");
  const image = isModel2 ? helpPolygon2 : helpPolygon;

  return (
    <GameHelpModel title={title} setShowHelp={setShowHelp}>
      <div className="relative h-[260px] w-[266px]">
        <img
          src={blueFrame1}
          alt="blueFrame1"
          className="absolute right-1 top-0 size-[146px]"
        />
        <img
          src={playmate}
          alt="playmate"
          className="absolute left-[23px] top-[108px] w-[220px]"
        />
        <img
          src={helpVector}
          alt="helpVector"
          className="absolute bottom-[76px] left-[88px] w-[78px]"
        />
        <img
          src={image}
          alt="helpPolygon"
          className="absolute bottom-1 left-9 w-[84px]"
        />
      </div>
    </GameHelpModel>
  );
}
