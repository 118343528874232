import { animated, useSpring } from "@react-spring/web";

import f32 from "../../../assets/images/qaCards/f32.png";
import f34 from "../../../assets/images/qaCards/f34.png";
import f39 from "../../../assets/images/qaCards/f39.png";
import groove1 from "../../../assets/images/qaCards/groove1.png";
import groove2 from "../../../assets/images/qaCards/groove2.png";
import { falling } from "../animationConfigs";

export default function AnimatedCardPanel() {
  const animation1 = useSpring(falling());
  const animation2 = useSpring(falling());
  const animation3 = useSpring(falling());

  return (
    <div className="relative flex h-[184px] w-full flex-none justify-center">
      <div className="ml-4 mt-7 flex h-[184px] w-[390px] gap-2">
        <img
          src={groove1}
          alt="groove1"
          className="absolute left-[15px] top-[45px] h-[92px] w-[96px] rotate-[-41deg]"
        />
        <animated.img
          src={f34}
          alt="f34"
          className="relative -left-1.5 top-4 z-10 h-[92px] w-[96px] rotate-[-22deg]"
          style={animation1}
        />

        <img
          src={groove2}
          alt="groove2"
          className="absolute left-[124px] top-[25px] size-20 rotate-[-1deg]"
        />
        <animated.img
          src={f39}
          alt="f39"
          className="relative -top-1 left-0.5 z-10 size-20 rotate-[1deg]"
          style={animation2}
        />

        <img
          src={groove1}
          alt="groove1"
          className="absolute left-[222px] top-[27px] h-[92px] w-[96px]"
        />
        <animated.img
          src={f32}
          alt="f32"
          className="relative -top-1 left-3 z-10 h-[92px] w-[96px] rotate-[20deg]"
          style={animation3}
        />
      </div>
    </div>
  );
}
