import { ReactNode } from "react";
import { useDroppable } from "@dnd-kit/core";

export default function Droppable({
  children,
  className,
}: {
  children?: ReactNode;
  className: string;
}) {
  const { setNodeRef } = useDroppable({
    id: "droppable",
  });

  return (
    <div ref={setNodeRef} className={className}>
      {children}
    </div>
  );
}
