import { Dispatch, SetStateAction } from "react";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../../../components/shadcn/inputOTP";

export default function SmsInput({
  smsCode,
  setSmsCode,
}: {
  smsCode: string;
  setSmsCode: Dispatch<SetStateAction<string>>;
}) {
  return (
    <InputOTP
      maxLength={4}
      value={smsCode}
      onChange={(value) => {
        const nonDigitRegex = /[^0-9]/;

        if (nonDigitRegex.test(value)) return;

        setSmsCode(value);
      }}
    >
      <InputOTPGroup>
        <InputOTPSlot index={0} />
      </InputOTPGroup>
      <InputOTPGroup>
        <InputOTPSlot index={1} />
      </InputOTPGroup>
      <InputOTPGroup>
        <InputOTPSlot index={2} />
      </InputOTPGroup>
      <InputOTPGroup>
        <InputOTPSlot index={3} />
      </InputOTPGroup>
    </InputOTP>
  );
}
