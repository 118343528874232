import { Loader2 } from "lucide-react";

export default function LyricsLoader() {
  return (
    <div
      className="flex items-center justify-center"
      style={{ height: "calc(100vh - 489px)" }}
    >
      <Loader2 className="animate-spin text-white" size={48} />
    </div>
  );
}
