import { ReactNode } from "react";
import { animated, useSpring } from "@react-spring/web";

import { fadeInModelZIndex } from "../../styles/zIndex";
import cn from "../../utils/cn";

export default function FadeInAnimation({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const styles = useSpring({
    to: async (next: any) => {
      await next({
        transform: "translateY(100%)",
        opacity: 0,
        immediate: true,
      });
      await next({ transform: "translateY(0%)", opacity: 0 });
      await next({ transform: "translateY(0%)", opacity: 1 });
    },
    from: { transform: "translateY(100%)", opacity: 0 },
    config: { duration: 300 },
  });

  return (
    <animated.div style={styles} className={cn(className, fadeInModelZIndex)}>
      {children}
    </animated.div>
  );
}
