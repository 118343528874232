import { ReactNode } from "react";

export default function DemoGameBoardWrapper({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div className="flex h-full w-full flex-1 items-center justify-center overflow-hidden">
      {children}
    </div>
  );
}
