import { useEffect, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import useResendSmsCode from "../../../services/webLogin/useResendSmsCode";
import { CountDownTime } from "../constants";

export default function CountDownAndResend({
  showSmsDrawer,
  phoneNumber,
  timeLeft,
  setTimeLeft,
}: {
  showSmsDrawer: boolean;
  phoneNumber: string;
  timeLeft: number;
  setTimeLeft: Dispatch<SetStateAction<number>>;
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (timeLeft === 0 || !showSmsDrawer) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, showSmsDrawer, setTimeLeft]);

  const mutationForResend = useResendSmsCode();

  const handleResend = () => {
    if (mutationForResend.isLoading) return;

    mutationForResend.mutate(phoneNumber, {
      onSuccess: () => {
        setTimeLeft(CountDownTime);
      },
    });
  };

  return (
    <div className="flex h-11 w-fit flex-1 items-center justify-center">
      {timeLeft > 0 ? (
        <Text>{`${timeLeft} ${t("seconds")}`}</Text>
      ) : (
        <Touchable
          className="flex h-9 w-fit items-center rounded-full border border-solid border-black8 px-4"
          onClick={handleResend}
        >
          <Text className="text-nowrap">{t("resend")}</Text>
        </Touchable>
      )}
    </div>
  );
}
