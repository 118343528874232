import { FlipCardFrameDuration } from "./constants";

export const moveToLeft = (duration: number) => ({
  to: async (next: any) => {
    await next({
      transform: "translateX(0px) translateY(0px)",
      immediate: true,
    });
    await next({ transform: "translateX(-96px) translateY(0px)" });
  },
  from: { transform: "translateX(0px) translateY(0px)" },
  config: { duration },
});

export const circleToRight = {
  to: async (next: any) => {
    await next({
      transform: "translateX(0px) translateY(0px)",
      immediate: true,
    });
    await next({ transform: "translateX(12px) translateY(-60px)" });
    await next({ transform: "translateX(48px) translateY(-60px)" });
    await next({ transform: "translateX(84px) translateY(-60px)" });
    await next({ transform: "translateX(96px) translateY(0px)" });
  },
  from: { transform: "translateX(0px) translateY(0px)" },
  config: { duration: FlipCardFrameDuration },
};
