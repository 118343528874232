import { useTranslation } from "react-i18next";
import { X } from "lucide-react";

import benefit from "../../../assets/images/event/benefit.png";
import benefit2 from "../../../assets/images/event/benefit2.png";
import SlideInAnimation from "../../../components/animation/SlideInAnimation";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import useStore from "../../../hooks/store/useStore";

export default function BenefitsDrawer({
  showDrawer,
  onClose,
}: {
  showDrawer: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const { userInformation } = useStore((state) => state.user);
  const { market } = userInformation || {};

  const image = market === "KR" ? benefit2 : benefit;

  return (
    <SlideInAnimation
      isVisible={showDrawer}
      className="fixed bottom-0 left-0 flex w-full flex-col items-center gap-6 rounded-t-3xl bg-gradient-to-t from-pink1 to-pink2 pb-[68px] pt-3"
    >
      <div className="flex w-full flex-col items-center gap-2">
        <div className="relative flex h-11 w-full items-center justify-center">
          <Text lg className="font-medium">
            {t("membershipBenefits")}
          </Text>

          <Touchable onClick={onClose}>
            <X className="absolute right-0 top-0 mx-4 my-2.5 size-6" />
          </Touchable>
        </div>

        <img
          src={image}
          alt="benefit"
          className="relative z-10 h-fit w-[350px]"
        />
      </div>

      <div className="w-[326px]">
        <Text className="pb-2 text-center">{t("membershipBenefitsTitle")}</Text>

        <Text className="font-medium leading-6">
          {t("membershipBenefitsDesc1")}
        </Text>

        {market === "KR" ? (
          <div className="mt-6">
            {t("membershipBenefitsDesc2")
              .split("\n")
              .map((text, i) => (
                <Text key={i} className="font-medium leading-6 text-black6">
                  {text}
                </Text>
              ))}
          </div>
        ) : null}
      </div>
    </SlideInAnimation>
  );
}
