import { useNavigate, useLocation } from "react-router-dom";

function generateUrl(
  path: string,
  tokenId: string | null,
  source: string | null,
  market: string | null,
  advisorCode: string | null,
) {
  let url = `${path}?`;

  if (tokenId) url += `tokenId=${encodeURIComponent(tokenId)}&`;
  if (source) url += `source=${encodeURIComponent(source)}&`;
  if (market) url += `market=${encodeURIComponent(market)}&`;
  if (advisorCode) url += `EA=${encodeURIComponent(advisorCode)}&`;

  const lastCharacter = url.slice(-1);

  return lastCharacter === "&" || lastCharacter === "?"
    ? url.slice(0, -1)
    : url;
}

const useNavigateWithParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateWithParams = (path = "/") => {
    const searchParams = new URLSearchParams(location.search);
    const tokenId = searchParams.get("tokenId");
    const source = searchParams.get("source");
    const market = searchParams.get("market");
    const advisorCode = searchParams.get("EA");
    const url = generateUrl(path, tokenId, source, market, advisorCode);

    navigate(url);
  };

  return navigateWithParams;
};

export default useNavigateWithParams;
