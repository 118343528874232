import { useMutation } from "react-query";

import useStore from "../hooks/store/useStore";

async function exchangeToken(tokenId: string) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/exchange-token`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
      },
      body: JSON.stringify({
        tokenId,
      }),
    }
  );

  if (!response.ok) throw new Error("Network response was not ok");

  return response.json();
}

export default function useExchangeToken() {
  const { setTokens } = useStore((state) => state.tokens);

  return useMutation({
    mutationFn: exchangeToken,
    onSuccess: (data) => {
      setTokens(data);
    },
  });
}
