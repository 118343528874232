import { useState } from "react";
import { ChevronRight } from "lucide-react";

import Card from "../../../components/ui/Card";
import Image from "../../../components/ui/Image";
import Text from "../../../components/ui/Text";
import useEventTracking from "../../../services/EventTracking/useEventTracking";
import { getVideoUrl } from "../utils/getVideoUrl";
import { RegularEventDataType } from "../types";
import EventVideo from "./EventVideo";

export default function RegularEventCard({
  data,
}: {
  data: RegularEventDataType;
}) {
  const { description, navigationUrl, thumbnailUrl, title } = data;
  const videoUrl = getVideoUrl(navigationUrl);

  const [showVideo, setShowVideo] = useState<boolean>(false);

  const mutation = useEventTracking();

  return (
    <>
      <Card
        url={navigationUrl}
        className="flex flex-none items-center gap-5 p-2 shadow-custom0"
        isLink={!videoUrl}
        onClick={() => {
          mutation.mutate({
            pageId: "Regular Event",
            eventId: navigationUrl,
          });

          if (videoUrl) setShowVideo(true);
        }}
      >
        <div className="relative size-[88px] flex-none overflow-hidden rounded-lg">
          <Image src={thumbnailUrl} alt={title} className="centered-img" />
        </div>

        <div className="flex w-full items-center justify-between pr-3">
          <div className="flex flex-col">
            <Text lg>{title}</Text>
            <Text normal className="text-[13px]">
              {description}
            </Text>
          </div>

          <ChevronRight className="flex-none" />
        </div>
      </Card>

      {showVideo ? (
        <EventVideo videoUrl={videoUrl} setShowVideo={setShowVideo} />
      ) : null}
    </>
  );
}
