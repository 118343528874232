import Dialog from "../../../components/game/Dialog";
import { QACardType } from "../../../types";
import { showBackText } from "../utils/judgements";
import { qacAudioData } from "../constants";
import { QACGameState } from "../types";

export default function QACDialog({
  currentCard,
  gameState,
  frontAudioIndex,
  backAudioIndex,
}: {
  currentCard: QACardType | null;
  gameState: QACGameState;
  frontAudioIndex: number;
  backAudioIndex: number;
}) {
  if (currentCard === null || gameState === "waitingForDragging") return null;

  const { frontAudio, backAudio } = qacAudioData[currentCard];
  const isBackPhase = showBackText(gameState);
  const { text } = isBackPhase
    ? backAudio(frontAudioIndex)[backAudioIndex]
    : frontAudio[frontAudioIndex];

  return (
    <div className="h-[140px]">
      <Dialog text={text} className="relative z-20" blue={isBackPhase} />
    </div>
  );
}
