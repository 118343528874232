import { Dispatch, SetStateAction } from "react";
import { Check } from "lucide-react";

import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import cn from "../../../utils/cn";

export default function RadioButton({
  text,
  value,
  location,
  setLocation,
}: {
  text: string;
  value: string;
  location: string;
  setLocation: Dispatch<SetStateAction<string>>;
}) {
  const checked = location === value;
  const wrapperStyle = checked
    ? "border-pink2 bg-pink2"
    : "border-grey2 bg-white";
  const circleStyle = checked
    ? "border-orange bg-orange"
    : "border-grey2 bg-white";

  return (
    <Touchable
      className={cn(
        wrapperStyle,
        "flex h-12 w-full items-center justify-center gap-2 rounded-lg border-2 border-solid px-3 duration-300 ease-in-out",
      )}
      onClick={() => setLocation(value)}
    >
      <div
        className={cn(
          circleStyle,
          "size-5 rounded-full border-2 border-solid duration-300 ease-in-out",
        )}
      >
        {checked ? <Check className="size-4 text-white" /> : null}
      </div>

      <Text className="flex-1">{text}</Text>
    </Touchable>
  );
}
