import { TACardType } from "../../../../types";
import getDuration from "../../../talkAlongCards/utils/getDuration";
import { tacImageData } from "../../../talkAlongCards/constants";
import { TACGameState } from "../../../talkAlongCards/types";
import { circleToRight, moveToLeft } from "../animationConfigs";
import AnimatedCard from "./AnimatedCard";
import StaticCard from "./StaticCard";

export default function CardHandler({
  gameState,
  currentCard,
  backAudioIndex,
}: {
  gameState: TACGameState;
  currentCard: TACardType | null;
  backAudioIndex: number;
}) {
  if (currentCard === null) return null;

  const { frontImage, backImage } = tacImageData[currentCard];

  if (gameState === "cardFlippingAndReset" || gameState === "cardReset")
    return (
      <AnimatedCard
        image={backImage}
        animationConfig={circleToRight}
        className="right-0"
      />
    );

  if (gameState === "frontPlaying")
    return (
      <AnimatedCard
        image={frontImage}
        animationConfig={moveToLeft(
          getDuration(gameState, currentCard, backAudioIndex),
        )}
      />
    );

  if (gameState === "backPlaying")
    return (
      <AnimatedCard
        image={backImage}
        animationConfig={moveToLeft(
          getDuration(gameState, currentCard, backAudioIndex),
        )}
      />
    );

  if (gameState === "waitingForFlipping")
    return <StaticCard image={frontImage} className="left-0" />;

  if (gameState === "waitingForReplay")
    return <StaticCard image={backImage} className="left-0" />;

  return null;
}
