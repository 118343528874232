"use client";

import * as React from "react";
import { format } from "date-fns";

import calendar from "../../assets/images/shadcn/calendar.png";
import cn from "../../utils/cn";
import Text from "../ui/Text";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export function DatePicker({
  date,
  setDate,
  disabled,
}: {
  date: Date | undefined;
  setDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  disabled?: (date: Date) => boolean;
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "h-11 w-[280px] justify-start rounded-full border border-solid border-grey2 px-3 py-2.5 text-left font-normal",
            !date && "text-muted-foreground",
          )}
        >
          <img src={calendar} alt="calendar" className="mr-2 size-6" />
          {date ? (
            <Text>{format(date, "dd/MM/yyyy")}</Text>
          ) : (
            <Text className="text-grey4">dd/mm/yyyy</Text>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
          disabled={disabled}
        />
      </PopoverContent>
    </Popover>
  );
}
