import { useTranslation } from "react-i18next";

import Touchable from "../../../components/ui/Touchable";
import useCtaStore from "../../../hooks/store/useCtaStore";
import useStore from "../../../hooks/store/useStore";
import cn from "../../../utils/cn";
import { TemplateType } from "../types";

export default function MaybeLater({ template }: { template: TemplateType }) {
  const { t } = useTranslation();

  const { setShowingCtaETId } = useCtaStore((state) => state.cta);
  const { userInformation } = useStore((state) => state.user);
  const { trialUserSegmentation } = userInformation || {};

  const textColor = template === "Video" ? "text-white" : "text-grey8";

  if (trialUserSegmentation === "Decision") return null;

  return (
    <Touchable
      className={cn("my-5 cursor-pointer font-semibold leading-5", textColor)}
      onClick={(e: any) => {
        e.stopPropagation();
        setShowingCtaETId(null);
      }}
    >
      {template === "Feature" ? t("gotIt") : t("maybeLater")}
    </Touchable>
  );
}
