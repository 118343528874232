import { useMutation } from "react-query";

import useStore from "../hooks/store/useStore";
import getHeaders from "./utils/getHeaders";

export default function useOneTimeToken() {
  const { accessKey, jwtToken } = useStore((state) => state.tokens);
  const { userInformation } = useStore((state) => state.user);

  return useMutation({
    mutationFn: async () => {
      if (!accessKey) throw new Error("No accessKey in the store");
      if (!jwtToken) throw new Error("No jwtToken in the store");
      if (!userInformation) throw new Error("No userInformation in the store");

      const { market, deviceId, trialUserId, userName, phoneNumber } =
        userInformation;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/one-time-token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...getHeaders({ accessKey, jwtToken }),
          },
          body: JSON.stringify({
            market,
            deviceId,
            trialUserId,
            userName,
            phoneNumber,
          }),
        },
      );

      if (!response.ok) throw new Error("Network response was not ok");

      return response.json();
    },
  });
}
