import i18n from "i18next";

import { MarketType, LanguageType } from "../../types";

const languageMapping: {
  [key in MarketType]: LanguageType;
} = {
  KR: "ko",
  HK: "zh-HK",
  TW: "zh-TW",
};

export default function changeLanguageByMarket(
  market: MarketType,
  setLanguage: (data: LanguageType) => void,
) {
  const language = languageMapping[market];

  i18n.changeLanguage(language, () => {
    setLanguage(language);
  });
}
