import cn from "../../../utils/cn";

export default function CarouselDots({
  carouselLength,
  carouselIndex,
}: {
  carouselLength: number;
  carouselIndex: number;
}) {
  if (carouselLength <= 1) return null;

  return (
    <div className="mt-2 flex justify-center">
      <div className="flex gap-1.5">
        {new Array(carouselLength).fill(null).map((_, index) => {
          const style =
            index === carouselIndex
              ? "w-4 h-1.5 bg-orange"
              : "size-1.5 bg-grey025";

          return (
            <div
              key={index}
              className={cn(style, "rounded-full duration-300 ease-in-out")}
            />
          );
        })}
      </div>
    </div>
  );
}
