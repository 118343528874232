import { Dispatch, ReactNode, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import FadeInAnimation from "../animation/FadeInAnimation";
import Text from "../ui/Text";
import Touchable from "../ui/Touchable";

export default function GameHelpModel({
  title,
  children,
  setShowHelp,
}: {
  title: string;
  children: ReactNode;
  setShowHelp: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();

  return (
    <FadeInAnimation className="fixed bottom-0 left-0 flex h-screen w-screen items-center justify-center bg-grey8/70">
      <div className="flex w-[314px] flex-col gap-6 rounded-3xl bg-white px-6 py-4">
        <Text lg>{title}</Text>

        {children}

        <div className="flex justify-center">
          <Touchable
            className="h-12 w-fit rounded-full bg-disneyGold px-10 py-3"
            onClick={() => setShowHelp(false)}
          >
            <Text className="text-white">{t("gotIt")}</Text>
          </Touchable>
        </div>
      </div>
    </FadeInAnimation>
  );
}
