import { useTranslation } from "react-i18next";

import thankYou from "../../../assets/images/bookDemo/thankYou.png";
import background from "../../../assets/images/bookDemo/background.png";
import FadeInAnimation from "../../../components/animation/FadeInAnimation";
import Text from "../../../components/ui/Text";
import useStore from "../../../hooks/store/useStore";
import Button from "./Button";

export default function ThanksModel() {
  const { t } = useTranslation();

  const { setShowBookDemoThanks } = useStore((state) => state.bookDemo);
  const { userInformation, setUserInformation } = useStore(
    (state) => state.user,
  );
  const { userName } = userInformation || {};

  const text = t("bookDemoThanks").replace("Name", userName || "");

  return (
    <FadeInAnimation className="fixed bottom-0 left-0 flex h-screen w-screen items-center justify-center bg-grey8/70">
      <div className="relative w-[310px] overflow-hidden rounded-2xl bg-pink1 p-6 pt-9">
        <img
          src={background}
          alt="background"
          className="absolute left-0 top-1 w-full"
        />

        <div className="relative z-10 flex flex-col gap-6">
          <div className="flex w-full justify-center">
            <img src={thankYou} alt="thankYou" className="h-10" />
          </div>

          <Text>{text}</Text>

          <div className="flex justify-center">
            <Button
              text={t("ok")}
              onClick={() => {
                setShowBookDemoThanks(false);

                setTimeout(() => {
                  if (userInformation)
                    setUserInformation({
                      ...userInformation,
                      trialUserSegmentation: "Decision",
                    });
                }, 300);
              }}
            />
          </div>
        </div>
      </div>
    </FadeInAnimation>
  );
}
