import eatFront from "../../../../assets/images/talkAlongCards/eatFront.png";
import playFront from "../../../../assets/images/talkAlongCards/playFront.png";
import picnicFront from "../../../../assets/images/talkAlongCards/picnicFront.png";
import carsFront from "../../../../assets/images/talkAlongCards/carsFront.png";
import playmate from "../../../../assets/images/gameShared/playmate.png";
import penPad from "../../../../assets/images/demoGame/penPad.png";
import Text from "../../../../components/ui/Text";
import { TACardType } from "../../../../types";

const mapping: {
  [key in TACardType]: string;
} = {
  eat: eatFront,
  play: playFront,
  picnic: picnicFront,
  cars: carsFront,
};

export default function CardMenu({ currentCard }: { currentCard: TACardType }) {
  return (
    <div className="relative flex h-[240px] flex-col items-center">
      <div className="relative z-50 mt-10 flex h-[140px] w-[140px] justify-center">
        <img
          src={mapping[currentCard]}
          alt="front"
          className="absolute right-0 top-[36.7px] z-10 w-[130.6px]"
        />
        <img
          src={playmate}
          alt="playmate"
          className="absolute bottom-[15px] left-0 z-20 w-[124.4px]"
        />
      </div>

      <img
        src={penPad}
        alt="penPad"
        className="absolute left-[19px] top-[144px] w-[180px]"
      />

      <div className="flex h-[60px] justify-center pt-[22px]">
        <Text typography="h3" className="text-[#1D1D1D]">
          토크얼롱 카드
        </Text>
      </div>
    </div>
  );
}
