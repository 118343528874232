import Text from "../../../components/ui/Text";
import useStore from "../../../hooks/store/useStore";
import cn from "../../../utils/cn";
import replaceUserName from "../utils/replaceUserName";

export default function CtaContentText({
  ctaContent,
  className,
}: {
  ctaContent: string;
  className: string;
}) {
  const { userInformation } = useStore((state) => state.user);
  const { userName = "" } = userInformation || {};

  return (
    <div className="flex flex-col gap-2">
      {ctaContent.split("\n").map((text, index) => (
        <Text key={index} className={cn("text-center", className)}>
          {replaceUserName(text, userName)}
        </Text>
      ))}
    </div>
  );
}
