import { RefObject } from "react";

import { bubbleSize } from "../constants";

function getScrollLeft(cardRef: RefObject<HTMLDivElement>, bubbleLeft: number) {
  const container = cardRef.current;

  if (!container) return null;

  const containerWidth = container.offsetWidth;
  const leftInScreen = (containerWidth - bubbleSize) / 2;

  return bubbleLeft - leftInScreen;
}

export default function scrollCard(
  cardRef: RefObject<HTMLDivElement>,
  bubbleLeft: number,
) {
  const left = getScrollLeft(cardRef, bubbleLeft);

  if (cardRef.current && left !== null)
    cardRef.current.scrollTo({
      left,
      behavior: "smooth",
    });
}
