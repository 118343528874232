import { bubbleSize } from "../constants";

const leftMax = 651;
const leftMin = 235;
const y = -100;

export default function getPenResetPosition(bubbleLeft: number) {
  let x = bubbleLeft + bubbleSize - 4;

  if (x > leftMax) x = leftMax;
  if (x < leftMin) x = leftMin;

  return { x, y };
}
