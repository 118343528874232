import MagicPenHome from "../../pages/magicPen/MagicPenHome";
import MagicPen from "../../pages/magicPen/MagicPen";
import TalkAlongCards from "../../pages/talkAlongCards/TalkAlongCards";
import QACards from "../../pages/qaCards/QACards";
import { GameType } from "../../types";

export const gameMapping: {
  [key in GameType]: React.ReactNode;
} = {
  magicPenHome: <MagicPenHome />,
  magicPen: <MagicPen />,
  talkAlongCards: <TalkAlongCards />,
  QACards: <QACards />,
};

export const magicPenMaxWidth = 805;
