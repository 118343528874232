import mickeyPen from "../../../../assets/images/magicPen/mickeyPen2.png";
import minniePen from "../../../../assets/images/magicPen/minniePen2.png";
import penPad from "../../../../assets/images/demoGame/penPad.png";
import { Checkbox } from "../../../../components/shadcn/checkbox";
import Text from "../../../../components/ui/Text";
import Touchable from "../../../../components/ui/Touchable";
import useStore from "../../../../hooks/store/useStore";

export default function PenMenu() {
  const { selectedPen, setSelectedPen } = useStore((state) => state.global);

  return (
    <div className="flex h-[240px] flex-col">
      <div className="flex h-[42px] justify-center">
        <div className="flex w-[70px] justify-center pt-6">
          <Checkbox
            checked={selectedPen === "mickey"}
            onCheckedChange={() => setSelectedPen("mickey")}
          />
        </div>

        <div className="flex w-[70px] justify-center pt-6">
          <Checkbox
            checked={selectedPen === "minnie"}
            onCheckedChange={() => setSelectedPen("minnie")}
          />
        </div>
      </div>

      <div className="relative flex h-[130px] justify-center">
        <Touchable
          className="relative -left-1 z-10 w-[70px]"
          onClick={() => {}}
        >
          <img
            src={mickeyPen}
            alt="mickeyPen"
            onClick={() => setSelectedPen("mickey")}
          />
        </Touchable>

        <Touchable
          className="relative -left-1 z-10 w-[70px]"
          onClick={() => {}}
        >
          <img
            src={minniePen}
            alt="minniePen"
            onClick={() => setSelectedPen("minnie")}
          />
        </Touchable>

        <img
          src={penPad}
          alt="penPad"
          className="absolute bottom-0 left-[19px] w-[180px]"
        />
      </div>

      <div className="flex flex-1 items-center justify-center">
        <Text typography="h3" className="text-[#1D1D1D]">
          매직펜
        </Text>
      </div>
    </div>
  );
}
