import { animated, useSpring } from "@react-spring/web";

import cn from "../../../../utils/cn";
import getDegree from "../utils/getDegree";

export default function AnimatedCard({
  image,
  isRect,
  backAudioIndex,
  animationConfig,
  size,
}: {
  image: string;
  isRect?: boolean;
  backAudioIndex?: number;
  animationConfig?: any;
  size?: string;
}) {
  const deg =
    backAudioIndex === undefined
      ? "0deg"
      : getDegree(Boolean(isRect), backAudioIndex)[0];
  const animation = useSpring(animationConfig);

  return (
    <animated.div
      className={cn("absolute bottom-8 right-8 z-20", size)}
      style={animation}
    >
      <div
        style={{
          transform: `rotate(${deg})`,
        }}
      >
        <img src={image} alt="main card" className="h-full w-full" />
      </div>
    </animated.div>
  );
}
