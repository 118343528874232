function getYoutubeId(url: string): string {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]+)/,
  );

  return match ? match[1] : "";
}

export function getVimeoId(url: string): string {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/|player\.vimeo\.com\/video\/)(\d+)/,
  );

  return match ? match[1] : "";
}

export function getVideoUrl(url: string): string {
  let id = getYoutubeId(url);

  if (id) return `https://www.youtube.com/watch?v=${id}`;

  id = getVimeoId(url);

  return id
    ? `https://player.vimeo.com/video/${id}?autoplay=1&muted=1&playsinline=1&controls=1`
    : "";
}
