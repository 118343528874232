import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";

export default function RoundedButton({
  texts,
  onClick,
}: {
  texts: string[];
  onClick?: () => void;
}) {
  return (
    <Touchable
      className="fixed bottom-4 right-4 z-[5] flex size-[76px] cursor-pointer flex-col items-center justify-center rounded-full bg-disneyGold shadow-custom0"
      onClick={onClick}
    >
      {texts.map((text) => (
        <Text key={text} className="text-nowrap text-center text-white">
          {text.replace("-", " ")}
        </Text>
      ))}
    </Touchable>
  );
}
