import { MarketType, UserInformation } from "../types";

function isNotTheMarket(
  market: MarketType,
  marketParam: string | null,
  userInformation: UserInformation | null,
) {
  const isValidMarketParam =
    marketParam && ["HK", "KR", "TW"].includes(marketParam as MarketType);

  return (
    !isValidMarketParam ||
    marketParam !== market ||
    (userInformation && userInformation.market !== market)
  );
}

export function isNotTW(
  marketParam: string | null,
  userInformation: UserInformation | null,
) {
  return isNotTheMarket("TW", marketParam, userInformation);
}

export function isNotKR(
  marketParam: string | null,
  userInformation: UserInformation | null,
) {
  return isNotTheMarket("KR", marketParam, userInformation);
}
