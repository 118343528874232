import loadingLogo from "../../assets/images/shared/loadingLogo.svg";
import { appBgColorMapping } from "../../styles/constants";
import { MenuItemType } from "../../types";
import cn from "../../utils/cn";

export default function Loader({ page }: { page?: MenuItemType }) {
  const bgColor = (page && appBgColorMapping[page]) || "bg-pink1";
  const style = page
    ? "absolute top-0 left-0 z-10 w-full h-full"
    : "min-w-[320px] max-w-[480px] w-screen h-screen";

  return (
    <div
      className={cn(
        bgColor,
        "relative flex min-h-screen w-full justify-center",
      )}
    >
      <div
        className={cn(style, "flex flex-col items-center justify-center gap-5")}
      >
        <img src={loadingLogo} alt="loading logo" className="w-[89px]" />

        <div className="relative flex gap-1.5">
          <div className="size-2 rounded-full bg-grey025" />
          <div className="size-2 rounded-full bg-grey025" />
          <div className="size-2 rounded-full bg-grey025" />

          <div className="loading-dot absolute left-0 top-0 w-9">
            <div className="size-2 rounded-full bg-black8" />
          </div>
        </div>
      </div>
    </div>
  );
}
