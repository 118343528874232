import { useSpring, animated } from "@react-spring/web";

import cn from "../../../../utils/cn";
import { FlipCardDuration } from "../../../qaCards/constants";

export default function AnimatedDoubleSidedCard({
  frontImage,
  backImage,
  isRect,
  size,
}: {
  frontImage: string;
  backImage: string;
  isRect: boolean;
  size?: string;
}) {
  const left = isRect ? 185 : 162;
  const scale = isRect ? "1.44" : "1.31";

  const frontAnimation = useSpring({
    from: {
      opacity: 1,
      left: 32, // left-8
      transform: "perspective(600px) rotateY(0deg) scale(1)",
    },
    to: {
      opacity: 0,
      left,
      transform: `perspective(600px) rotateY(-180deg) scale(${scale})`,
    },
    config: { duration: FlipCardDuration },
  });

  const backAnimation = useSpring({
    from: {
      opacity: 0,
      left: 32, // left-8
      transform: "perspective(600px) rotateY(180deg) scale(1)",
    },
    to: {
      opacity: 1,
      left,
      transform: `perspective(600px) rotateY(0deg) scale(${scale})`,
    },
    config: { duration: FlipCardDuration },
  });

  return (
    <div className={cn("absolute bottom-8 left-8 z-20", size)}>
      <animated.div
        className={cn(size, "absolute top-2")}
        style={frontAnimation}
      >
        <img src={frontImage} alt="frontImage" className="h-full w-full" />
      </animated.div>
      <animated.div
        className={cn(size, "absolute top-2")}
        style={backAnimation}
      >
        <img src={backImage} alt="backImage" className="h-full w-full" />
      </animated.div>
    </div>
  );
}
