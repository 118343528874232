import { Dispatch, ReactNode, SetStateAction, useEffect } from "react";
import { X } from "lucide-react";

import useCtaStore from "../../hooks/store/useCtaStore";
import Text from "./Text";
import Touchable from "./Touchable";

export default function VideoModel({
  videoUrl,
  setShowVideo,
  videoTitle,
  onClose,
  children,
}: {
  videoUrl: string;
  setShowVideo: Dispatch<SetStateAction<boolean>>;
  videoTitle?: string;
  onClose?: () => void;
  children?: ReactNode;
}) {
  const { setCtaIntervalBlock } = useCtaStore((state) => state.ctaInterval);

  const handleClose = (e: any) => {
    e.stopPropagation();

    setCtaIntervalBlock(false);
    setShowVideo(false);

    if (onClose) onClose();
  };

  useEffect(() => {
    setCtaIntervalBlock(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen touch-none items-center justify-center">
      <div className="fixed left-0 top-0 z-20 flex h-11 w-screen items-center justify-center">
        {videoTitle ? (
          <Text lg className="font-medium text-white">
            {videoTitle}
          </Text>
        ) : null}

        <Touchable onClick={handleClose}>
          <X className="absolute right-0 top-0 mx-4 my-2.5 size-6 text-white" />
        </Touchable>
      </div>

      {children ? (
        <div className="z-10 w-full">{children}</div>
      ) : (
        <video
          className="z-10 w-full"
          controls
          autoPlay
          webkit-playsinline="true"
          playsInline
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
        >
          <source src={videoUrl} />
        </video>
      )}

      <div className="absolute left-0 top-0 h-full w-full bg-black" />
    </div>
  );
}
