import { MarketType } from "../../types";
import getBodyKR from "./utils/getBodyKR";
import getBodyTW from "./utils/getBodyTW";

export default async function bookDemoFnOthers({
  market,
  name,
  phone,
}: {
  market: MarketType;
  name: string;
  phone: string;
}) {
  const url =
    market === "TW"
      ? `${process.env.REACT_APP_API_URL_BOOKDEMO_TW}/appointment`
      : `${process.env.REACT_APP_API_URL_BOOKDEMO_KR}/application`;
  const contentType =
    market === "TW" ? "application/json" : "application/x-www-form-urlencoded";
  const body =
    market === "TW" ? getBodyTW(name, phone) : getBodyKR(name, phone);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": contentType,
    },
    body,
  });

  if (!response.ok) throw new Error("Network response was not ok");

  return;
}
