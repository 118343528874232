import { UserInformation } from "../../types";

const TriggerCount = 41;
const MaxDisplayCount = 1;

export const IntervalMsTime = 3000;

export function hasCtaTimer(userInformation: UserInformation | null) {
  const { trialUserSegmentation: seg } = userInformation || {};

  return seg === "Awareness" || seg === "Interest";
}

/**
 * ctaExperienceTypeId: 8
 * Remain still for 10 seconds except for Games
 * Awareness, Interest
 */
export default function ctaHandler8(
  ctaIntervalBlock: boolean,
  ctaIntervalCount: number,
  setCtaIntervalCount: (count: number) => void,
  showingCtaETId: string | null,
  setShowingCtaETId: (data: string | null) => void,
  ctaIntervalDisplayCount: number,
  setCtaIntervalDisplayCount: (count: number) => void,
  showBookDemoDrawer: boolean,
  showBookDemoThanks: boolean,
  showWannaUnlockModel: boolean,
  showAppDownloadModel: boolean,
) {
  if (
    ctaIntervalDisplayCount >= MaxDisplayCount ||
    showBookDemoDrawer ||
    showBookDemoThanks ||
    showWannaUnlockModel ||
    showAppDownloadModel ||
    ctaIntervalBlock ||
    showingCtaETId !== null
  )
    return;

  const newCount = ctaIntervalCount + 1;

  if (newCount === TriggerCount) {
    setShowingCtaETId("8");

    setTimeout(
      () => setCtaIntervalDisplayCount(ctaIntervalDisplayCount + 1),
      100,
    );
    setTimeout(() => setCtaIntervalCount(0), 200);
  } else {
    setCtaIntervalCount(newCount);
  }
}
