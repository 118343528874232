export const videoUrlMapping: {
  [key: string]: string;
} = {
  counting: "https://vimeo.com/1006461067",
  sharing: "https://vimeo.com/1006461190",
  "flour, sugar, milk and butter": "https://vimeo.com/1006461183",
  "hello!": "https://vimeo.com/1006461148",
  "i'm standing in the box": "https://vimeo.com/1006461172",
  "up and down": "https://vimeo.com/1006461135",
  "what's your name?": "https://vimeo.com/1006461050",
  "my little car": "https://vimeo.com/1006461079",
  "the abc song": "https://vimeo.com/1006461037",
  "peekaboo!": "https://vimeo.com/1006461160",
  "the bellybutton song": "https://vimeo.com/1006461125",
  "i'm walking": "https://vimeo.com/1006461092",
  "open your hands and close them": "https://vimeo.com/1006461110",
  "clap your hands": "https://vimeo.com/1006461008",
  balloons: "https://vimeo.com/1006460989",
  "yummy, yummy food": "https://vimeo.com/1006461023",
  "hey, mr.strongman!": "https://vimeo.com/1006460949",
  "taking turns": "https://vimeo.com/1006460976",
  "introducing disney friends": "https://vimeo.com/1006460930",
  "look up! the sun is shining": "https://vimeo.com/1006460964",
  "baby's in the bathtub": "https://vimeo.com/1024646551",
};

export const introVideoUrlMapping: {
  [key: string]: string;
} = {
  counting: "https://vimeo.com/1006507403",
  sharing: "https://vimeo.com/1006507109",
  "flour, sugar, milk and butter": "https://vimeo.com/1006507512",
  "hello!": "https://vimeo.com/1006507378",
  "i'm standing in the box": "https://vimeo.com/1006507491",
  "up and down": "https://vimeo.com/1006507171",
  "what's your name?": "https://vimeo.com/1006507147",
  "my little car": "https://vimeo.com/1006507282",
  "the abc song": "https://vimeo.com/1006507185",
  "peekaboo!": "https://vimeo.com/1006507241",
  "the bellybutton song": "https://vimeo.com/1006507208",
  "i'm walking": "https://vimeo.com/1006507336",
  "open your hands and close them": "https://vimeo.com/1006507261",
  "clap your hands": "https://vimeo.com/1006507429",
  balloons: "https://vimeo.com/1006507448",
  "yummy, yummy food": "https://vimeo.com/1006507130",
  "hey, mr.strongman!": "https://vimeo.com/1006507354",
  "taking turns": "https://vimeo.com/1006507227",
  "introducing disney friends": "https://vimeo.com/1006507467",
  "look up! the sun is shining": "https://vimeo.com/1006507310",
  "baby's in the bathtub": "https://vimeo.com/1024646259",
};
