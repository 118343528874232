import Text from "../../../components/ui/Text";

export default function LyricsError() {
  return (
    <div
      className="flex items-center justify-center"
      style={{ height: "calc(100vh - 489px)" }}
    >
      <Text lg className="w-[220px] text-center text-white">
        An error occurred while retrieving the lyrics
      </Text>
    </div>
  );
}
