import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import storyBooks from "../../assets/images/story/storyBooks.png";
import orangeBg from "../../assets/images/shared/orangeBg.png";
import Text from "../../components/ui/Text";
import BookButton from "../bookDemo/BookButton";
import DisneyButton from "./components/DisneyButton";

export default function StoryHome() {
  const { t } = useTranslation();

  const mainImageRef = useRef<HTMLDivElement>(null);

  const [backgroundTop, setBackgroundTop] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      if (mainImageRef.current) {
        const rect = mainImageRef.current.getBoundingClientRect();
        const mainImageOffsetTop = rect.top;
        const diff = 137;
        const menuHeight = 122;

        setBackgroundTop(mainImageOffsetTop - diff - menuHeight);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="relative h-full w-full ">
      <img
        src={orangeBg}
        alt="orange bg"
        className="absolute left-0 max-h-[408px] w-full"
        style={{
          top: backgroundTop,
        }}
      />

      <div className="relative z-[1] flex flex-col items-center">
        <Text className="mt-5 text-center text-[22px]">{t("story")}</Text>

        <div className="min-h-[164px] w-[310px]">
          <Text normal>{t("storyDescription")}</Text>
        </div>

        <div ref={mainImageRef} className="mb-6 mt-8 w-[350px]">
          <img src={storyBooks} alt="storyBooks" className="w-[350px]" />
        </div>

        <DisneyButton />
      </div>

      <BookButton sourcePage="Story Home" />
    </div>
  );
}
