import { Dispatch, RefObject, SetStateAction } from "react";

import { LrcDataType } from "../types";

function isNextLyricLine(
  lyrics: LrcDataType[],
  currentMillisecond: number,
  currentLyricIndex: number,
  i: number
) {
  return (
    currentMillisecond >= lyrics[i].time &&
    (!lyrics[i + 1] || currentMillisecond < lyrics[i + 1].time) &&
    currentLyricIndex !== i
  );
}

function scroll(ref: RefObject<HTMLDivElement>) {
  if (!ref.current) return;
  const playedLyrics = ref.current.querySelectorAll(".played-lyric");
  let totalHeight = 0;

  playedLyrics.forEach((lyric) => {
    totalHeight += lyric.getBoundingClientRect().height;
  });

  // including the gap-6
  ref.current.scrollTo({
    top: totalHeight + playedLyrics.length * 24,
    behavior: "smooth",
  });
}

export default function lyricsScrollHandler(
  ref: RefObject<HTMLDivElement>,
  lyrics: LrcDataType[],
  currentMillisecond: number,
  currentLyricIndex: number,
  setCurrentLyricIndex: Dispatch<SetStateAction<number>>
) {
  for (let i = 0; i < lyrics.length; i++) {
    if (isNextLyricLine(lyrics, currentMillisecond, currentLyricIndex, i)) {
      setCurrentLyricIndex(i);
      scroll(ref);

      break;
    }
  }
}
