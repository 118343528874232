import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DndContext } from "@dnd-kit/core";

import mpCard from "../../assets/images/magicPen/mpCard.webp";
import bubble3 from "../../assets/images/magicPen/bubble3.png";
import mickeyPen from "../../assets/images/magicPen/mickeyPen2.png";
import minniePen from "../../assets/images/magicPen/minniePen2.png";
import GameTitle from "../../components/game/GameTitle";
import useCtaStore from "../../hooks/store/useCtaStore";
import useStore from "../../hooks/store/useStore";
import { magicPenMaxWidth } from "../../layout/game/constants";
import cn from "../../utils/cn";
import ctaHandler5 from "../../utils/ctaHandlers/ctaHandler5";

import DraggablePen from "./components/DraggablePen";
import MagicPenDialog from "./components/MagicPenDialog";
import Tips from "./components/Tips";
import getPlayOrder from "./utils/getPlayOrder";
import getPenResetPosition from "./utils/getPenResetPosition";
import isPenPointOnABubble from "./utils/isPenPointOnABubble";
import { isPhase1 } from "./utils/judgements";
import scrollCard from "./utils/scrollCard";
import { magicPenGameData, cardPaddingTop } from "./constants";
import { MagicPenGameState } from "./types";

export default function MagicPen() {
  const { t } = useTranslation();

  const cardRef = useRef<HTMLDivElement>(null);

  const q0Ref = useRef<HTMLAudioElement>(null);
  const q1Ref = useRef<HTMLAudioElement>(null);
  const q2Ref = useRef<HTMLAudioElement>(null);
  const q3Ref = useRef<HTMLAudioElement>(null);
  const q4Ref = useRef<HTMLAudioElement>(null);
  const q5Ref = useRef<HTMLAudioElement>(null);
  const q6Ref = useRef<HTMLAudioElement>(null);
  const q7Ref = useRef<HTMLAudioElement>(null);
  const q8Ref = useRef<HTMLAudioElement>(null);
  const a0Ref = useRef<HTMLAudioElement>(null);
  const a1Ref = useRef<HTMLAudioElement>(null);
  const a2Ref = useRef<HTMLAudioElement>(null);
  const a3Ref = useRef<HTMLAudioElement>(null);
  const a4Ref = useRef<HTMLAudioElement>(null);
  const a5Ref = useRef<HTMLAudioElement>(null);
  const a6Ref = useRef<HTMLAudioElement>(null);
  const a7Ref = useRef<HTMLAudioElement>(null);
  const a8Ref = useRef<HTMLAudioElement>(null);

  const refMapping = [
    {
      question: q0Ref,
      answer: a0Ref,
    },
    {
      question: q1Ref,
      answer: a1Ref,
    },
    {
      question: q2Ref,
      answer: a2Ref,
    },
    {
      question: q3Ref,
      answer: a3Ref,
    },
    {
      question: q4Ref,
      answer: a4Ref,
    },
    {
      question: q5Ref,
      answer: a5Ref,
    },
    {
      question: q6Ref,
      answer: a6Ref,
    },
    {
      question: q7Ref,
      answer: a7Ref,
    },
    {
      question: q8Ref,
      answer: a8Ref,
    },
  ];

  const [gameState, setGameState] =
    useState<MagicPenGameState>("waitingClick1");
  const [playOrder, setPlayOrder] = useState<number[]>([
    0, 1, 2, 3, 4, 5, 6, 7, 8,
  ]);
  const [playIndex, setPlayIndex] = useState<number>(0);
  const [penPosition, setPenPosition] = useState({ x: 0, y: 0 });

  const { selectedPen } = useStore((state) => state.global);
  const { userInformation } = useStore((state) => state.user);
  const { setShowingCtaETId } = useCtaStore((state) => state.cta);
  const { magicPenCount, setMagicPenCount } = useCtaStore(
    (state) => state.ctaCount,
  );

  const pen = selectedPen === "mickey" ? mickeyPen : minniePen;
  const { question, answer } = magicPenGameData[playOrder[playIndex]];
  const { top, left } = isPhase1(gameState) ? question : answer;
  const bubbleZIndex =
    gameState === "waitingClick1" || gameState === "waitingClick2"
      ? "z-30"
      : "z-10";

  const handleDragEnd = () => {
    if (isPenPointOnABubble(selectedPen, penPosition, top, left)) {
      if (gameState === "waitingClick1") {
        setGameState("questionPlaying");

        const audioRef = refMapping[playOrder[playIndex]].question;
        audioRef.current?.play();
      } else if (gameState === "waitingClick2") {
        setGameState("answerPlaying");

        const audioRef = refMapping[playOrder[playIndex]].answer;
        audioRef.current?.play();
      }
    }
  };

  const handleAudioQuestionEnded = () => {
    if (magicPenMaxWidth > window.innerWidth) {
      scrollCard(cardRef, answer.left);
      setTimeout(() => setPenPosition(getPenResetPosition(answer.left)), 400);
    }

    setGameState("waitingClick2");
  };

  const handleAudioAnswerEnded = () => {
    setGameState("waitingClick1");

    let nextIndex = playIndex + 1;

    if (nextIndex === playOrder.length) {
      setPlayOrder(getPlayOrder());
      setPlayIndex(0);
      return;
    }

    setPlayIndex(nextIndex);

    ctaHandler5(
      userInformation,
      magicPenCount,
      setMagicPenCount,
      setShowingCtaETId,
    );
  };

  useEffect(() => {
    if (window.innerWidth >= magicPenMaxWidth) return;

    scrollCard(cardRef, left);
    setTimeout(() => setPenPosition(getPenResetPosition(left)), 400);
  }, [left]);

  useEffect(() => {
    setPlayOrder(getPlayOrder());
    setPenPosition(getPenResetPosition(left));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GameTitle text="Magic Pen" />

      <div className="relative flex h-full flex-1 flex-col bg-[#ffe5d1]">
        <div className="absolute left-0 top-0 w-full">
          {gameState === "waitingClick1" ? (
            <Tips text={t("magicPenTipsText")} />
          ) : (
            <MagicPenDialog
              playOrder={playOrder}
              playIndex={playIndex}
              gameState={gameState}
            />
          )}
        </div>

        <DndContext onDragEnd={handleDragEnd}>
          <div
            ref={cardRef}
            className="hide-scrollbar relative w-screen overflow-y-auto pt-[112px]"
          >
            <div className="w-[805px] px-2.5 pb-5">
              <img src={mpCard} alt="mpCard" className="h-[493px] w-[805px]" />

              <img
                src={bubble3}
                alt="bubble3"
                className={cn(bubbleZIndex, "floating absolute size-[84px]")}
                style={{ top: `${top + cardPaddingTop}px`, left: `${left}px` }}
              />

              <DraggablePen
                pen={pen}
                penPosition={penPosition}
                setPenPosition={setPenPosition}
                cardRef={cardRef}
              />
            </div>
          </div>
        </DndContext>
      </div>

      <audio
        src={magicPenGameData[0].question.audio}
        ref={q0Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[1].question.audio}
        ref={q1Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[2].question.audio}
        ref={q2Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[3].question.audio}
        ref={q3Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[4].question.audio}
        ref={q4Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[5].question.audio}
        ref={q5Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[6].question.audio}
        ref={q6Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[7].question.audio}
        ref={q7Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[8].question.audio}
        ref={q8Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[0].answer.audio}
        ref={a0Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[1].answer.audio}
        ref={a1Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[2].answer.audio}
        ref={a2Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[3].answer.audio}
        ref={a3Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[4].answer.audio}
        ref={a4Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[5].answer.audio}
        ref={a5Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[6].answer.audio}
        ref={a6Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[7].answer.audio}
        ref={a7Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[8].answer.audio}
        ref={a8Ref}
        onEnded={handleAudioAnswerEnded}
      />
    </>
  );
}
