import Dialog from "../../../components/game/Dialog";
import { isTextPhase1 } from "../utils/judgements";
import { magicPenGameData } from "../constants";
import { MagicPenGameState } from "../types";

export default function MagicPenDialog({
  playOrder,
  playIndex,
  gameState,
}: {
  playOrder: number[];
  playIndex: number;
  gameState: MagicPenGameState;
}) {
  const { question, answer } = magicPenGameData[playOrder[playIndex]];
  const isPhase1 = isTextPhase1(gameState);
  const { text } = isPhase1 ? question : answer;

  return (
    <Dialog
      text={text}
      blue={!isPhase1}
      wrapperClassName="items-start"
      className="w-[284px]"
    />
  );
}
