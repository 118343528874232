import { Dispatch, SetStateAction } from "react";

const delay = 1000;

const withDelay = (
  callback: Function,
  unknown: boolean,
  setUnknown: Dispatch<SetStateAction<boolean>>,
) => {
  return () => {
    if (unknown) {
      setTimeout(() => {
        callback();

        setUnknown(false);
      }, delay);
    } else {
      callback();
    }
  };
};

export default withDelay;
