import { CenterCardDuration } from "../../qaCards/constants";
import getDegree from "./utils/getDegree";

const screenWidth = 600;
const padding = 32; // left-8

export const moveToLeft = (duration: number, width: number) => {
  const x = screenWidth - padding * 2 - width;

  return {
    to: async (next: any) => {
      await next({
        transform: "translateX(0px) translateY(0px)",
        immediate: true,
      });
      await next({ transform: `translateX(-${x}px) translateY(0px)` });
    },
    from: { transform: "translateX(0px) translateY(0px)" },
    config: { duration },
  };
};

export const centering = (isRect: boolean, backAudioIndex: number) => {
  const left = isRect ? 185 : 162;
  const scale = isRect ? "1.44" : "1.31";
  const deg = getDegree(isRect, backAudioIndex)[0];

  return {
    from: {
      left: 32, // left-8
      transform: `rotate(${deg}) scale(1)`,
    },
    to: {
      left,
      transform: `rotate(${deg}) scale(${scale})`,
    },
    config: { duration: CenterCardDuration },
  };
};
