import { create } from "zustand";

import { CtaStoreState } from "./types";

const useCtaStore = create<CtaStoreState>((set) => ({
  cta: {
    showingCtaETId: null,
    setShowingCtaETId: (data) =>
      set((state) => ({
        cta: { ...state.cta, showingCtaETId: data },
      })),
  },
  ctaCount: {
    unlockedSongsCount: [],
    setUnlockedSongsCount: (array) =>
      set((state) => ({
        ctaCount: { ...state.ctaCount, unlockedSongsCount: array },
      })),
    unlockedVideosCounts: {
      tbc: [],
      pa: [],
      sa: [],
    },
    setUnlockedVideosCounts: (object) =>
      set((state) => ({
        ctaCount: {
          ...state.ctaCount,
          unlockedVideosCounts: {
            ...state.ctaCount.unlockedVideosCounts,
            ...object,
          },
        },
      })),
    lockedVideosCount: 0,
    setLockedVideosCount: (count) =>
      set((state) => ({
        ctaCount: { ...state.ctaCount, lockedVideosCount: count },
      })),
    magicPenCount: 0,
    setMagicPenCount: (count) =>
      set((state) => ({
        ctaCount: { ...state.ctaCount, magicPenCount: count },
      })),
  },
  ctaInterval: {
    ctaIntervalCount: 0,
    setCtaIntervalCount: (count) =>
      set((state) => ({
        ctaInterval: { ...state.ctaInterval, ctaIntervalCount: count },
      })),
    ctaIntervalBlock: false,
    setCtaIntervalBlock: (value) =>
      set((state) => ({
        ctaInterval: {
          ...state.ctaInterval,
          ctaIntervalBlock: value,
          ...(value ? { ctaIntervalCount: 0 } : {}),
        },
      })),
    ctaIntervalDisplayCount: 0,
    setCtaIntervalDisplayCount: (count) =>
      set((state) => ({
        ctaInterval: { ...state.ctaInterval, ctaIntervalDisplayCount: count },
      })),
  },
}));

export default useCtaStore;
