import { create } from "zustand";

import { StoreState } from "./types";

const useStore = create<StoreState>((set) => ({
  global: {
    source: null,
    setSource: (data) =>
      set((state) => ({
        global: { ...state.global, source: data },
      })),
    language: null,
    setLanguage: (data) =>
      set((state) => ({
        global: { ...state.global, language: data },
      })),
    selectedGame: null,
    setSelectedGame: (name) =>
      set((state) => ({
        global: { ...state.global, selectedGame: name },
      })),
    selectedPen: "mickey",
    setSelectedPen: (pen) =>
      set((state) => ({
        global: { ...state.global, selectedPen: pen },
      })),
  },
  tokens: {
    tokenId: null,
    accessKey: null,
    jwtToken: null,
    setTokenId: (data) =>
      set((state) => ({
        tokens: { ...state.tokens, tokenId: data },
      })),
    setTokens: ({ accessKey, jwtToken }) =>
      set((state) => ({
        tokens: { ...state.tokens, accessKey, jwtToken },
      })),
  },
  user: {
    userInformation: null,
    setUserInformation: (data) =>
      set((state) => ({
        user: { ...state.user, userInformation: data },
      })),
  },
  bookDemo: {
    showBookDemoDrawer: false,
    setShowBookDemoDrawer: (value) =>
      set((state) => ({
        bookDemo: { ...state.bookDemo, showBookDemoDrawer: value },
      })),
    showBookDemoThanks: false,
    setShowBookDemoThanks: (value) =>
      set((state) => ({
        bookDemo: { ...state.bookDemo, showBookDemoThanks: value },
      })),
    showWannaUnlockModel: false,
    setShowWannaUnlockModel: (value) =>
      set((state) => ({
        bookDemo: { ...state.bookDemo, showWannaUnlockModel: value },
      })),
  },
  webVersion: {
    showAppDownloadModel: false,
    setShowAppDownloadModel: (value) =>
      set((state) => ({
        webVersion: { ...state.webVersion, showAppDownloadModel: value },
      })),
  },
}));

export default useStore;
