import {
  UnlockedVideosCountsObject,
  UnlockedVideosCountsType,
} from "../../hooks/store/types";
import { VideoListType } from "../../pages/watch/types";
import { UserInformation } from "../../types";

/**
 * ctaExperienceTypeId: 3
 * Try to rewatch unlocked videos
 * Awareness
 */
export default function ctaHandler3(
  userInformation: UserInformation | null,
  listType: VideoListType,
  videoIndex: number,
  unlockedVideosCounts: UnlockedVideosCountsType,
  setUnlockedVideosCounts: (object: UnlockedVideosCountsObject) => void,
  setShowingCtaETId: (data: string | null) => void
): boolean {
  const { trialUserSegmentation: seg } = userInformation || {};

  if (seg !== "Awareness") return false;

  const newCount = [...unlockedVideosCounts[listType]];

  if (newCount[videoIndex] === undefined) {
    newCount[videoIndex] = 1;
  } else {
    newCount[videoIndex] += 1;
  }

  setUnlockedVideosCounts({ [listType]: newCount });

  if (newCount[videoIndex] % 2 === 0) {
    setShowingCtaETId("3");

    return true;
  }

  return false;
}
