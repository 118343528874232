import { CSSProperties, ReactNode } from "react";

import cn from "../../utils/cn";

export default function Touchable({
  children,
  className,
  style,
  onClick,
}: {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: (e?: any) => void;
}) {
  return (
    <div
      className={cn("cursor-pointer active:opacity-75", className)}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
