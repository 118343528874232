import { Dispatch, SetStateAction, useState } from "react";

import MobileIcon1 from "../../../assets/images/webLogin/mobile1.png";
import MobileIcon2 from "../../../assets/images/webLogin/mobile2.png";
import MobileIcon3 from "../../../assets/images/webLogin/mobile3.png";
import cn from "../../../utils/cn";
import Text from "../../../components/ui/Text";

export default function PhoneNumberInput({
  placeholder,
  setInputValue,
  errorMessage,
}: {
  placeholder?: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  errorMessage: string;
}) {
  const [isFocused, setIsFocused] = useState(false);

  const icon = errorMessage
    ? MobileIcon3
    : isFocused
      ? MobileIcon2
      : MobileIcon1;
  const borderColor = errorMessage
    ? "border0"
    : isFocused
      ? "border-pink2"
      : "border-greyType102";
  const caretColor = errorMessage
    ? "caret-red0"
    : isFocused
      ? "caret-orange"
      : "caret-grey4";
  const placeholderText = isFocused || errorMessage ? "" : placeholder;

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="relative mb-10 w-fit">
      <img src={icon} alt="mobile" className="absolute left-3 top-2.5 size-6" />

      <input
        type="text"
        className={cn(
          "h-11 w-[310px] flex-1 rounded-full border border-solid text-center font-normal text-black8 focus:outline-none focus:ring-0",
          borderColor,
          caretColor,
        )}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handleInputChange}
        placeholder={placeholderText}
      />

      {errorMessage ? (
        <Text normal className="absolute left-0 top-12 text-red0">
          {errorMessage}
        </Text>
      ) : null}
    </div>
  );
}
