import { Dispatch, SetStateAction } from "react";

import playIcon from "../../../assets/images/listen/playlist/play-2.svg";
import lockIcon from "../../../assets/images/listen/playlist/lock.svg";
import playingIcon from "../../../assets/images/listen/playlist/playing.svg";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import useCtaStore from "../../../hooks/store/useCtaStore";
import useStore from "../../../hooks/store/useStore";
import useEventTracking from "../../../services/EventTracking/useEventTracking";
import cn from "../../../utils/cn";
import ctaHandler2 from "../../../utils/ctaHandlers/ctaHandler2";
import withDelay from "../utils/withDelay";
import { SongDataType } from "../types";

export default function PlayList({
  songs,
  unlockedSongs,
  currentSongIndex,
  setCurrentSong,
  setUnlockedSongIndex,
  isPlaying,
  setIsPlaying,
  unknown,
  setUnknown,
}: {
  songs: SongDataType[];
  unlockedSongs: SongDataType[];
  currentSongIndex: number;
  setCurrentSong: Dispatch<SetStateAction<SongDataType | null>>;
  setUnlockedSongIndex: Dispatch<SetStateAction<number>>;
  isPlaying: boolean;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
  unknown: boolean;
  setUnknown: Dispatch<SetStateAction<boolean>>;
}) {
  const { userInformation } = useStore((state) => state.user);
  const { setShowingCtaETId } = useCtaStore((state) => state.cta);
  const { setCtaIntervalBlock } = useCtaStore((state) => state.ctaInterval);

  const mutation = useEventTracking();

  const selectSong = (songId: string, locked: boolean) => {
    if (locked) {
      const isCtaTriggered = ctaHandler2(userInformation, setShowingCtaETId);

      if (isCtaTriggered && isPlaying) setIsPlaying(false);

      return;
    }

    const index = unlockedSongs.findIndex((s) => s.songId === songId);

    setCurrentSong(unlockedSongs[index]);
    setUnlockedSongIndex(index);

    setCtaIntervalBlock(true);

    setTimeout(() => {
      setIsPlaying(true);
    }, 500);
  };

  return (
    <div className="flex flex-col gap-5 py-6">
      {songs.map((song, index) => {
        const textStyle =
          currentSongIndex === index ? "text-yellow" : "text-white";
        const icon = song.locked
          ? lockIcon
          : currentSongIndex === index
            ? playingIcon
            : playIcon;

        return (
          <Touchable
            key={song.songTitle}
            className="flex h-11 cursor-pointer items-center gap-4"
            onClick={withDelay(
              () => {
                selectSong(song.songId, song.locked);

                mutation.mutate({
                  pageId: "Listen",
                  eventId: song.songTitle,
                });
              },
              unknown,
              setUnknown,
            )}
          >
            <img className="size-11" src={icon} alt={song.songTitle} />
            <Text lg className={cn(textStyle)}>
              {song.songTitle}
            </Text>
          </Touchable>
        );
      })}
    </div>
  );
}
