import Text from "../../../components/ui/Text";

export default function Label({ text, url }: { text: string; url: string }) {
  return (
    <div className="relative my-4 flex h-12 flex-row-reverse items-center overflow-hidden rounded-lg bg-white px-5 shadow">
      <div className="absolute left-0 top-0">
        <img src={url} alt={text} className="h-12" />
      </div>

      <Text lg>{text}</Text>
    </div>
  );
}
