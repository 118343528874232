import { useState } from "react";
import { useTranslation } from "react-i18next";
import { X } from "lucide-react";

import intro from "../../assets/images/magicPen/intro.gif";
import SlideInAnimation from "../../components/animation/SlideInAnimation";
import GameTitle from "../../components/game/GameTitle";
import Text from "../../components/ui/Text";
import Touchable from "../../components/ui/Touchable";
import PenImage from "./components/PenImage";
import TryItCard from "./components/TryItCard";
import DisneyButton from "./components/DisneyButton";

export default function MagicPenHome() {
  const { t } = useTranslation();

  const [showPens, setShowPens] = useState(false);

  return (
    <>
      <GameTitle text="Magic Pen" />

      <div className="flex h-full flex-1 flex-col">
        <div className="mb-6 px-6">
          <Text normal>{t("magicPenDesc")}</Text>
        </div>

        <div className="relative w-full">
          <img src={intro} alt="intro" className="h-[390px] object-cover" />

          <div className="absolute left-0 top-0 flex h-[390px] w-full flex-col justify-between px-6 py-4">
            <Text
              className="text-2xl text-white"
              style={{ textShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)" }}
            >
              {t("magicPenVideoTitle")}
            </Text>
          </div>
        </div>

        <div className="flex flex-1 justify-center bg-gradient-to-b from-[#FDE0C8] to-pink1 py-4">
          <TryItCard onClick={() => setShowPens(true)} />
        </div>

        <SlideInAnimation
          isVisible={showPens}
          className="fixed bottom-0 left-0 flex h-[486px] w-full flex-col items-center gap-6 rounded-t-3xl bg-gradient-to-t from-pink2 to-pink1 pt-3"
        >
          <div className="relative flex h-11 w-full items-center justify-center">
            <Text lg>{t("chooseYourMagicPen")}</Text>

            <Touchable onClick={() => setShowPens(false)}>
              <X className="absolute right-0 top-0 mx-4 my-2.5 size-6" />
            </Touchable>
          </div>

          <div className="flex w-full max-w-[480px] flex-col gap-6">
            <div className="flex h-[270px] justify-around px-10">
              <PenImage type="mickey" />
              <PenImage type="minnie" />
            </div>

            <div className="flex h-[54px] justify-around px-9">
              <DisneyButton type="mickey" />
              <DisneyButton type="minnie" />
            </div>
          </div>
        </SlideInAnimation>
      </div>
    </>
  );
}
