import { useEffect, useRef, useState } from "react";
import { DndContext } from "@dnd-kit/core";

import mpCard from "../../../assets/images/magicPen/mpCard.webp";
import bubble3 from "../../../assets/images/magicPen/bubble3.png";
import Mask from "../../../components/ui/Mask";
import useCtaStore from "../../../hooks/store/useCtaStore";
import useStore from "../../../hooks/store/useStore";
import cn from "../../../utils/cn";
import ctaHandler5 from "../../../utils/ctaHandlers/ctaHandler5";
import { SourceType } from "../../../types";

import DemoGameWrapper from "../components/DemoGameWrapper";
import DemoGameMenu from "../components/DemoGameMenu";
import DemoGameBoardWrapper from "../components/DemoGameBoardWrapper";
import DemoGameVideo from "../components/DemoGameVideo";
import DemoGameQRCode from "../components/DemoGameQRCode";

import DraggablePen from "./components/DraggablePen";
import MagicPenDialog from "./components/MagicPenDialog";
import PenMenu from "./components/PenMenu";
import getPlayOrder from "./utils/getPlayOrder";
import isPenPointOnABubble from "./utils/isPenPointOnABubble";
import { isPhase1, isWaiting } from "./utils/judgements";
import { magicPenGameData } from "./constants";
import { MagicPenGameState } from "./types";

export default function DemoMagicPen() {
  const cardRef = useRef<HTMLDivElement>(null);

  const q0Ref = useRef<HTMLAudioElement>(null);
  const q1Ref = useRef<HTMLAudioElement>(null);
  const q2Ref = useRef<HTMLAudioElement>(null);
  const q3Ref = useRef<HTMLAudioElement>(null);
  const q4Ref = useRef<HTMLAudioElement>(null);
  const q5Ref = useRef<HTMLAudioElement>(null);
  const q6Ref = useRef<HTMLAudioElement>(null);
  const q7Ref = useRef<HTMLAudioElement>(null);
  const q8Ref = useRef<HTMLAudioElement>(null);
  const a0Ref = useRef<HTMLAudioElement>(null);
  const a1Ref = useRef<HTMLAudioElement>(null);
  const a2Ref = useRef<HTMLAudioElement>(null);
  const a3Ref = useRef<HTMLAudioElement>(null);
  const a4Ref = useRef<HTMLAudioElement>(null);
  const a5Ref = useRef<HTMLAudioElement>(null);
  const a6Ref = useRef<HTMLAudioElement>(null);
  const a7Ref = useRef<HTMLAudioElement>(null);
  const a8Ref = useRef<HTMLAudioElement>(null);

  const refMapping = [
    {
      question: q0Ref,
      answer: a0Ref,
    },
    {
      question: q1Ref,
      answer: a1Ref,
    },
    {
      question: q2Ref,
      answer: a2Ref,
    },
    {
      question: q3Ref,
      answer: a3Ref,
    },
    {
      question: q4Ref,
      answer: a4Ref,
    },
    {
      question: q5Ref,
      answer: a5Ref,
    },
    {
      question: q6Ref,
      answer: a6Ref,
    },
    {
      question: q7Ref,
      answer: a7Ref,
    },
    {
      question: q8Ref,
      answer: a8Ref,
    },
  ];

  const [gameState, setGameState] =
    useState<MagicPenGameState>("waitingClick1");
  const [playOrder, setPlayOrder] = useState<number[]>([
    0, 1, 2, 3, 4, 5, 6, 7, 8,
  ]);
  const [playIndex, setPlayIndex] = useState<number>(0);
  const [penPosition, setPenPosition] = useState({ x: 0, y: 0 });
  const [shouldOpenIntroVideo, setShouldOpenIntroVideo] =
    useState<boolean>(false);
  const [shouldOpenQRCodeModel, setShouldOpenQRCodeModel] =
    useState<boolean>(false);

  const { source, selectedPen } = useStore((state) => state.global);
  const { userInformation } = useStore((state) => state.user);
  const { setShowingCtaETId } = useCtaStore((state) => state.cta);
  const { magicPenCount, setMagicPenCount } = useCtaStore(
    (state) => state.ctaCount,
  );

  const { question, answer } = magicPenGameData[playOrder[playIndex]];
  const { top, left } = isPhase1(gameState) ? question : answer;
  const bubbleZIndex =
    gameState === "waitingClick1" || gameState === "waitingClick2"
      ? "z-30"
      : "z-10";

  const handleDragEnd = () => {
    if (isPenPointOnABubble(selectedPen, penPosition, top, left)) {
      if (gameState === "waitingClick1") {
        setGameState("questionPlaying");

        const audioRef = refMapping[playOrder[playIndex]].question;
        audioRef.current?.play();
      } else if (gameState === "waitingClick2") {
        setGameState("answerPlaying");

        const audioRef = refMapping[playOrder[playIndex]].answer;
        audioRef.current?.play();
      }
    }
  };

  const handleAudioQuestionEnded = () => {
    setGameState("waitingClick2");
  };

  const handleAudioAnswerEnded = () => {
    setGameState("waitingClick1");

    let nextIndex = playIndex + 1;

    if (nextIndex === playOrder.length) {
      setPlayOrder(getPlayOrder());
      setPlayIndex(0);
      return;
    }

    setPlayIndex(nextIndex);

    ctaHandler5(
      userInformation,
      magicPenCount,
      setMagicPenCount,
      setShowingCtaETId,
    );
  };

  useEffect(() => {
    setPlayOrder(getPlayOrder());
    setPenPosition({ x: 506, y: -50 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (source !== SourceType.DemoApp) return <div>Page Not Found</div>;

  return (
    <>
      {isWaiting(gameState) ? null : <Mask />}

      <DemoGameWrapper>
        <DemoGameMenu
          gameType="magicPen"
          setShouldOpenIntroVideo={setShouldOpenIntroVideo}
          setShouldOpenQRCodeModel={setShouldOpenQRCodeModel}
        >
          <PenMenu />
        </DemoGameMenu>

        <DemoGameBoardWrapper>
          <DndContext onDragEnd={handleDragEnd}>
            {/* 557 = dialog:40 + gap:32 + board:365 + py:120 */}
            <div
              ref={cardRef}
              className="relative h-[557px] w-[720px] p-[60px]"
            >
              <div className="absolute left-[60px] top-[60px] w-[600px]">
                <MagicPenDialog
                  playOrder={playOrder}
                  playIndex={playIndex}
                  gameState={gameState}
                />
              </div>

              <img
                src={mpCard}
                alt="mpCard"
                className="mt-[72px] h-[365px] w-[600px]"
              />

              <img
                src={bubble3}
                alt="bubble3"
                className={cn(bubbleZIndex, "floating absolute size-[84px]")}
                style={{ top: `${top}px`, left: `${left}px` }}
              />

              <DraggablePen
                penPosition={penPosition}
                setPenPosition={setPenPosition}
              />
            </div>
          </DndContext>
        </DemoGameBoardWrapper>
      </DemoGameWrapper>

      {shouldOpenIntroVideo ? (
        <DemoGameVideo
          gameType="magicPen"
          setShouldOpenIntroVideo={setShouldOpenIntroVideo}
        />
      ) : null}

      {shouldOpenQRCodeModel ? (
        <DemoGameQRCode setShouldOpenQRCodeModel={setShouldOpenQRCodeModel} />
      ) : null}

      <audio
        src={magicPenGameData[0].question.audio}
        ref={q0Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[1].question.audio}
        ref={q1Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[2].question.audio}
        ref={q2Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[3].question.audio}
        ref={q3Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[4].question.audio}
        ref={q4Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[5].question.audio}
        ref={q5Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[6].question.audio}
        ref={q6Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[7].question.audio}
        ref={q7Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[8].question.audio}
        ref={q8Ref}
        onEnded={handleAudioQuestionEnded}
      />
      <audio
        src={magicPenGameData[0].answer.audio}
        ref={a0Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[1].answer.audio}
        ref={a1Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[2].answer.audio}
        ref={a2Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[3].answer.audio}
        ref={a3Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[4].answer.audio}
        ref={a4Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[5].answer.audio}
        ref={a5Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[6].answer.audio}
        ref={a6Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[7].answer.audio}
        ref={a7Ref}
        onEnded={handleAudioAnswerEnded}
      />
      <audio
        src={magicPenGameData[8].answer.audio}
        ref={a8Ref}
        onEnded={handleAudioAnswerEnded}
      />
    </>
  );
}
