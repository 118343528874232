import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { getVideoUrl } from "../utils/getVideoUrl";
import { FeaturedEventDataType } from "../types";
import EventVideo from "./EventVideo";

export default function FeaturedEventVideo({
  data,
  index,
  currentVideoIndex,
  setCurrentVideoIndex,
}: {
  data: FeaturedEventDataType;
  index: number;
  currentVideoIndex: number;
  setCurrentVideoIndex: Dispatch<SetStateAction<number>>;
}) {
  const { navigationUrl } = data;
  const videoUrl = getVideoUrl(navigationUrl);

  const [showVideo, setShowVideo] = useState<boolean>(false);

  useEffect(() => {
    if (index === currentVideoIndex) setShowVideo(true);
  }, [index, currentVideoIndex]);

  return showVideo ? (
    <EventVideo
      videoUrl={videoUrl}
      setShowVideo={setShowVideo}
      setCurrentVideoIndex={setCurrentVideoIndex}
    />
  ) : null;
}
