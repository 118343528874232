import blueFrame1 from "../../../assets/images/qaCards/blueFrame1.png";
import blueFrame2 from "../../../assets/images/qaCards/blueFrame2.png";
import Droppable from "../../../components/game/Droppable";
import { QACardType } from "../../../types";

export default function BlueFrame({
  isDragging,
}: {
  isDragging: QACardType | null;
}) {
  return (
    <div className="absolute -top-[200px] left-0 z-10 h-[230px] w-full">
      {isDragging ? (
        <Droppable className="absolute bottom-0 right-4">
          {isDragging === "qa39" ? (
            <img src={blueFrame2} alt="blueFrame2" className="size-[168px]" />
          ) : (
            <img
              src={blueFrame1}
              alt="blueFrame1"
              className="h-[205px] w-[214px]"
            />
          )}
        </Droppable>
      ) : null}
    </div>
  );
}
