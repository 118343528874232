import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ko from "./ko";
import zhHK from "./zh-HK";
import zhTW from "./zh-TW";

i18n.use(initReactI18next).init({
  resources: {
    ko: {
      translation: ko,
    },
    "zh-HK": {
      translation: zhHK,
    },
    "zh-TW": {
      translation: zhTW,
    },
  },
  lng: "ko",
  fallbackLng: "ko",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
