import { QACardType } from "../../../../types";
import { qacAudioData } from "../../../qaCards/constants";
import { QACGameState } from "../../../qaCards/types";
import DemoGameDialog from "../../components/DemoGameDialog";
import { showBackText } from "../utils/judgements";

export default function QACDialog({
  currentCard,
  gameState,
  frontAudioIndex,
  backAudioIndex,
}: {
  currentCard: QACardType | null;
  gameState: QACGameState;
  frontAudioIndex: number;
  backAudioIndex: number;
}) {
  if (currentCard === null || gameState === "waitingForDragging") return null;

  const { frontAudio, backAudio } = qacAudioData[currentCard];
  const isBackPhase = showBackText(gameState);
  const { text } = isBackPhase
    ? backAudio(frontAudioIndex)[backAudioIndex]
    : frontAudio[frontAudioIndex];

  return (
    <DemoGameDialog text={text} className="relative z-20" blue={isBackPhase} />
  );
}
