import { useEffect, useRef, useState } from "react";
import { DndContext } from "@dnd-kit/core";

import Mask from "../../../components/ui/Mask";
import { QACardType } from "../../../types";
import {
  CenterCardDuration,
  FlipCardDuration,
  RotateCardDuration,
  qacAudioData,
} from "../../qaCards/constants";
import { QACGameState } from "../../qaCards/types";

import DemoGameWrapper from "../components/DemoGameWrapper";
import DemoGameMenu from "../components/DemoGameMenu";
import DemoGameBoardWrapper from "../components/DemoGameBoardWrapper";
import DemoGameVideo from "../components/DemoGameVideo";
import DemoGameQRCode from "../components/DemoGameQRCode";

import BlueFrame from "./components/BlueFrame";
import ButtonHandler from "./components/ButtonHandler";
import CardHandler from "./components/CardHandler";
import CardPanel from "./components/CardPanel";
import CardMenu from "./components/CardMenu";
import QACDialog from "./components/QACDialog";
import Playmate from "./components/Playmate";
import { isRect, isWaiting } from "./utils/judgements";

export default function DemoQACards() {
  const f34Q1Ref = useRef<HTMLAudioElement>(null);
  const f34Q2Ref = useRef<HTMLAudioElement>(null);
  const f34Q1CARef = useRef<HTMLAudioElement>(null);
  const f34Q2CARef = useRef<HTMLAudioElement>(null);
  const f34aRef = useRef<HTMLAudioElement>(null);
  const f34bRef = useRef<HTMLAudioElement>(null);
  const f34cRef = useRef<HTMLAudioElement>(null);
  const f34dRef = useRef<HTMLAudioElement>(null);
  const f34eRef = useRef<HTMLAudioElement>(null);

  const f39Q1Ref = useRef<HTMLAudioElement>(null);
  const f39Q2Ref = useRef<HTMLAudioElement>(null);
  const f39Q1CARef = useRef<HTMLAudioElement>(null);
  const f39Q2CARef = useRef<HTMLAudioElement>(null);
  const f39bRef = useRef<HTMLAudioElement>(null);
  const f39cRef = useRef<HTMLAudioElement>(null);
  const f39dRef = useRef<HTMLAudioElement>(null);

  const f32Q1Ref = useRef<HTMLAudioElement>(null);
  const f32Q2Ref = useRef<HTMLAudioElement>(null);
  const f32Q1CARef = useRef<HTMLAudioElement>(null);
  const f32Q2CARef = useRef<HTMLAudioElement>(null);
  const f32aRef = useRef<HTMLAudioElement>(null);
  const f32bRef = useRef<HTMLAudioElement>(null);
  const f32cRef = useRef<HTMLAudioElement>(null);
  const f32dRef = useRef<HTMLAudioElement>(null);
  const f32eRef = useRef<HTMLAudioElement>(null);

  const refMapping = {
    qa34: {
      front: [f34Q1Ref, f34Q2Ref],
      back: (index: number) => [
        index === 0 ? f34Q1CARef : f34aRef,
        index === 1 ? f34Q2CARef : f34bRef,
        f34cRef,
        f34dRef,
        f34eRef,
      ],
    },
    qa39: {
      front: [f39Q1Ref, f39Q2Ref],
      back: (index: number) => [
        index === 0 ? f39Q1CARef : f39Q2CARef,
        f39bRef,
        f39cRef,
        f39dRef,
      ],
    },
    qa32: {
      front: [f32Q1Ref, f32Q2Ref],
      back: (index: number) => [
        index === 0 ? f32Q1CARef : f32aRef,
        index === 1 ? f32Q2CARef : f32bRef,
        f32cRef,
        f32dRef,
        f32eRef,
      ],
    },
  };

  const [gameState, setGameState] = useState<QACGameState>("beforeLanding");
  const [currentCard, setCurrentCard] = useState<QACardType | null>(null);
  const [isDragging, setIsDragging] = useState<QACardType | null>(null);
  const [frontAudioIndex, setFrontAudioIndex] = useState(0);
  const [backAudioIndex, setBackAudioIndex] = useState(0);
  const [shouldOpenIntroVideo, setShouldOpenIntroVideo] =
    useState<boolean>(false);
  const [shouldOpenQRCodeModel, setShouldOpenQRCodeModel] =
    useState<boolean>(false);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    const activeId = active.id as QACardType;

    setIsDragging(null);

    if (over && over.id === "droppable") {
      const frontIndex = Math.random() >= 0.5 ? 0 : 1;

      setCurrentCard(activeId);
      setFrontAudioIndex(frontIndex);
      setBackAudioIndex(0);
      setGameState("frontPlaying");

      const audioRef = refMapping[activeId].front[frontIndex];
      audioRef.current?.play();
    }
  };

  const handleflip = () => {
    setGameState("cardFlippingAndCentering");

    setTimeout(() => {
      setGameState("waitingForRotating");
    }, FlipCardDuration);
  };

  const handleRotate = () => {
    if (!currentCard) return;

    setGameState("cardRotating");

    setTimeout(() => {
      const limit = isRect(currentCard) ? 4 : 5;
      const nextIndex = backAudioIndex + 1 === limit ? 0 : backAudioIndex + 1;

      setBackAudioIndex(nextIndex);
      setGameState("waitingForRotating");
    }, RotateCardDuration);
  };

  const handleOk = () => {
    if (!currentCard) return;

    setGameState("backPlaying");

    const audioRef =
      refMapping[currentCard].back(frontAudioIndex)[backAudioIndex];
    audioRef.current?.play();
  };

  const handleAudioFrontEnded = () => {
    setGameState("waitingForFlipping");
  };

  const handleAudioBackCorrectEnded = () =>
    setGameState("waitingForNewDragging");

  const handleAudioBackEnded = () => {
    setGameState("cardCentering");

    setTimeout(() => {
      setGameState("waitingForRotating");
    }, CenterCardDuration);
  };

  useEffect(() => {
    setGameState("waitingForDragging");
  }, []);

  return (
    <>
      {isWaiting(gameState) ? null : <Mask />}

      <DemoGameWrapper>
        <DemoGameMenu
          gameType="QACards"
          setShouldOpenIntroVideo={setShouldOpenIntroVideo}
          setShouldOpenQRCodeModel={setShouldOpenQRCodeModel}
        >
          <CardMenu currentCard={currentCard || "qa34"} />
        </DemoGameMenu>

        <DemoGameBoardWrapper>
          <DndContext
            onDragEnd={handleDragEnd}
            onDragStart={(event) => {
              const id = event.active.id as QACardType;
              setIsDragging(id);
            }}
          >
            {/* 504 = dialog:40 + paddingTop:188 + playmate:88 + paddingTop:94 + smallCard:94 */}
            <div className="relative h-[501px] w-[600px]">
              {gameState !== "waitingForDragging" ? (
                <div className="absolute left-0 top-0 flex w-[600px] justify-center">
                  <QACDialog
                    gameState={gameState}
                    currentCard={currentCard}
                    frontAudioIndex={frontAudioIndex}
                    backAudioIndex={backAudioIndex}
                  />
                </div>
              ) : null}

              {/* 228 = dialog:40 + paddingTop:188 */}
              <div className="relative z-20 mt-[228px] flex w-full flex-col items-center justify-center">
                <Playmate gameState={gameState} />

                <CardHandler
                  gameState={gameState}
                  currentCard={currentCard}
                  frontAudioIndex={frontAudioIndex}
                  backAudioIndex={backAudioIndex}
                />

                <ButtonHandler
                  gameState={gameState}
                  onFlip={handleflip}
                  onRotate={handleRotate}
                  onOk={handleOk}
                />

                <BlueFrame isDragging={isDragging} />
              </div>

              <CardPanel gameState={gameState} currentCard={currentCard} />
            </div>
          </DndContext>
        </DemoGameBoardWrapper>
      </DemoGameWrapper>

      {shouldOpenIntroVideo ? (
        <DemoGameVideo
          gameType="QACards"
          setShouldOpenIntroVideo={setShouldOpenIntroVideo}
        />
      ) : null}

      {shouldOpenQRCodeModel ? (
        <DemoGameQRCode setShouldOpenQRCodeModel={setShouldOpenQRCodeModel} />
      ) : null}

      <audio
        src={qacAudioData.qa34.frontAudio[0].audio}
        ref={f34Q1Ref}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={qacAudioData.qa34.frontAudio[1].audio}
        ref={f34Q2Ref}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={qacAudioData.qa34.backAudio(0)[0].audio}
        ref={f34Q1CARef}
        onEnded={handleAudioBackCorrectEnded}
      />
      <audio
        src={qacAudioData.qa34.backAudio(1)[1].audio}
        ref={f34Q2CARef}
        onEnded={handleAudioBackCorrectEnded}
      />
      <audio
        src={qacAudioData.qa34.backAudio(-1)[0].audio}
        ref={f34aRef}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={qacAudioData.qa34.backAudio(-1)[1].audio}
        ref={f34bRef}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={qacAudioData.qa34.backAudio(-1)[2].audio}
        ref={f34cRef}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={qacAudioData.qa34.backAudio(-1)[3].audio}
        ref={f34dRef}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={qacAudioData.qa34.backAudio(-1)[4].audio}
        ref={f34eRef}
        onEnded={handleAudioBackEnded}
      />

      <audio
        src={qacAudioData.qa39.frontAudio[0].audio}
        ref={f39Q1Ref}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={qacAudioData.qa39.frontAudio[1].audio}
        ref={f39Q2Ref}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={qacAudioData.qa39.backAudio(0)[0].audio}
        ref={f39Q1CARef}
        onEnded={handleAudioBackCorrectEnded}
      />
      <audio
        src={qacAudioData.qa39.backAudio(1)[0].audio}
        ref={f39Q2CARef}
        onEnded={handleAudioBackCorrectEnded}
      />
      <audio
        src={qacAudioData.qa39.backAudio(-1)[1].audio}
        ref={f39bRef}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={qacAudioData.qa39.backAudio(-1)[2].audio}
        ref={f39cRef}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={qacAudioData.qa39.backAudio(-1)[3].audio}
        ref={f39dRef}
        onEnded={handleAudioBackEnded}
      />

      <audio
        src={qacAudioData.qa32.frontAudio[0].audio}
        ref={f32Q1Ref}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={qacAudioData.qa32.frontAudio[1].audio}
        ref={f32Q2Ref}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={qacAudioData.qa32.backAudio(0)[0].audio}
        ref={f32Q1CARef}
        onEnded={handleAudioBackCorrectEnded}
      />
      <audio
        src={qacAudioData.qa32.backAudio(1)[1].audio}
        ref={f32Q2CARef}
        onEnded={handleAudioBackCorrectEnded}
      />
      <audio
        src={qacAudioData.qa32.backAudio(-1)[0].audio}
        ref={f32aRef}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={qacAudioData.qa32.backAudio(-1)[1].audio}
        ref={f32bRef}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={qacAudioData.qa32.backAudio(-1)[2].audio}
        ref={f32cRef}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={qacAudioData.qa32.backAudio(-1)[3].audio}
        ref={f32dRef}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={qacAudioData.qa32.backAudio(-1)[4].audio}
        ref={f32eRef}
        onEnded={handleAudioBackEnded}
      />
    </>
  );
}
