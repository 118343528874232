import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import dweLogo from "../../assets/images/webLogin/dweLogo.svg";
import playText from "../../assets/images/webLogin/play.svg";
import background from "../../assets/images/webLogin/background.png";

import Loader from "../../components/ui/Loader";
import Text from "../../components/ui/Text";
import PillButton from "../../components/ui/PillButton";
import useStore from "../../hooks/store/useStore";
import changeLanguageByMarket from "../../locales/utils/changeLanguageByMarket";
import usePhoneNumberLogin from "../../services/webLogin/usePhoneNumberLogin";
import { LoginResponse } from "../../services/webLogin/types";
import importFont from "../../styles/utils/importFont";
import cn from "../../utils/cn";
import { MarketType } from "../../types";

import isValidPhoneNumber from "./utils/isValidPhoneNumber";
import PhoneNumberInput from "./components/PhoneNumberInput";
import SmsDrawer from "./components/SmsDrawer";
import openApp from "./utils/openApp";
import { CountDownTime } from "./constants";

export default function WebLogin() {
  const { t } = useTranslation();

  const [market, setMarket] = useState<MarketType>();
  const [fontLoaded, setFontLoaded] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSmsDrawer, setShowSmsDrawer] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);

  const { language, setLanguage } = useStore((state) => state.global);

  const mutationForLogin = usePhoneNumberLogin();

  const handleSubmit = () => {
    if (!isValidPhoneNumber(phoneNumber))
      return setErrorMessage(t("invalidPhoneFormat"));

    mutationForLogin.mutate(phoneNumber, {
      onSuccess: (data: LoginResponse) => {
        const { unLoginType } = data;

        if (!market) return setErrorMessage("Invalid market: " + market);

        if (unLoginType === "Member") {
          return openApp(market);
        } else {
          setTimeLeft(CountDownTime);
          setShowSmsDrawer(true);
        }
      },
      onError: (error: any) => {
        setErrorMessage("Login failed with code " + error.status);
      },
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const marketParam = searchParams.get("market");
    const isValidMarketParam =
      marketParam && ["HK", "KR", "TW"].includes(marketParam as MarketType);
    const marketValue = isValidMarketParam ? (marketParam as MarketType) : "TW";

    setMarket(marketValue);
    changeLanguageByMarket(marketValue, setLanguage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!language) return;

    importFont(language, setFontLoaded);
  }, [language]);

  if (market !== "TW")
    return <div>This page is not available in your region</div>;

  if (!language || !fontLoaded) return <Loader />;

  const height =
    window.innerHeight > 760 ? "relative" : "absolute bottom-0 left-0";

  return (
    <div className="relative flex min-h-screen w-full justify-center bg-pink1">
      <div className="relative flex h-screen w-screen min-w-[320px] max-w-[480px] flex-col overflow-hidden">
        <img
          src={dweLogo}
          alt="dweLogo"
          className="relative z-[2] mx-4 my-2 w-32"
        />

        <div className="relative z-[2] mx-10 my-6">
          <img src={playText} alt="play" className="w-[136px]" />
          <Text lg className="mx-1 my-1.5 font-medium">
            {t("loginSlogan")}
          </Text>
        </div>

        <div className={cn(height, "mt-[165px] h-[430px] w-full")}>
          <div className="absolute left-0 top-[-165px] z-[1] h-[360px] w-full overflow-hidden">
            <img
              src={background}
              alt="background"
              className="absolute left-1/2 h-[360px] w-auto -translate-x-1/2 transform object-cover"
            />
          </div>

          <div className="h-[430px] w-full bg-gradient-to-b from-pink2 to-pink1 pt-[195px]">
            <div className="flex flex-col items-center justify-center py-12">
              <PhoneNumberInput
                placeholder={t("mobileNumber")}
                setInputValue={setPhoneNumber}
                errorMessage={errorMessage}
              />

              <PillButton
                disabled={phoneNumber.length === 0}
                text={t("startTrial")}
                className="h-12"
                bgColor="bg-disneyGold"
                onClick={handleSubmit}
                isLoading={mutationForLogin.isLoading}
              />
            </div>
          </div>
        </div>
      </div>

      <SmsDrawer
        phoneNumber={phoneNumber}
        showSmsDrawer={showSmsDrawer}
        setShowSmsDrawer={setShowSmsDrawer}
        smsCode={smsCode}
        setSmsCode={setSmsCode}
        timeLeft={timeLeft}
        setTimeLeft={setTimeLeft}
      />
    </div>
  );
}
