import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import Text from "../../components/ui/Text";
import Touchable from "../../components/ui/Touchable";
import Mask from "../../components/ui/Mask";
import useStore from "../../hooks/store/useStore";
import bookDemoFnOthers from "../../services/bookDemo/bookDemoFnOthers";
import Button from "./components/Button";
import getBookDemoImageUrl from "./utils/getBookDemoImageUrl";

export default function BookDemoOthers() {
  const { t } = useTranslation();

  const { setShowBookDemoDrawer, setShowBookDemoThanks } = useStore(
    (state) => state.bookDemo,
  );
  const { userInformation } = useStore((state) => state.user);
  const { userName, phoneNumber, market } = userInformation || {};

  const mutation = useMutation({
    mutationFn: bookDemoFnOthers,
    onSuccess: () => {
      setShowBookDemoDrawer(false);
      setShowBookDemoThanks(true);
    },
  });
  const { isLoading } = mutation;
  const imageUrl = getBookDemoImageUrl(market);

  return (
    <div className="flex w-full flex-col items-center gap-10 px-10 pb-6">
      <div className="flex max-w-[310px] flex-col gap-3">
        <Text className="text-xl">{t("bookDemoTitle")}</Text>

        <Text normal>{userName + t("bookDemoDesc1")}</Text>
        <Text normal>{t("bookDemoDesc2")}</Text>

        <div className="flex w-full justify-center">
          <img src={imageUrl} alt="cover" className="w-[310px] rounded" />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <Button
          text={t("bookDemoAction")}
          isLoading={isLoading}
          onClick={() => {
            if (market && userName && phoneNumber)
              mutation.mutate({ market, name: userName, phone: phoneNumber });
          }}
        />

        <div className="flex h-[54px] w-full items-center justify-center">
          <Touchable onClick={() => setShowBookDemoDrawer(false)}>
            <Text normal>{t("maybeLater")}</Text>
          </Touchable>
        </div>
      </div>

      {isLoading ? <Mask /> : null}
    </div>
  );
}
