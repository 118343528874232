import { TACardType } from "../../../types";
import { tacAudioData } from "../constants";
import { TACGameState } from "../types";

export default function getDuration(
  gameState: TACGameState,
  currentCard: TACardType | null,
  backAudioIndex: number,
): number {
  if (
    currentCard === null ||
    (gameState !== "frontPlaying" && gameState !== "backPlaying")
  )
    return 0;

  const { frontAudio, backAudio } = tacAudioData[currentCard];
  const { duration } =
    gameState === "frontPlaying" ? frontAudio : backAudio[backAudioIndex];

  return duration;
}
