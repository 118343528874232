import { useQuery } from "react-query";

export default function useQueryLrc(
  songTitle: string | undefined,
  url: string | undefined = "",
  enabled: boolean
) {
  return useQuery({
    queryKey: [songTitle],
    queryFn: () =>
      fetch(url).then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      }),
    enabled,
  });
}
