import { useTranslation } from "react-i18next";

import card1 from "../../assets/images/playing/card1.png";
import card2 from "../../assets/images/playing/card2.png";
import magicPen from "../../assets/images/playing/magicPen.png";
import mickeyPen from "../../assets/images/playing/mickeyPen.png";
import playmate from "../../assets/images/playing/playmate.png";
import qaCards from "../../assets/images/playing/qaCards.png";
import bubble from "../../assets/images/shared/bubble.png";
import talkAlongCards from "../../assets/images/playing/talkAlongCards.png";
import Text from "../../components/ui/Text";
import BookButton from "../bookDemo/BookButton";
import MenuCard from "./components/MenuCard";

export default function Playing() {
  const { t } = useTranslation();

  return (
    <div className="relative w-full p-5">
      <Text className="mb-1 text-center text-[22px]">{t("playing")}</Text>
      <Text normal className="text-center">
        {t("playingDescription")}
      </Text>

      <div className="flex flex-col gap-3 px-8 py-6">
        <MenuCard
          text={t("magicPen")}
          url={magicPen}
          border="border-lightGreen2"
          dataIndex={0}
        >
          <img
            src={mickeyPen}
            alt="mickeyPen"
            className="absolute bottom-1 right-1 w-12"
          />
        </MenuCard>

        <MenuCard
          text={t("talkAlongCards")}
          url={talkAlongCards}
          border="border-lightBlue2"
          dataIndex={1}
        >
          <img
            src={card1}
            alt="card1"
            className="absolute bottom-4 right-[14px] w-[82px]"
          />
          <img
            src={playmate}
            alt="playmate"
            className="absolute bottom-2 right-3 w-[90px]"
          />
        </MenuCard>

        <MenuCard
          text={t("qaCards")}
          url={qaCards}
          border="border-yellow2"
          dataIndex={2}
        >
          <img
            src={card2}
            alt="card2"
            className="absolute bottom-5 right-[18px] w-20"
          />
          <img
            src={playmate}
            alt="playmate"
            className="absolute bottom-2 right-3 w-[90px]"
          />
        </MenuCard>
      </div>

      <img
        src={bubble}
        alt="bubble"
        className="floating absolute right-2 top-16 size-8"
      />
      <img
        src={bubble}
        alt="bubble"
        className="floating absolute left-4 top-60 size-6"
        style={{ animationDelay: "0.7s" }}
      />
      <img
        src={bubble}
        alt="bubble"
        className="floating absolute -left-4 bottom-12 size-10"
        style={{ animationDelay: "1.3s" }}
      />

      <BookButton sourcePage="Playing" />
    </div>
  );
}
