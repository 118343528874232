import Text from "../../../components/ui/Text";

export default function Tips({ text }: { text: string }) {
  return (
    <div className="relative mx-6 my-5 flex h-[72px] flex-col-reverse">
      <div className="flex h-[72px] w-[284px] items-center gap-3 rounded-lg bg-white px-3 py-4 shadow-sm">
        <Text lg className="text-blue">
          Tips
        </Text>
        <Text normal className="text-blue">
          {text}
        </Text>
      </div>
      <div className="triangle-down absolute -bottom-1.5 left-[167px] z-10"></div>
    </div>
  );
}
