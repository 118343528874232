import { useEffect, useRef, Dispatch, SetStateAction, RefObject } from "react";

import playSkipBack from "../../../assets/images/listen/play-skip-back.svg";
import playSkipForward from "../../../assets/images/listen/play-skip-forward.svg";
import play from "../../../assets/images/listen/play.svg";
import pause from "../../../assets/images/listen/pause.svg";

import Touchable from "../../../components/ui/Touchable";
import useCtaStore from "../../../hooks/store/useCtaStore";
import useStore from "../../../hooks/store/useStore";
import ctaHandler1 from "../../../utils/ctaHandlers/ctaHandler1";
import withDelay from "../utils/withDelay";
import { SongDataType } from "../types";
import RepeatButton from "./RepeatButton";

export default function Controls({
  audioRef,
  progressBarRef,
  setTimeProgress,
  songs,
  songIndex,
  setSongIndex,
  setCurrentSong,
  isPlaying,
  setIsPlaying,
  handleNext,
  repeat,
  setRepeat,
  unknown,
  setUnknown,
}: {
  audioRef: RefObject<HTMLAudioElement>;
  progressBarRef: RefObject<HTMLInputElement>;
  setTimeProgress: Dispatch<SetStateAction<number>>;
  songs: SongDataType[];
  songIndex: number;
  setSongIndex: Dispatch<SetStateAction<number>>;
  setCurrentSong: Dispatch<SetStateAction<SongDataType | null>>;
  isPlaying: boolean;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
  handleNext: () => void;
  repeat: boolean;
  setRepeat: Dispatch<SetStateAction<boolean>>;
  unknown: boolean;
  setUnknown: Dispatch<SetStateAction<boolean>>;
}) {
  // For throttling the progress function
  const lastProgressTimeRef = useRef<number>(0);
  const throttleInterval = 300;

  const { userInformation } = useStore((state) => state.user);
  const { setShowingCtaETId } = useCtaStore((state) => state.cta);
  const { unlockedSongsCount, setUnlockedSongsCount } = useCtaStore(
    (state) => state.ctaCount,
  );
  const { setCtaIntervalBlock } = useCtaStore((state) => state.ctaInterval);

  const togglePlayPause = withDelay(
    () => {
      if (!isPlaying) {
        const isCtaTriggered = ctaHandler1(
          userInformation,
          songIndex,
          unlockedSongsCount,
          setUnlockedSongsCount,
          setShowingCtaETId,
        );

        if (isCtaTriggered) return;
      }

      setCtaIntervalBlock(!isPlaying);
      setIsPlaying((prev) => !prev);
    },
    unknown,
    setUnknown,
  );

  const handlePrevious = withDelay(
    () => {
      if (songIndex === 0) {
        let lastSongIndex = songs.length - 1;

        setSongIndex(lastSongIndex);
        setCurrentSong(songs[lastSongIndex]);
      } else {
        setSongIndex((prev) => prev - 1);
        setCurrentSong(songs[songIndex - 1]);
      }
    },
    unknown,
    setUnknown,
  );

  const progress = () => {
    const currentAudioRef = audioRef.current;
    const duration = currentAudioRef?.duration || 0;
    const currentTime = currentAudioRef?.currentTime || 0;

    setTimeProgress(currentTime);

    if (progressBarRef?.current) {
      progressBarRef.current.value = String(currentTime);

      const progressBarValue = parseFloat(progressBarRef.current.value || "0");
      const progressPercentage = (progressBarValue / duration) * 100;

      progressBarRef.current.style.setProperty(
        "--range-progress",
        `${progressPercentage}%`,
      );
    }
  };

  useEffect(() => {
    const currentAudioRef = audioRef.current;
    let animationFrameId: number | undefined;

    const animate = (currentTime: number) => {
      if (currentTime - lastProgressTimeRef.current >= throttleInterval) {
        progress();
        lastProgressTimeRef.current = currentTime;
      }

      if (isPlaying) animationFrameId = requestAnimationFrame(animate);
    };

    if (currentAudioRef) {
      if (isPlaying) {
        currentAudioRef
          .play()
          .then(() => {
            animationFrameId = requestAnimationFrame(animate);
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
          });
      } else {
        currentAudioRef.pause();

        if (animationFrameId) cancelAnimationFrame(animationFrameId);
      }
    }

    return () => {
      if (currentAudioRef) currentAudioRef.pause();

      if (animationFrameId) cancelAnimationFrame(animationFrameId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, audioRef]);

  return (
    <div className="flex w-full items-center justify-center gap-4">
      <Touchable onClick={handlePrevious}>
        <img className="size-11" src={playSkipBack} alt="playSkipBack" />
      </Touchable>

      <Touchable onClick={togglePlayPause}>
        {isPlaying ? (
          <img className="size-16" src={pause} alt="pause" />
        ) : (
          <img className="size-16" src={play} alt="play" />
        )}
      </Touchable>

      <Touchable onClick={handleNext}>
        <img className="size-11" src={playSkipForward} alt="playSkipForward" />
      </Touchable>

      <RepeatButton
        repeat={repeat}
        setRepeat={setRepeat}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
      />
    </div>
  );
}
