import f32 from "../../../../assets/images/qaCards/f32.png";
import f34 from "../../../../assets/images/qaCards/f34.png";
import f39 from "../../../../assets/images/qaCards/f39.png";
import groove1 from "../../../../assets/images/qaCards/groove1.png";
import groove2 from "../../../../assets/images/qaCards/groove2.png";
import Draggable from "../../../../components/game/Draggable";
import { QACardType } from "../../../../types";
import { QACGameState } from "../../../qaCards/types";
import { isWaiting } from "../utils/judgements";

export default function CardPanel({
  gameState,
  currentCard,
}: {
  gameState: QACGameState;
  currentCard: QACardType | null;
}) {
  const isWaitingPhase = isWaiting(gameState);

  return (
    <div className="relative mt-[94px] flex h-[94px] w-full items-center justify-center gap-6">
      <img
        src={groove1}
        alt="groove1"
        className="absolute left-[139px] top-[2px] h-[92px] w-[96px] rotate-[-21deg]"
      />
      <Draggable
        id="qa34"
        isDraggable={isWaitingPhase && currentCard !== "qa34"}
      >
        <img src={f34} alt="f34" className="relative z-10 w-[96px]" />
      </Draggable>

      <img
        src={groove2}
        alt="groove2"
        className="absolute left-[253px] top-[1px] size-[94px] rotate-[-1deg]"
      />
      <Draggable
        id="qa39"
        isDraggable={isWaitingPhase && currentCard !== "qa39"}
      >
        <img src={f39} alt="f39" className="relative z-10 size-[90px]" />
      </Draggable>

      <img
        src={groove1}
        alt="groove1"
        className="absolute left-[373px] top-[3px] w-[96px] rotate-[-20deg]"
      />
      <Draggable
        id="qa32"
        isDraggable={isWaitingPhase && currentCard !== "qa32"}
      >
        <img src={f32} alt="f32" className="relative z-10 h-[92px] w-[96px]" />
      </Draggable>
    </div>
  );
}
