import f34 from "../../../../assets/images/qaCards/f34.png";
import f39 from "../../../../assets/images/qaCards/f39.png";
import f32 from "../../../../assets/images/qaCards/f32.png";
import playmate from "../../../../assets/images/gameShared/playmate.png";
import penPad from "../../../../assets/images/demoGame/penPad.png";
import Text from "../../../../components/ui/Text";
import { QACardType } from "../../../../types";

const mapping: {
  [key in QACardType]: string;
} = {
  qa34: f34,
  qa39: f39,
  qa32: f32,
};

export default function CardMenu({ currentCard }: { currentCard: QACardType }) {
  return (
    <div className="relative flex h-[240px] flex-col items-center">
      <div className="relative z-50 mt-10 flex h-[140px] w-[140px] justify-center">
        <img
          src={mapping[currentCard]}
          alt="front"
          className="absolute right-0 top-1.5 z-10 w-[101.9px]"
        />
        <img
          src={playmate}
          alt="playmate"
          className="absolute bottom-[15px] left-0 z-20 w-[124.4px]"
        />
      </div>

      <img
        src={penPad}
        alt="penPad"
        className="absolute left-[19px] top-[144px] w-[180px]"
      />

      <div className="flex h-[60px] justify-center pt-[22px]">
        <Text typography="h3" className="text-[#1D1D1D]">
          Q&A 카드
        </Text>
      </div>
    </div>
  );
}
