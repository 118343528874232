import { ReactNode, useEffect, useState } from "react";

import useStore from "../../hooks/store/useStore";
import useEventTracking from "../../services/EventTracking/useEventTracking";
import { EventTrackingSourcePageType } from "../../services/EventTracking/types";
import useOneTimeToken from "../../services/useOneTimeToken";

export default function BookDemoLink({
  children,
  sourcePage,
}: {
  children: ReactNode;
  sourcePage: EventTrackingSourcePageType;
}) {
  const [url, setUrl] = useState("");

  const { userInformation } = useStore((state) => state.user);
  const { bookDemoLinkAddress } = userInformation || {};

  const mutation = useEventTracking();
  const mutationForOneTimeToken = useOneTimeToken();

  const handleEventTracking = () => {
    mutation.mutate({
      pageId: "Booked Demo",
      eventId: sourcePage,
    });
  };

  const updateUrl = async () => {
    const { tokenId } = await mutationForOneTimeToken.mutateAsync();

    setUrl(`${bookDemoLinkAddress}?tokenId=${tokenId}`);
  };

  useEffect(() => {
    updateUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        handleEventTracking();
        updateUrl();
      }}
    >
      {children}
    </a>
  );
}
