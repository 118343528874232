import { webLoginStoragekey } from "../constants";

export default function setWebLoginTokenToStorage({
  accessKey,
  jwtToken,
}: {
  accessKey: string;
  jwtToken: string;
}) {
  const now = new Date();
  const ttl = 24 * 3600 * 1000; // 1 day

  const data = {
    expireTime: now.getTime() + ttl,
    accessKey,
    jwtToken,
  };

  localStorage.setItem(webLoginStoragekey, JSON.stringify(data));
}
