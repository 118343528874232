import { QACardType } from "../../../../types";
import { qacAudioData } from "../../../qaCards/constants";
import { QACGameState } from "../../../qaCards/types";

export default function getDuration(
  gameState: QACGameState,
  currentCard: QACardType | null,
  frontAudioIndex: number,
  backAudioIndex: number,
): number {
  if (
    currentCard === null ||
    (gameState !== "frontPlaying" && gameState !== "backPlaying")
  )
    return 0;

  const { frontAudio, backAudio } = qacAudioData[currentCard];
  const { duration } =
    gameState === "frontPlaying"
      ? frontAudio[frontAudioIndex]
      : backAudio(frontAudioIndex)[backAudioIndex];

  return duration;
}
