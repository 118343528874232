import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DndContext } from "@dnd-kit/core";

import playmate from "../../../assets/images/gameShared/playmate.png";
import reverse from "../../../assets/images/gameShared/reverse.svg";
import eatFront from "../../../assets/images/talkAlongCards/eatFront.png";
import playFront from "../../../assets/images/talkAlongCards/playFront.png";
import picnicFront from "../../../assets/images/talkAlongCards/picnicFront.png";
import carsFront from "../../../assets/images/talkAlongCards/carsFront.png";
import replay from "../../../assets/images/demoGame/replay.png";

import Draggable from "../../../components/game/Draggable";
import Droppable from "../../../components/game/Droppable";
import Mask from "../../../components/ui/Mask";
import useStore from "../../../hooks/store/useStore";
import cn from "../../../utils/cn";
import { SourceType, TACardType } from "../../../types";

import { isWaiting } from "../../talkAlongCards/utils/judgements";
import { FlipCardDuration, tacAudioData } from "../../talkAlongCards/constants";
import { TACGameState } from "../../talkAlongCards/types";

import DemoGameWrapper from "../components/DemoGameWrapper";
import DemoGameMenu from "../components/DemoGameMenu";
import DemoGameBoardWrapper from "../components/DemoGameBoardWrapper";
import DemoGameButton from "../components/DemoGameButton";
import DemoGameVideo from "../components/DemoGameVideo";
import DemoGameQRCode from "../components/DemoGameQRCode";

import CardHandler from "./components/CardHandler";
import CardMenu from "./components/CardMenu";
import TACDialog from "./components/TACDialog";

export default function DemoTalkAlongCards() {
  const { t } = useTranslation();

  const eatFRef = useRef<HTMLAudioElement>(null);
  const eatB0Ref = useRef<HTMLAudioElement>(null);
  const eatB1Ref = useRef<HTMLAudioElement>(null);
  const playFRef = useRef<HTMLAudioElement>(null);
  const playB0Ref = useRef<HTMLAudioElement>(null);
  const playB1Ref = useRef<HTMLAudioElement>(null);
  const picnicFRef = useRef<HTMLAudioElement>(null);
  const picnicB0Ref = useRef<HTMLAudioElement>(null);
  const picnicB1Ref = useRef<HTMLAudioElement>(null);
  const carsFRef = useRef<HTMLAudioElement>(null);
  const carsB0Ref = useRef<HTMLAudioElement>(null);
  const carsB1Ref = useRef<HTMLAudioElement>(null);

  const refMapping = {
    eat: {
      front: eatFRef,
      back: [eatB0Ref, eatB1Ref],
    },
    play: {
      front: playFRef,
      back: [playB0Ref, playB1Ref],
    },
    picnic: {
      front: picnicFRef,
      back: [picnicB0Ref, picnicB1Ref],
    },
    cars: {
      front: carsFRef,
      back: [carsB0Ref, carsB1Ref],
    },
  };

  const [gameState, setGameState] =
    useState<TACGameState>("waitingForDragging");
  const [currentCard, setCurrentCard] = useState<TACardType | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [backAudioIndex, setBackAudioIndex] = useState(0);
  const [shouldOpenIntroVideo, setShouldOpenIntroVideo] =
    useState<boolean>(false);
  const [shouldOpenQRCodeModel, setShouldOpenQRCodeModel] =
    useState<boolean>(false);

  const { source } = useStore((state) => state.global);

  const droppableStyle = isDragging ? "blue-frame" : "";
  const isWaitingPhase = isWaiting(gameState);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    const activeId = active.id as TACardType;

    setIsDragging(false);

    if (over && over.id === "droppable") {
      setCurrentCard(activeId);
      setBackAudioIndex(0);
      setGameState("frontPlaying");

      const audioRef = refMapping[activeId].front;
      audioRef.current?.play();
    }
  };

  const handleflip = () => {
    setGameState("cardFlippingAndReset");

    setTimeout(() => {
      setGameState("backPlaying");

      if (!currentCard) return;

      const audioRef = refMapping[currentCard].back[backAudioIndex];
      audioRef.current?.play();
    }, FlipCardDuration);
  };

  const handleReplay = () => {
    if (!currentCard) return;

    const nextIndex = backAudioIndex === 0 ? backAudioIndex + 1 : 1;

    if (backAudioIndex === 0) setBackAudioIndex(nextIndex);

    setGameState("cardReset");

    setTimeout(() => {
      setGameState("backPlaying");

      if (!currentCard) return;

      const audioRef = refMapping[currentCard].back[nextIndex];
      audioRef.current?.play();
    }, FlipCardDuration);
  };

  const handleAudioFrontEnded = () => {
    setGameState("waitingForFlipping");
  };

  const handleAudioBackEnded = () => setGameState("waitingForReplay");

  if (source !== SourceType.DemoApp) return <div>Page Not Found</div>;

  return (
    <>
      {isWaitingPhase ? null : <Mask />}

      <DemoGameWrapper>
        <DemoGameMenu
          gameType="talkAlongCards"
          setShouldOpenIntroVideo={setShouldOpenIntroVideo}
          setShouldOpenQRCodeModel={setShouldOpenQRCodeModel}
        >
          <CardMenu currentCard={currentCard || "eat"} />
        </DemoGameMenu>

        <DemoGameBoardWrapper>
          <DndContext
            onDragEnd={handleDragEnd}
            onDragStart={() => {
              setIsDragging(true);
            }}
          >
            {/* 501 = dialog:40 + paddingTop:188 + playmate:88 + paddingTop:120 + smallCard:65 */}
            <div className="relative h-[501px] w-[600px]">
              {gameState !== "waitingForDragging" ? (
                <div className="absolute left-0 top-0 flex w-[600px] justify-center">
                  <TACDialog
                    gameState={gameState}
                    currentCard={currentCard}
                    backAudioIndex={backAudioIndex}
                  />
                </div>
              ) : null}

              {/* 228 = dialog:40 + paddingTop:188 */}
              <div className="relative mt-[228px] flex w-full flex-col items-center justify-center">
                <img
                  src={playmate}
                  alt="playmate"
                  className="relative z-50 h-[88px] w-[308px]"
                />

                <CardHandler
                  gameState={gameState}
                  currentCard={currentCard}
                  backAudioIndex={backAudioIndex}
                />

                <Droppable
                  className={cn(
                    droppableStyle,
                    "absolute -top-[80px] right-0 z-20 h-[135px] w-[300px]",
                  )}
                />

                {gameState === "waitingForFlipping" ? (
                  <DemoGameButton
                    blue
                    image={reverse}
                    text={t("flipCard")}
                    onClick={handleflip}
                    className="absolute top-[112px] border-[#008EFA]"
                  />
                ) : null}

                {gameState === "waitingForReplay" ? (
                  <DemoGameButton
                    image={replay}
                    text={t("playAgain")}
                    onClick={handleReplay}
                    className="absolute top-[112px]"
                  />
                ) : null}

                <div className="mt-[120px] flex items-center justify-start gap-2">
                  <Draggable
                    id="eat"
                    isDraggable={isWaitingPhase && currentCard !== "eat"}
                  >
                    <img
                      src={eatFront}
                      alt="eatFront"
                      className="h-[65px] w-[144px]"
                    />
                  </Draggable>

                  <Draggable
                    id="play"
                    isDraggable={isWaitingPhase && currentCard !== "play"}
                  >
                    <img
                      src={playFront}
                      alt="playFront"
                      className="h-[65px] w-[144px]"
                    />
                  </Draggable>

                  <Draggable
                    id="picnic"
                    isDraggable={isWaitingPhase && currentCard !== "picnic"}
                  >
                    <img
                      src={picnicFront}
                      alt="picnicFront"
                      className="h-[65px] w-[144px]"
                    />
                  </Draggable>

                  <Draggable
                    id="cars"
                    isDraggable={isWaitingPhase && currentCard !== "cars"}
                  >
                    <img
                      src={carsFront}
                      alt="carsFront"
                      className="h-[65px] w-[144px]"
                    />
                  </Draggable>
                </div>
              </div>
            </div>
          </DndContext>
        </DemoGameBoardWrapper>
      </DemoGameWrapper>

      {shouldOpenIntroVideo ? (
        <DemoGameVideo
          gameType="talkAlongCards"
          setShouldOpenIntroVideo={setShouldOpenIntroVideo}
        />
      ) : null}

      {shouldOpenQRCodeModel ? (
        <DemoGameQRCode setShouldOpenQRCodeModel={setShouldOpenQRCodeModel} />
      ) : null}

      <audio
        src={tacAudioData.eat.frontAudio.audio}
        ref={eatFRef}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={tacAudioData.eat.backAudio[0].audio}
        ref={eatB0Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.eat.backAudio[1].audio}
        ref={eatB1Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.play.frontAudio.audio}
        ref={playFRef}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={tacAudioData.play.backAudio[0].audio}
        ref={playB0Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.play.backAudio[1].audio}
        ref={playB1Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.picnic.frontAudio.audio}
        ref={picnicFRef}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={tacAudioData.picnic.backAudio[0].audio}
        ref={picnicB0Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.picnic.backAudio[1].audio}
        ref={picnicB1Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.cars.frontAudio.audio}
        ref={carsFRef}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={tacAudioData.cars.backAudio[0].audio}
        ref={carsB0Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.cars.backAudio[1].audio}
        ref={carsB1Ref}
        onEnded={handleAudioBackEnded}
      />
    </>
  );
}
