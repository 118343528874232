import { MarketType } from "../../../types";

export default function getBookDemoImageUrl(
  market: MarketType | undefined,
): string {
  if (!market) return "";

  const folder = process.env.REACT_APP_BLOB_FOLDER;

  return `https://service.worldfamilyenglish.com/${folder}/data/digital-experience/BookDemoPhoto_${market}.jpg`;
}
