import { SyntheticEvent } from "react";

import noImg from "../../assets/images/shared/noImg.png";

export default function Image({
  src,
  alt,
  className = "",
  onLoad,
}: {
  src: string;
  alt: string;
  className?: string;
  onLoad?: () => void;
}) {
  return (
    <img
      className={className}
      src={src || noImg}
      alt={alt}
      onError={(event: SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = noImg;
      }}
      onLoad={onLoad}
    />
  );
}
