import { QACardType } from "../../../types";
import getDuration from "../utils/getDuration";
import { isRect } from "../utils/judgements";
import { centering, moveToLeft } from "../animationConfigs";
import { tacImageData } from "../constants";
import { QACGameState } from "../types";
import AnimatedCard from "./AnimatedCard";
import AnimatedDoubleSidedCard from "./AnimatedDoubleSidedCard";
import StaticCard from "./StaticCard";
import StaticCenteredCard from "./StaticCenteredCard";
import AnimatedCenteredCard from "./AnimatedCenteredCard";
import AnimatedCard2 from "./AnimatedCard2";

export default function CardHandler({
  gameState,
  currentCard,
  frontAudioIndex,
  backAudioIndex,
}: {
  gameState: QACGameState;
  currentCard: QACardType | null;
  frontAudioIndex: number;
  backAudioIndex: number;
}) {
  if (currentCard === null) return null;

  const { frontImage, backImage } = tacImageData[currentCard];
  const isRectCard = isRect(currentCard);
  const size = isRectCard ? "size-[168px]" : "w-[214px] h-[205px]";
  const width = isRectCard ? 168 : 214;

  if (gameState === "frontPlaying")
    return (
      <AnimatedCard
        image={frontImage}
        animationConfig={moveToLeft(
          getDuration(gameState, currentCard, frontAudioIndex, backAudioIndex),
          width,
        )}
        size={size}
      />
    );

  if (gameState === "backPlaying")
    return (
      <AnimatedCard
        image={backImage}
        isRect={isRectCard}
        backAudioIndex={backAudioIndex}
        animationConfig={moveToLeft(
          getDuration(gameState, currentCard, frontAudioIndex, backAudioIndex),
          width,
        )}
        size={size}
      />
    );

  if (gameState === "cardFlippingAndCentering")
    return (
      <AnimatedDoubleSidedCard
        frontImage={frontImage}
        backImage={backImage}
        isRect={isRectCard}
        size={size}
      />
    );

  if (gameState === "cardCentering")
    return (
      <AnimatedCard2
        image={backImage}
        animationConfig={centering(isRectCard, backAudioIndex)}
        size={size}
      />
    );

  if (gameState === "cardRotating")
    return (
      <AnimatedCenteredCard
        image={backImage}
        isRect={isRectCard}
        backAudioIndex={backAudioIndex}
        size={size}
      />
    );

  if (gameState === "waitingForFlipping")
    return <StaticCard image={frontImage} size={size} />;

  if (gameState === "waitingForNewDragging")
    return (
      <StaticCard
        image={backImage}
        isRect={isRectCard}
        backAudioIndex={backAudioIndex}
        size={size}
      />
    );

  if (gameState === "waitingForRotating")
    return (
      <StaticCenteredCard
        image={backImage}
        isRect={isRectCard}
        backAudioIndex={backAudioIndex}
        size={size}
      />
    );

  return null;
}
