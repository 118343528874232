import { animated, useSpring } from "@react-spring/web";

import cn from "../../../utils/cn";
import getDegree from "../utils/getDegree";
import { RotateCardDuration } from "../constants";

export default function AnimatedCenteredCard({
  image,
  isRect,
  backAudioIndex,
  size,
}: {
  image: string;
  isRect: boolean;
  backAudioIndex: number;
  size?: string;
}) {
  const left = isRect ? 95 : 72;
  const scale = isRect ? "1.5" : "1.36";
  const [fromDeg, toDeg] = getDegree(isRect, backAudioIndex);

  const animation = useSpring({
    from: { transform: `rotate(${fromDeg})` },
    to: { transform: `rotate(${toDeg})` },
    config: { duration: RotateCardDuration },
  });

  return (
    <div
      className={cn(
        "absolute bottom-8 left-4 z-20 flex items-center justify-center",
        size,
      )}
    >
      <div
        className={cn(size, "absolute top-2")}
        style={{
          left,
          transform: `scale(${scale})`,
        }}
      >
        <animated.div
          style={{ transformOrigin: "center center", ...animation }}
        >
          <img src={image} alt="main card" className="h-full w-full" />
        </animated.div>
      </div>
    </div>
  );
}
