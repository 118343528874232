import cn from "../../utils/cn";

type TypographyType =
  | "h1"
  | "h2"
  | "h3"
  | "bodyBold"
  | "body"
  | "footnoteBold"
  | "footnote";

const typographyStyles = {
  h1: "text-[28px] leading-[32px] font-semibold",
  h2: "text-[20px] leading-[auto] font-semibold",
  h3: "text-[18px] leading-[24px] font-semibold",
  bodyBold: "text-[14px] leading-[16px] font-bold",
  body: "text-[14px] leading-[16px] font-medium",
  footnoteBold: "text-[12px] leading-[14px] font-bold",
  footnote: "text-[12px] leading-[14px] font-medium",
};

function wrapEnglishText(text: string) {
  const pattern = /([a-zA-Z0-9'.]+)/g;
  const matches = text.match(pattern);

  if (!matches) return;

  const parts = [];
  let lastIndex = 0;

  matches.forEach((word, index) => {
    const startIndex = text.indexOf(word, lastIndex);

    if (startIndex > lastIndex) {
      const nonEnglishPart = text.substring(lastIndex, startIndex);

      parts.push(nonEnglishPart);
    }

    parts.push(
      <span key={index} className="font-lexend">
        {word}
      </span>,
    );

    lastIndex = startIndex + word.length;
  });

  if (lastIndex < text.length) {
    const lastNonEnglishPart = text.substring(lastIndex);

    parts.push(lastNonEnglishPart);
  }

  return parts;
}

export default function Text({
  children,
  className,
  style,
  lg,
  normal,
  typography,
}: {
  children: string;
  className?: string;
  style?: React.CSSProperties;
  lg?: boolean;
  normal?: boolean;
  typography?: TypographyType;
}) {
  const wrapperedText = wrapEnglishText(children);
  const defaultStyles = cn(
    "leading-5 text-black8",
    lg ? "text-[17px]" : "text-[15px]",
    normal ? "font-normal" : "font-semibold",
  );
  const appliedStyles = typography
    ? typographyStyles[typography]
    : defaultStyles;

  return (
    <p className={cn(appliedStyles, className)} style={style}>
      {wrapperedText || children}
    </p>
  );
}
