import subtitle from "../../assets/images/gameShared/subtitle.png";
import cn from "../../utils/cn";
import Text from "../ui/Text";

export default function Dialog({
  text,
  wrapperClassName,
  className,
  blue,
}: {
  text: string;
  wrapperClassName?: string;
  className?: string;
  blue?: boolean;
}) {
  const bgColor = blue ? "bg-skyBlue2" : "bg-white";

  return (
    <div
      className={cn(
        "mx-6 my-5 flex h-[72px] flex-col items-center justify-center",
        wrapperClassName,
      )}
    >
      <div
        className={cn(
          "flex w-full gap-1 rounded-lg border border-solid border-skyBlue px-2 py-3",
          bgColor,
          className,
        )}
      >
        <img src={subtitle} alt="subtitle" className="size-6" />

        <Text className="leading-6 text-blue">{text}</Text>
      </div>
    </div>
  );
}
