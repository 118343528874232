import { UserInformation } from "../../types";

const triggerCount = 5;

/**
 * ctaExperienceTypeId: 5
 * After playing Magic Pen 5 times
 * Awareness, Interest
 */
export default function ctaHandler5(
  userInformation: UserInformation | null,
  magicPenCount: number,
  setMagicPenCount: (count: number) => void,
  setShowingCtaETId: (data: string | null) => void,
) {
  const { trialUserSegmentation: seg } = userInformation || {};

  if (seg !== "Awareness" && seg !== "Interest") return;

  const newCount = magicPenCount + 1;

  setMagicPenCount(newCount);

  if (newCount % triggerCount === 0) setShowingCtaETId("5");
}
