import { format } from "date-fns";

export default function bookDemoFnHK(
  name: string | undefined,
  phone: string | undefined,
  location: string,
  date: Date | undefined,
  time: string,
) {
  return async function (tokenId: string) {
    if (!date) return;

    const dateParam = format(date, "yyyy/MM/dd").replace(/\//g, "%2F");
    const timeParam = encodeURIComponent(time);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL_BOOKDEMO_HK}/leads/appointment/CUSTOM/submit?source_code=INIA2403POTA&name=${name}&tel=${phone}&location=${location}&selectedDate=${dateParam}&time=${timeParam}`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${tokenId}`,
        },
      },
    );

    if (!response.ok) throw new Error("Network response was not ok");

    return response.json();
  };
}
