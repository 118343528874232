import { useTranslation } from "react-i18next";

import background from "../../../assets/images/bookDemo/background.png";
import FadeInAnimation from "../../../components/animation/FadeInAnimation";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import PillButton from "../../../components/ui/PillButton";
import useStore from "../../../hooks/store/useStore";
import useEventTracking from "../../../services/EventTracking/useEventTracking";
import { EventTrackingAppDownloadType } from "../../../services/EventTracking/types";
import openDownloadPage from "../../webLogin/utils/openDownloadPage";

export default function AppDownloadModel({
  eventId,
}: {
  eventId: EventTrackingAppDownloadType;
}) {
  const { t } = useTranslation();

  const { userInformation } = useStore((state) => state.user);
  const { setShowAppDownloadModel } = useStore((state) => state.webVersion);

  const mutation = useEventTracking();

  const { market } = userInformation || {};

  const handleEventTracking = () => {
    mutation.mutate({
      pageId: "App Download",
      eventId: eventId,
    });
  };

  return (
    <FadeInAnimation className="fixed bottom-0 left-0 flex h-screen w-screen items-center justify-center bg-grey8/70">
      <div className="relative w-[310px] overflow-hidden rounded-2xl bg-pink1 px-6 pb-4 pt-9">
        <img
          src={background}
          alt="background"
          className="absolute left-0 top-1 w-full"
        />

        <div className="relative z-10 flex flex-col gap-6">
          <Text className="text-xl">{t("appDownloadModelTitle")}</Text>

          <Text normal>{t("appDownloadModelDescription")}</Text>

          <div className="flex flex-col items-center gap-4">
            <PillButton
              text={t("appDownloadModelAction")}
              onClick={() => {
                handleEventTracking();

                setShowAppDownloadModel(false);

                if (market) openDownloadPage(market);
              }}
            />

            <Touchable
              className="flex h-12 w-fit items-center"
              onClick={() => {
                setShowAppDownloadModel(false);
              }}
            >
              <Text>{t("maybeLater")}</Text>
            </Touchable>
          </div>
        </div>
      </div>
    </FadeInAnimation>
  );
}
