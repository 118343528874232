import { useMutation } from "react-query";

export default function useVerifySmsCode() {
  return useMutation({
    mutationFn: async ({
      phoneNumber,
      smsCode,
    }: {
      phoneNumber: string;
      smsCode: string;
    }) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/verify-sms-code`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phoneNumber,
            smsCode,
          }),
        },
      );

      if (!response.ok) throw new Error("Network response was not ok");

      return response.json();
    },
  });
}
