import { animated, useSpring } from "@react-spring/web";

import cn from "../../../../utils/cn";

export default function AnimatedCard({
  image,
  animationConfig,
  className,
}: {
  image: string;
  animationConfig?: any;
  className?: string;
}) {
  const animation = useSpring(animationConfig);

  return (
    <animated.div
      className={cn(
        "absolute -top-[80px] right-0 z-20 h-[135px] w-[300px]",
        className,
      )}
      style={animation}
    >
      <img src={image} alt="main card" className="h-full w-full" />
    </animated.div>
  );
}
