import { CenterCardDuration, LandingCardPanelDuration } from "./constants";
import generateRandomTransform from "./utils/generateRandomTransform";
import getDegree from "./utils/getDegree";

const screenWidth = 390;
const padding = 32;

export const moveToLeft = (duration: number, width: number) => {
  const x = screenWidth - padding - width;

  return {
    to: async (next: any) => {
      await next({
        transform: "translateX(0px) translateY(0px)",
        immediate: true,
      });
      await next({ transform: `translateX(-${x}px) translateY(0px)` });
    },
    from: { transform: "translateX(0px) translateY(0px)" },
    config: { duration },
  };
};

export const centering = (isRect: boolean, backAudioIndex: number) => {
  const left = isRect ? 95 : 72;
  const scale = isRect ? "1.5" : "1.36";
  const deg = getDegree(isRect, backAudioIndex)[0];

  return {
    from: {
      left: 16,
      transform: `rotate(${deg}) scale(1)`,
    },
    to: {
      left,
      transform: `rotate(${deg}) scale(${scale})`,
    },
    config: { duration: CenterCardDuration },
  };
};

export const falling = () => {
  return {
    from: {
      transform: generateRandomTransform(),
    },
    to: { transform: "translateX(0px) translateY(0%) rotate(0deg) scale(1)" },
    config: { duration: LandingCardPanelDuration, friction: 300 },
  };
};
