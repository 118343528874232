import tryIt from "../../../assets/images/magicPen/tryIt.png";
import tryItMagicPen from "../../../assets/images/magicPen/tryItMagicPen.png";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";

export default function TryItCard({ onClick }: { onClick: () => void }) {
  return (
    <Touchable
      className="relative h-[96px] w-[200px] overflow-hidden rounded-lg bg-white p-1.5 shadow"
      onClick={onClick}
    >
      <img src={tryIt} alt="try it" className="h-[84px] w-[120px]" />

      <img
        src={tryItMagicPen}
        alt="mickey pen"
        className="back-and-forth-sway absolute right-[42px] top-[3px] w-12"
      />

      <Text lg className="absolute bottom-4 right-3">
        Try it
      </Text>
    </Touchable>
  );
}
