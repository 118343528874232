import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useDraggable } from "@dnd-kit/core";

import { magicPenMaxWidth } from "../../../layout/game/constants";
import { penLeft, penTop } from "../constants";
import { PositionType } from "../types";

function getScrollLeft(cardRef: RefObject<HTMLDivElement>) {
  const container = cardRef.current;

  return container ? container.scrollLeft : 0;
}

export default function DraggablePen({
  pen,
  penPosition,
  setPenPosition,
  cardRef,
}: {
  pen: string;
  penPosition: PositionType;
  setPenPosition: Dispatch<SetStateAction<PositionType>>;
  cardRef: RefObject<HTMLDivElement>;
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth);

  const { attributes, listeners } = useDraggable({
    id: "pen",
  });

  const handleDragMove = (event: MouseEvent | TouchEvent) => {
    const { clientX, clientY } = "touches" in event ? event.touches[0] : event;

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    let leftSpace = 0;

    if (deviceWidth > magicPenMaxWidth)
      leftSpace = (deviceWidth - magicPenMaxWidth) / 2;

    const x = clientX - 110 / 2 - penLeft + getScrollLeft(cardRef) - leftSpace;
    const y = clientY - 203 - penTop + scrollTop;

    setPenPosition({ x, y });
  };

  useEffect(() => {
    const handleResize = () => setDeviceWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleDragMove);
    } else {
      window.removeEventListener("mousemove", handleDragMove);
    }

    return () => {
      window.removeEventListener("mousemove", handleDragMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  return (
    <div
      {...attributes}
      {...listeners}
      className="absolute z-50"
      style={{
        top: penTop,
        left: penLeft,
        touchAction: "none",
        transform: `translate(${penPosition.x}px, ${penPosition.y}px)`,
      }}
      // @ts-ignore
      onTouchMove={handleDragMove}
      onMouseDown={() => setIsDragging(true)}
      onMouseUp={() => setIsDragging(false)}
    >
      <img src={pen} alt="pen" className="w-[110px]" />
    </div>
  );
}
