import { useMutation } from "react-query";

export default function useResendSmsCode() {
  return useMutation({
    mutationFn: async (phoneNumber: string) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/resend-sms-code`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phoneNumber,
          }),
        },
      );

      if (!response.ok) throw new Error("Network response was not ok");

      /**
       * This request does not return data
       */
      return;
    },
  });
}
