import mickey from "../../assets/images/menu/disney/mickey.png";
import minnie from "../../assets/images/menu/disney/minnie.png";
import donald from "../../assets/images/menu/disney/donald.png";
import pluto from "../../assets/images/menu/disney/pluto.png";
import goofy from "../../assets/images/menu/disney/goofy.png";
import eventIcon from "../../assets/images/menu/event.svg";
import listenIcon from "../../assets/images/menu/listen-active.svg";
import watchIcon from "../../assets/images/menu/watch-active.svg";
import playingIcon from "../../assets/images/menu/playing-active.svg";
import storyIcon from "../../assets/images/menu/storyIcon.svg";
import { MenuItemType } from "../../types";

const menuItemData: {
  [key: string]: {
    type: MenuItemType;
    image: string;
    icon: string;
    delay: string;
    isRight: boolean;
  };
} = {
  donald: {
    type: "event",
    image: donald,
    icon: eventIcon,
    delay: "0s",
    isRight: false,
  },
  minnie: {
    type: "listen",
    image: minnie,
    icon: listenIcon,
    delay: "0.3s",
    isRight: true,
  },
  mickey: {
    type: "watch",
    image: mickey,
    icon: watchIcon,
    delay: "0.6s",
    isRight: false,
  },
  pluto: {
    type: "playing",
    image: pluto,
    icon: playingIcon,
    delay: "0.9s",
    isRight: true,
  },
  goofy: {
    type: "story",
    image: goofy,
    icon: storyIcon,
    delay: "1.2s",
    isRight: false,
  },
};

export default menuItemData;
