import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "lucide-react";

import sms from "../../../assets/images/webLogin/sms.png";
import SlideInAnimation from "../../../components/animation/SlideInAnimation";
import Touchable from "../../../components/ui/Touchable";
import Text from "../../../components/ui/Text";
import PillButton from "../../../components/ui/PillButton";
import useStore from "../../../hooks/store/useStore";
import useVerifySmsCode from "../../../services/webLogin/useVerifySmsCode";
import { VerifySmsCodeResponse } from "../../../services/webLogin/types";

import formatPhoneNumber from "../utils/formatPhoneNumber";
import setWebLoginTokenToStorage from "../utils/setWebLoginTokenToStorage";
import CountDownAndResend from "./CountDownAndResend";
import SmsInput from "./SmsInput";

export default function SmsDrawer({
  phoneNumber,
  showSmsDrawer,
  setShowSmsDrawer,
  smsCode,
  setSmsCode,
  timeLeft,
  setTimeLeft,
}: {
  phoneNumber: string;
  showSmsDrawer: boolean;
  setShowSmsDrawer: Dispatch<SetStateAction<boolean>>;
  smsCode: string;
  setSmsCode: Dispatch<SetStateAction<string>>;
  timeLeft: number;
  setTimeLeft: Dispatch<SetStateAction<number>>;
}) {
  const { t } = useTranslation();

  const contentRef = useRef<HTMLDivElement>(null);

  const [backgroundHeight, setBackgroundHeight] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const { setTokens } = useStore((state) => state.tokens);

  const mutationForVerify = useVerifySmsCode();

  const handleSubmit = () => {
    mutationForVerify.mutate(
      { phoneNumber, smsCode },
      {
        onSuccess: (data: VerifySmsCodeResponse) => {
          const { accessKey, jwtToken } = data;

          setWebLoginTokenToStorage({ accessKey, jwtToken });

          setTokens({ accessKey, jwtToken });
        },
        onError: (error: any) => {
          setErrorMessage(t("invalidSMSCode"));
        },
      },
    );
  };

  const handleBack = () => {
    setTimeLeft(0);
    setShowSmsDrawer(false);
  };

  useEffect(() => {
    if (contentRef.current) {
      const marginTop = 4 * 14;

      setBackgroundHeight(contentRef.current.offsetHeight + marginTop);
    }
  }, [contentRef.current?.offsetHeight]);

  const description = t("smsVerificationDescription").replace(
    "XXX",
    formatPhoneNumber(phoneNumber),
  );

  return (
    <SlideInAnimation
      underlay
      isVisible={showSmsDrawer}
      className="fixed bottom-0 left-0 flex h-fit w-screen justify-center"
    >
      <div
        className="absolute left-0 top-0 h-full w-full overflow-hidden"
        style={{ height: `${backgroundHeight}px` }}
      >
        <img
          src={sms}
          alt="smsBackground"
          className="absolute left-1/2 h-full w-full -translate-x-1/2 transform object-cover object-top"
        />
      </div>

      <div ref={contentRef} className="relative z-10 mt-14 max-w-[480px]">
        <div className="relative flex h-11 w-full items-center justify-center">
          <Touchable
            className="absolute left-1 top-2.5 h-11"
            onClick={handleBack}
          >
            <ChevronLeft className="size-6 text-orange" />
          </Touchable>

          <Text lg className="font-medium">
            {t("smsVerificationTitle")}
          </Text>
        </div>

        <div className="flex flex-col items-center justify-center gap-6 px-8 py-6">
          <Text normal>{description}</Text>

          <div className="relative w-full">
            <div className="flex gap-2">
              <SmsInput smsCode={smsCode} setSmsCode={setSmsCode} />

              <CountDownAndResend
                showSmsDrawer={showSmsDrawer}
                phoneNumber={phoneNumber}
                timeLeft={timeLeft}
                setTimeLeft={setTimeLeft}
              />
            </div>

            {errorMessage ? (
              <Text normal className="absolute left-0 top-12 text-red0">
                {errorMessage}
              </Text>
            ) : null}
          </div>
        </div>

        <div className="flex justify-center pb-6 pt-4">
          <PillButton
            disabled={smsCode.length < 4}
            text={t("confirm")}
            className="h-12"
            bgColor="bg-red1"
            onClick={handleSubmit}
            isLoading={mutationForVerify.isLoading}
          />
        </div>
      </div>
    </SlideInAnimation>
  );
}
