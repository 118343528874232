import cn from "../../../utils/cn";

export default function StaticCard({
  image,
  className,
}: {
  image: string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "absolute -top-[96px] right-3 z-20 h-[122px] w-[270px]",
        className,
      )}
    >
      <img src={image} alt="main card" className="h-full w-full" />
    </div>
  );
}
