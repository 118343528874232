import cn from "../../../utils/cn";
import getDegree from "../utils/getDegree";

export default function StaticCenteredCard({
  image,
  isRect,
  backAudioIndex,
  size,
}: {
  image: string;
  isRect: boolean;
  backAudioIndex: number;
  size: string;
}) {
  const left = isRect ? 95 : 72;
  const scale = isRect ? "1.5" : "1.36";
  const deg = getDegree(isRect, backAudioIndex)[0];

  return (
    <div className={cn("absolute bottom-8 left-4 z-20", size)}>
      <div
        className={cn(size, "absolute top-2")}
        style={{
          left,
          transform: `rotate(${deg}) scale(${scale})`,
        }}
      >
        <img src={image} alt="main card" className="h-full w-full" />
      </div>
    </div>
  );
}
