import { useMutation } from "react-query";

import useStore from "../../hooks/store/useStore";
import getHeaders from "../utils/getHeaders";
import { SegmentationType } from "../../types";
import { EventTrackingType } from "./types";

function eventCode(
  accessKey: string | null,
  jwtToken: string | null,
  segmentation: SegmentationType | undefined,
) {
  return async function ({
    pageId,
    eventId,
  }: {
    [key in keyof EventTrackingType]: {
      pageId: key;
      eventId: EventTrackingType[key];
    };
  }[keyof EventTrackingType]) {
    if (!accessKey) throw new Error("No accessKey in the store");
    if (!jwtToken) throw new Error("No jwtToken in the store");

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/event-code`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...getHeaders({ accessKey, jwtToken }),
        },
        body: JSON.stringify({
          segmentation: segmentation || "",
          pageId,
          eventId,
        }),
      },
    );

    if (!response.ok) throw new Error("Network response was not ok");

    return response.json();
  };
}

export default function useEventTracking() {
  const { accessKey, jwtToken } = useStore((state) => state.tokens);
  const { userInformation } = useStore((state) => state.user);
  const { trialUserSegmentation } = userInformation || {};

  const mutation = useMutation({
    mutationFn: eventCode(
      accessKey,
      jwtToken,
      userInformation?.trialUserSegmentation,
    ),
  });

  return trialUserSegmentation === "Action"
    ? {
        mutate: (data: any) => null,
      }
    : mutation;
}
