import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PillButton from "../../../components/ui/PillButton";
import useCtaStore from "../../../hooks/store/useCtaStore";
import useStore from "../../../hooks/store/useStore";
import useEventTracking from "../../../services/EventTracking/useEventTracking";
import useOneTimeToken from "../../../services/useOneTimeToken";
import { TemplateType } from "../types";

export default function CtaButton({
  baseUrl,
  text,
  ctaExperienceTypeId,
  template,
}: {
  baseUrl: string;
  text: string;
  ctaExperienceTypeId: string;
  template: TemplateType;
}) {
  const { t } = useTranslation();

  const [url, setUrl] = useState("");

  const { setShowingCtaETId } = useCtaStore((state) => state.cta);
  const { setShowBookDemoDrawer } = useStore((state) => state.bookDemo);
  const { userInformation } = useStore((state) => state.user);
  const { trialUserSegmentation: seg } = userInformation || {};

  const mutation = useEventTracking();
  const mutationForOneTimeToken = useOneTimeToken();

  const handleEventTracking = () => {
    mutation.mutate({
      pageId: "Call to Action Submit",
      eventId: ctaExperienceTypeId,
    });
  };

  const updateUrl = async () => {
    const { tokenId } = await mutationForOneTimeToken.mutateAsync();

    setUrl(`${baseUrl}?tokenId=${tokenId}`);
  };

  useEffect(() => {
    if (seg === "Awareness") updateUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonBgColor =
    template === "Video" || template === "Feature"
      ? "bg-disneyGold"
      : "bg-grey8";

  if (seg === "Awareness") {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          handleEventTracking();
          updateUrl();
        }}
      >
        <PillButton text={text} bgColor={buttonBgColor} />
      </a>
    );
  }

  return (
    <PillButton
      text={seg === "Interest" ? text : t("gotIt")}
      bgColor={buttonBgColor}
      onClick={() => {
        handleEventTracking();
        setShowingCtaETId(null);

        if (seg === "Interest") setShowBookDemoDrawer(true);
      }}
    />
  );
}
