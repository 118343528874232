import { UserInformation } from "../../types";

/**
 * ctaExperienceTypeId: 2
 * Try to listen locked songs
 * Awareness, Interest
 */
export default function ctaHandler2(
  userInformation: UserInformation | null,
  setShowingCtaETId: (data: string | null) => void,
): boolean {
  const { trialUserSegmentation: seg } = userInformation || {};

  if (seg !== "Awareness" && seg !== "Interest") return false;

  setShowingCtaETId("2");

  return true;
}
