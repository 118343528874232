import { useTranslation } from "react-i18next";

import reverse from "../../../../assets/images/gameShared/reverse.svg";
import rotatePentagon from "../../../../assets/images/demoGame/rotatePentagon.png";
import { QACGameState } from "../../../qaCards/types";
import DemoGameButton from "../../components/DemoGameButton";

export default function ButtonHandler({
  gameState,
  onFlip,
  onRotate,
  onOk,
}: {
  gameState: QACGameState;
  onFlip: () => void;
  onRotate: () => void;
  onOk: () => void;
}) {
  const { t } = useTranslation();

  if (gameState === "waitingForFlipping")
    /* 112 = playmate:88 + paddingTop:24 */
    return (
      <DemoGameButton
        blue
        image={reverse}
        text={t("flipCard")}
        onClick={onFlip}
        className="absolute top-[112px] border-[#008EFA]"
      />
    );

  if (gameState === "waitingForRotating") {
    return (
      <div className="absolute top-[112px] flex gap-4">
        <DemoGameButton
          image={rotatePentagon}
          text={t("rotateCard")}
          onClick={onRotate}
        />
        <DemoGameButton
          blue
          text={t("ok")}
          onClick={onOk}
          className="border-[#008EFA]"
        />
      </div>
    );
  }

  return null;
}
