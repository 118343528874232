import cn from "../../../../utils/cn";

export default function StaticCard({
  image,
  className,
}: {
  image: string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "absolute -top-[80px] right-0 z-20 h-[135px] w-[300px]",
        className,
      )}
    >
      <img src={image} alt="main card" className="h-full w-full" />
    </div>
  );
}
