import { useTranslation } from "react-i18next";

import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import cn from "../../../utils/cn";

export default function Tag({
  text,
  className,
  textStyle,
  icon,
  onClick,
}: {
  text: string;
  className: string;
  textStyle?: string;
  icon: string;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Touchable
      className={cn(
        className,
        "flex w-fit items-center gap-1 rounded-md px-1.5 py-1",
      )}
      onClick={onClick}
    >
      <img src={icon} alt={text} />

      <Text normal className={cn(textStyle, "text-[11px]")}>
        {t(text)}
      </Text>
    </Touchable>
  );
}
