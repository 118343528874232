import eatF from "../../assets/audios/talkAlongCards/DWE_TAC_46_F1.mp3";
import eatB from "../../assets/audios/talkAlongCards/DWE_TAC_46_B.mp3";
import playF from "../../assets/audios/talkAlongCards/DWE_TAC_81_F1.mp3";
import playB from "../../assets/audios/talkAlongCards/DWE_TAC_81_B.mp3";
import picnicF from "../../assets/audios/talkAlongCards/DWE_TAC_189_F1.mp3";
import picnicB from "../../assets/audios/talkAlongCards/DWE_TAC_189_B.mp3";
import carsF from "../../assets/audios/talkAlongCards/DWE_TAC_199_F1.mp3";
import carsB from "../../assets/audios/talkAlongCards/DWE_TAC_199_B.mp3";

import eatFront from "../../assets/images/talkAlongCards/eatFront.png";
import playFront from "../../assets/images/talkAlongCards/playFront.png";
import picnicFront from "../../assets/images/talkAlongCards/picnicFront.png";
import carsFront from "../../assets/images/talkAlongCards/carsFront.png";
import eatBack from "../../assets/images/talkAlongCards/eatBack.png";
import playBack from "../../assets/images/talkAlongCards/playBack.png";
import picnicBack from "../../assets/images/talkAlongCards/picnicBack.png";
import carsBack from "../../assets/images/talkAlongCards/carsBack.png";

import { TACardType } from "../../types";
import { TACardAudioDataType } from "./types";

export const FlipCardFrameDuration = 250;
export const FlipCardDuration = FlipCardFrameDuration * 4;

export const tacAudioData: {
  [key in TACardType]: TACardAudioDataType;
} = {
  eat: {
    frontAudio: { audio: eatF, duration: 5000, text: "Eat. Goofy is eating." },
    backAudio: [
      { audio: eatF, duration: 5000, text: "Eat. Goofy is eating." },
      {
        audio: eatB,
        duration: 6700,
        text: "What is Goofy doing? He's eating.",
      },
    ],
  },
  play: {
    frontAudio: {
      audio: playF,
      duration: 8000,
      text: "Play. Huey, Dewey, and Louie are playing.",
    },
    backAudio: [
      {
        audio: playF,
        duration: 8000,
        text: "Play. Huey, Dewey, and Louie are playing.",
      },
      {
        audio: playB,
        duration: 8200,
        text: "What are Huey, Dewey, and Louie doing? They're playing.",
      },
    ],
  },
  picnic: {
    frontAudio: {
      audio: picnicF,
      duration: 5600,
      text: "A picnic. Mickey and Minnie are having a picnic.",
    },
    backAudio: [
      {
        audio: picnicF,
        duration: 5600,
        text: "A picnic. Mickey and Minnie are having a picnic.",
      },
      {
        audio: picnicB,
        duration: 7200,
        text: "What are Mickey and Minnie doing? They're having a picnic.",
      },
    ],
  },
  cars: {
    frontAudio: { audio: carsF, duration: 4000, text: "Cars. These are cars." },
    backAudio: [
      { audio: carsF, duration: 4000, text: "Cars. These are cars." },
      {
        audio: carsB,
        duration: 4500,
        text: "How many cars are there? Three.",
      },
    ],
  },
};

export const tacImageData: {
  [key in TACardType]: {
    frontImage: string;
    backImage: string;
  };
} = {
  eat: {
    frontImage: eatFront,
    backImage: eatBack,
  },
  play: {
    frontImage: playFront,
    backImage: playBack,
  },
  picnic: {
    frontImage: picnicFront,
    backImage: picnicBack,
  },
  cars: {
    frontImage: carsFront,
    backImage: carsBack,
  },
};
