import { LrcDataType } from "../types";

export default function parseLrc(lrcText: string): LrcDataType[] {
  const lines = lrcText.split("\n");
  const parsedLyrics = lines
    .map((line) => {
      const timeMatch = line.match(/\[(\d{2}):(\d{2})\.(\d{2})\]/);
      const textMatch = line.match(/\](.*)/);

      if (timeMatch && textMatch) {
        const minutes = parseInt(timeMatch[1]);
        const seconds = parseInt(timeMatch[2]);
        const milliseconds = parseInt(timeMatch[3]);
        const totalMilliseconds =
          minutes * 60 * 1000 + seconds * 1000 + milliseconds;
        const time = parseFloat((totalMilliseconds / 1000).toFixed(2));
        const text = textMatch[1].trim();

        return { time, text };
      }

      return null;
    })
    .filter((lyric): lyric is LrcDataType => lyric !== null);

  return parsedLyrics;
}
