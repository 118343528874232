import { ReactNode } from "react";
import { animated, useSpring } from "@react-spring/web";

import {
  slideInDrawerNormalZIndex,
  slideInDrawerUnderlayZIndex,
  slideInDrawerZIndex,
} from "../../styles/zIndex";
import cn from "../../utils/cn";

export default function SlideInAnimation({
  isVisible,
  children,
  className,
  underlay,
}: {
  isVisible: boolean;
  children: ReactNode;
  className?: string;
  underlay?: boolean;
}) {
  const styles = useSpring({
    transform: isVisible ? "translateY(0%)" : "translateY(100%)",
  });

  const underlayStyles = useSpring({
    opacity: isVisible ? 1 : 0,
  });

  const zIndex = underlay ? slideInDrawerZIndex : slideInDrawerNormalZIndex;

  return (
    <>
      <animated.div style={styles} className={cn(className, zIndex)}>
        {children}
      </animated.div>

      {underlay && isVisible ? (
        <animated.div
          style={underlayStyles}
          className={cn(
            "fixed bottom-0 left-0 h-screen w-screen bg-grey8/70",
            slideInDrawerUnderlayZIndex,
          )}
        />
      ) : null}
    </>
  );
}
