import cn from "../../../../utils/cn";
import getDegree from "../utils/getDegree";

export default function StaticCard({
  image,
  isRect,
  backAudioIndex,
  size,
}: {
  image: string;
  isRect?: boolean;
  backAudioIndex?: number;
  size?: string;
}) {
  const deg =
    backAudioIndex === undefined
      ? "0deg"
      : getDegree(Boolean(isRect), backAudioIndex)[0];

  return (
    <div
      className={cn("absolute bottom-8 left-8 z-20", size)}
      style={{
        transform: `rotate(${deg})`,
      }}
    >
      <img src={image} alt="main card" className="h-full w-full" />
    </div>
  );
}
