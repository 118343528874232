import { ReactNode } from "react";

import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import useStore from "../../../hooks/store/useStore";
import useNavigateWithParams from "../../../hooks/useNavigateWithParams";
import { EventTrackingGameType } from "../../../services/EventTracking/types";
import useEventTracking from "../../../services/EventTracking/useEventTracking";
import cn from "../../../utils/cn";
import { GameType } from "../../../types";

const data: {
  gameType: GameType;
  eventId: EventTrackingGameType;
}[] = [
  {
    gameType: "magicPenHome",
    eventId: "Magic Pen",
  },
  {
    gameType: "talkAlongCards",
    eventId: "Talk Along Cards",
  },
  {
    gameType: "QACards",
    eventId: "Q & A Cards",
  },
];

export default function MenuCard({
  text,
  url,
  border,
  children,
  dataIndex,
}: {
  text: string;
  url: string;
  border: string;
  children: ReactNode;
  dataIndex: number;
}) {
  const navigateWithParams = useNavigateWithParams();
  const { setSelectedGame } = useStore((state) => state.global);

  const mutation = useEventTracking();

  const handleMenuClick = () => {
    const { gameType, eventId } = data[dataIndex];

    mutation.mutate({
      pageId: "Playing",
      eventId,
    });

    setSelectedGame(gameType);
    navigateWithParams("/game");
  };

  return (
    <Touchable
      className={cn(
        border,
        "relative overflow-hidden rounded-2xl border border-solid",
      )}
      onClick={handleMenuClick}
    >
      <img src={url} alt={text} />

      {children}

      <div className="absolute bottom-0 left-0 flex aspect-[71/8] w-full items-center pl-3">
        <Text lg className="font-medium">
          {text}
        </Text>
      </div>
    </Touchable>
  );
}
