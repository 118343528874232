import { Dispatch, SetStateAction, useState } from "react";
import ReactPlayer from "react-player";

import VideoModel from "../../../components/ui/VideoModel";
import { getVimeoId } from "../utils/getVideoUrl";

export default function EventVideo({
  videoUrl,
  setShowVideo,
  setCurrentVideoIndex,
}: {
  videoUrl: string;
  setShowVideo: Dispatch<SetStateAction<boolean>>;
  setCurrentVideoIndex?: Dispatch<SetStateAction<number>>;
}) {
  const isVimeo = Boolean(getVimeoId(videoUrl));

  const [isReady, setIsReady] = useState(false);

  return (
    <VideoModel
      videoUrl={videoUrl}
      setShowVideo={setShowVideo}
      onClose={() => {
        if (setCurrentVideoIndex) setCurrentVideoIndex(-1);
      }}
    >
      {!isReady ? (
        <div className="absolute left-0 top-0 z-[1] h-full w-full bg-black" />
      ) : null}

      {isVimeo ? (
        <iframe
          title={videoUrl}
          src={videoUrl}
          width="100%"
          className="aspect-[16/9]"
          allow="autoplay; fullscreen"
          allowFullScreen
          onLoad={() => setIsReady(true)}
        />
      ) : (
        <ReactPlayer
          width="100%"
          aspectratio="16:9"
          url={videoUrl}
          playing
          playsinline
          muted
          controls
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
                preload: "auto",
              },
            },
          }}
          onReady={() => setIsReady(true)}
        />
      )}
    </VideoModel>
  );
}
