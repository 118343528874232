export default function getBodyTW(name: string, phone: string) {
  return JSON.stringify({
    time_submitted: "",
    page_uuid: "",
    page_name: "",
    date_submitted: "",
    ip_address: "",
    page_url: "",
    variant: "",
    customerName: name,
    mobilePhone: phone,
    address: "",
    source: "DR-APP01",
    sourceCode: "",
    webpage: "swagger1",
    urlReferrer: "swagger2",
    aid: "",
    affiliate_subid2: "",
    ip: "1.1.1.2",
    onlineDemo: "",
  });
}
