import getHeaders from "./getHeaders";

export default function queryFunction(path: string) {
  return async function userInformation(
    accessKey: string | null,
    jwtToken: string | null
  ) {
    if (!accessKey) throw new Error("No accessKey in the store");
    if (!jwtToken) throw new Error("No jwtToken in the store");

    const response = await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
      method: "GET",
      headers: getHeaders({ accessKey, jwtToken }),
    });

    if (!response.ok) throw new Error("Network response was not ok");

    return response.json();
  };
}
