import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "lucide-react";

import play3 from "../../assets/images/shared/play-3.svg";
import thumbnail from "../../assets/images/story/thumbnail.png";
import arrow from "../../assets/images/story/arrow.png";
import Text from "../../components/ui/Text";
import Touchable from "../../components/ui/Touchable";
import VimeoVideo from "../../components/ui/VimeoVideo";
import useNavigateWithParams from "../../hooks/useNavigateWithParams";
import BookButton from "../bookDemo/BookButton";
import FlipBook from "./components/FlipBook";

export default function Story() {
  const { t } = useTranslation();

  const [showVideo, setShowVideo] = useState<boolean>(false);

  const navigateWithParams = useNavigateWithParams();

  return (
    <div className="flex h-screen w-screen justify-center bg-pink1">
      <div className="relative flex min-h-screen w-screen min-w-[320px] max-w-[390px] flex-col bg-pink1">
        <Touchable
          className="relative z-40 flex h-11 w-full items-center"
          onClick={() => navigateWithParams()}
        >
          <ChevronLeft className="mx-1 size-6 text-orange" />
        </Touchable>

        <Text className="mx-6 mb-4 text-[22px]">{t("story")}</Text>

        <FlipBook />

        <div className="relative mt-[455px] flex h-fit w-full gap-0.5 px-4">
          <div className="h-fit w-[171px]">
            <Touchable
              className="relative w-[171px] overflow-hidden rounded-lg shadow-custom0"
              onClick={() => setShowVideo(true)}
            >
              <img src={thumbnail} alt="thumbnail" />

              <img
                src={play3}
                alt="play3"
                className="absolute bottom-1.5 right-1.5 z-[2] size-9"
              />
            </Touchable>

            <Text normal className="mt-2">
              Straight Play 4
            </Text>
          </div>

          <div className="relative left-0 top-[26px] flex h-fit flex-1 items-center">
            <img
              src={arrow}
              alt="arrow"
              className="relative z-[1] w-2 shrink-0"
            />

            <div className="h-fit w-full rounded-lg bg-white px-3 py-4 shadow-custom0">
              <Text normal className="text-blue">
                {t("storyVideoDescription")}
              </Text>
            </div>
          </div>
        </div>

        {showVideo ? (
          <VimeoVideo
            videoTitle="Straight Play 4"
            videoUrl="https://player.vimeo.com/video/1031468559?title=0&byline=0&portrait=0"
            setShowVideo={setShowVideo}
          />
        ) : null}

        <BookButton sourcePage="Story" />
      </div>
    </div>
  );
}
