import { useTranslation } from "react-i18next";

import car from "../../../assets/images/story/car.png";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import useNavigateWithParams from "../../../hooks/useNavigateWithParams";

export default function DisneyButton() {
  const { t } = useTranslation();

  const navigateWithParams = useNavigateWithParams();

  return (
    <Touchable
      className="relative flex h-[54px] items-center gap-2 rounded-full border-2 border-solid border-[#0BBEFF4C] bg-white py-1 pl-6 pr-2"
      onClick={() => {
        navigateWithParams("/story");
      }}
    >
      <Text lg className="text-skyBlue">
        {t("letsGetStarted")}
      </Text>

      <div className="size-[46px] rounded-full bg-[#E7F8FF]" />

      <img
        src={car}
        alt="car"
        className="absolute bottom-0 right-[-20px] w-20"
      />
    </Touchable>
  );
}
