import { MagicPenGameState } from "../types";

export function isPhase1(gameState: MagicPenGameState) {
  return (
    gameState === "waitingClick1" ||
    gameState === "penMoving1" ||
    gameState === "questionPlaying"
  );
}

export function isTextPhase1(gameState: MagicPenGameState) {
  return (
    gameState === "waitingClick1" ||
    gameState === "penMoving1" ||
    gameState === "questionPlaying" ||
    gameState === "waitingClick2"
  );
}
