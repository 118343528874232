import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "lucide-react";

import Loader from "../../components/ui/Loader";
import Text from "../../components/ui/Text";
import Touchable from "../../components/ui/Touchable";
import useCtaStore from "../../hooks/store/useCtaStore";
import useStore from "../../hooks/store/useStore";
import useNavigateWithParams from "../../hooks/useNavigateWithParams";
import BookButton from "../../pages/bookDemo/BookButton";
import CaringCta from "../../pages/caringCta/CaringCta";
import { GameSourcePageMapping } from "../../services/EventTracking/mapping";
import cn from "../../utils/cn";
import { SourceType } from "../../types";
import { gameMapping, magicPenMaxWidth } from "./constants";

export default function GameLayout() {
  const { t } = useTranslation();
  const navigateWithParams = useNavigateWithParams();

  const { source, selectedGame, setSelectedGame } = useStore(
    (state) => state.global,
  );
  const { showingCtaETId } = useCtaStore((state) => state.cta);

  useEffect(() => {
    if (!selectedGame) navigateWithParams();
  }, [selectedGame, navigateWithParams]);

  if (source === SourceType.DemoApp) return <div>Page Not Found</div>;

  if (!selectedGame) return <Loader />;

  const wrapperStyle =
    selectedGame === "magicPen"
      ? "hide-scrollbar relative flex min-h-screen w-full overflow-y-auto bg-[#ffe5d1] justify-center"
      : "flex min-h-screen w-full justify-center bg-pink1";
  const maxWidth =
    selectedGame === "magicPen"
      ? `max-w-[${magicPenMaxWidth}px]`
      : selectedGame === "magicPenHome"
        ? "max-w-[480px]"
        : "max-w-[390px]";

  const displayBookButton =
    selectedGame !== "magicPen" && source === SourceType.ClubApp;

  return (
    <div className={cn(wrapperStyle, "overflow-hidden")}>
      <div
        className={cn(
          maxWidth,
          "flex min-h-screen w-screen min-w-[320px] flex-col bg-pink1",
        )}
      >
        <Touchable
          className="flex h-11 w-full cursor-pointer items-center"
          onClick={() => {
            navigateWithParams();
            setSelectedGame(null);
          }}
        >
          <ChevronLeft className="mx-1 size-6 text-orange" />

          <Text normal>{t("playing")}</Text>
        </Touchable>

        {gameMapping[selectedGame]}

        <BookButton
          sourcePage={GameSourcePageMapping[selectedGame]}
          displayBookButton={displayBookButton}
        />

        {showingCtaETId ? (
          <CaringCta ctaExperienceTypeId={showingCtaETId} />
        ) : null}
      </div>
    </div>
  );
}
