export default function formatPhoneNumber(number: string): string {
  const koreaRegex = /^010\d{8}$/;
  const taiwanRegex = /^09\d{8}$/;
  const hongKongRegex = /^[69]\d{7}$/;

  if (taiwanRegex.test(number)) {
    return `${number.slice(0, 4)}-xxx-xx${number.slice(9, 10)}`;
  } else if (koreaRegex.test(number)) {
    return `${number.slice(0, 3)}-${number.slice(3, 4)}xxx-${number.slice(7, 8)}xxx`;
  } else if (hongKongRegex.test(number)) {
    return `${number.slice(0, 4)}-xxxx`;
  } else {
    return "Invalid number";
  }
}
