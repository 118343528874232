import mickeyPen from "../../../assets/images/magicPen/mickeyPen.png";
import minniePen from "../../../assets/images/magicPen/minniePen.png";
import mickeyShadow from "../../../assets/images/magicPen/mickeyShadow.png";
import minnieShadow from "../../../assets/images/magicPen/minnieShadow.png";
import { MagicPenType } from "../../../types";

export default function PenImage({ type }: { type: MagicPenType }) {
  const image = type === "mickey" ? mickeyPen : minniePen;
  const shadow = type === "mickey" ? mickeyShadow : minnieShadow;

  return (
    <div className="relative -left-3 top-1 w-[130px]">
      <img src={image} alt="pen" className="relative z-10" />
      <img src={shadow} alt="shadow" className="absolute left-6 top-2" />
    </div>
  );
}
