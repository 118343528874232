import { animated, useSpring } from "@react-spring/web";

import cn from "../../../../utils/cn";

export default function AnimatedCard2({
  image,
  animationConfig,
  size,
}: {
  image: string;
  animationConfig?: any;
  size?: string;
}) {
  const animation = useSpring(animationConfig);

  return (
    <div className={cn("absolute bottom-8 left-8 z-20", size)}>
      <animated.div className={cn(size, "absolute top-2")} style={animation}>
        <img src={image} alt="main card" className="h-full w-full" />
      </animated.div>
    </div>
  );
}
