import cn from "../../utils/cn";
import Text from "../ui/Text";
import Touchable from "../ui/Touchable";

export default function GameButton({
  text,
  onClick,
  image,
  className,
  blue = false,
}: {
  text: string;
  onClick: () => void;
  image?: string;
  className?: string;
  blue?: boolean;
}) {
  const bgColor = blue ? "bg-skyBlue" : "bg-white";
  const textColor = blue ? "text-white" : "text-skyBlue";

  return (
    <Touchable
      className={cn(
        bgColor,
        "flex h-[54px] items-center gap-1.5 rounded-full border-2 border-solid border-skyBlue3 px-8",
        className,
      )}
      onClick={onClick}
    >
      <Text lg className={cn(textColor)}>
        {text}
      </Text>

      {image ? <img src={image} alt="button" className="size-6" /> : null}
    </Touchable>
  );
}
