import { ReactNode } from "react";

import cn from "../../utils/cn";
import Touchable from "./Touchable";

export default function Card({
  children,
  url,
  isLink,
  className,
  onClick,
}: {
  children: ReactNode;
  url: string;
  isLink: boolean;
  className?: string;
  onClick?: () => void;
}) {
  return isLink ? (
    <a href={url} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <Touchable
        className={cn("w-full overflow-hidden rounded-lg bg-white", className)}
      >
        {children}
      </Touchable>
    </a>
  ) : (
    <Touchable
      className={cn("w-full overflow-hidden rounded-lg bg-white", className)}
      onClick={onClick}
    >
      {children}
    </Touchable>
  );
}
