import { Loader2 } from "lucide-react";

import cn from "../../utils/cn";
import Text from "./Text";
import Touchable from "./Touchable";

export default function PillButton({
  text,
  className,
  bgColor,
  onClick,
  disabled,
  isLoading,
}: {
  text: string;
  className?: string;
  bgColor?: string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  isLoading?: boolean;
}) {
  const buttonBgColor = bgColor ? bgColor : "bg-grey8";

  if (isLoading)
    return (
      <div
        className={cn(
          "relative flex h-14 w-fit items-center justify-center rounded-full px-8 opacity-[0.38]",
          buttonBgColor,
          className,
        )}
      >
        <Text className="text-white opacity-0">{text}</Text>
        <Loader2
          className="centered-absolute animate-spin text-white"
          size={24}
        />
      </div>
    );

  return disabled ? (
    <div
      className={cn(
        "flex h-14 w-fit items-center justify-center rounded-full px-8 opacity-[0.38]",
        buttonBgColor,
        className,
      )}
    >
      <Text className="text-white">{text}</Text>
    </div>
  ) : (
    <Touchable
      className={cn(
        "flex h-14 w-fit items-center justify-center rounded-full px-8",
        buttonBgColor,
        className,
      )}
      onClick={onClick}
    >
      <Text className="text-white">{text}</Text>
    </Touchable>
  );
}
