import interest from "../../../assets/images/caringCta/interested.png";
import didYouNotice from "../../../assets/images/caringCta/didYouNotice.png";

import { TemplateType } from "../types";

export default function CtaTitle({
  ctaTitle,
  template,
}: {
  ctaTitle: string;
  template: TemplateType;
}) {
  if (template === "Video") return null;
  if (ctaTitle === "Interested?")
    return <img src={interest} alt="Interested?" className="mb-2" />;
  if (ctaTitle === "Did you notice?")
    return <img src={didYouNotice} alt="Did you notice?" className="mb-2" />;

  return (
    <h1 className="white-stroke mb-2 text-center text-2xl font-semibold text-grey8">
      {ctaTitle}
    </h1>
  );
}
