import { UserInformation } from "../../types";

/**
 * ctaExperienceTypeId: 6
 * After selecting another Talk Along Card
 * Awareness, Interest
 */
export default function ctaHandler6(
  userInformation: UserInformation | null,
  setShowingCtaETId: (data: string | null) => void,
) {
  const { trialUserSegmentation: seg } = userInformation || {};

  if (seg !== "Awareness" && seg !== "Interest") return;

  setShowingCtaETId("6");
}
