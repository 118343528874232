import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { addDays, addMonths, isAfter, isBefore } from "date-fns";

import useStore from "../../hooks/store/useStore";
import { DatePicker } from "../../components/shadcn/datePicker";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "../../components/shadcn/select";
import Text from "../../components/ui/Text";
import Mask from "../../components/ui/Mask";
import generateTokenHK from "../../services/bookDemo/generateTokenHK";
import bookDemoFnHK from "../../services/bookDemo/bookDemoFnHK";
import Button from "./components/Button";
import RadioButton from "./components/RadioButton";

export default function BookDemoHK() {
  const { t } = useTranslation();

  const { setShowBookDemoDrawer, setShowBookDemoThanks } = useStore(
    (state) => state.bookDemo,
  );
  const { userInformation } = useStore((state) => state.user);
  const { userName, phoneNumber } = userInformation || {};

  const [location, setLocation] = useState("");
  const [date, setDate] = useState<Date>();
  const [time, setTime] = useState("上午");

  const currentDate = new Date();
  const fourDaysLater = addDays(currentDate, 4);
  const twoMonthsLater = addMonths(currentDate, 2);

  const mutationForToken = useMutation({
    mutationFn: generateTokenHK,
    onSuccess: (data) => {
      mutationForSubmit.mutate(data.token);
    },
  });
  const mutationForSubmit = useMutation({
    mutationFn: bookDemoFnHK(userName, phoneNumber, location, date, time),
    onSuccess: () => {
      setShowBookDemoDrawer(false);
      setShowBookDemoThanks(true);
    },
  });
  const isLoading = mutationForSubmit.isLoading || mutationForToken.isLoading;

  return (
    <div className="flex justify-center">
      <div className="flex w-full max-w-[310px] flex-col gap-10 pb-[68px]">
        <div>
          <Text className="text-xl">{t("bookDemoTitle")}</Text>

          <Text normal className="my-3">
            {userName + t("bookDemoDesc1")}
          </Text>
          <Text normal className="my-3">
            {t("bookDemoDesc2")}
          </Text>
        </div>

        <div className="flex flex-col gap-3">
          <Text lg className="font-medium">
            預約體驗地點
          </Text>

          <RadioButton
            text="荔枝角 D2 Place 寰宇家庭俱樂部"
            value="D2_Place"
            location={location}
            setLocation={setLocation}
          />
          <RadioButton
            text="澳門活動教學中心"
            value="macau"
            location={location}
            setLocation={setLocation}
          />
        </div>

        <div className="flex flex-col gap-3">
          <Text lg className="font-medium">
            預約體驗日期
          </Text>

          <div className="flex gap-1">
            <DatePicker
              date={date}
              setDate={setDate}
              disabled={(date) =>
                isBefore(date, fourDaysLater) || isAfter(date, twoMonthsLater)
              }
            />

            <Select
              onValueChange={(value) => setTime(value)}
              defaultValue="上午"
            >
              <SelectTrigger className="h-11 w-20 rounded-full border border-solid border-grey2 bg-white px-3 py-2.5">
                <Text>{time}</Text>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="上午">上午</SelectItem>
                <SelectItem value="下午">下午</SelectItem>
                <SelectItem value="晚上">晚上</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <Button
          text={t("bookDemoAction")}
          isLoading={isLoading}
          onClick={() => {
            mutationForToken.mutate();
          }}
          disabled={!location || !date}
        />

        {isLoading ? <Mask /> : null}
      </div>
    </div>
  );
}
