import {
  parseISO,
  isPast,
  differenceInDays,
  startOfDay,
  addDays,
} from "date-fns";

import { UserInformation } from "../../types";

/**
 * ctaExperienceTypeId: 12 / 13 / 14
 * Booked demo after 20 / 25 / 29 days
 * Decision
 */
export default function ctaHandler12to14(
  userInformation: UserInformation | null,
  setShowingCtaETId: (data: string | null) => void,
) {
  const { trialUserSegmentation: seg, expiredDate } = userInformation || {};

  if (seg !== "Decision" || !expiredDate) return;

  const expireTime = startOfDay(addDays(parseISO(expiredDate), 1));

  if (isPast(expireTime)) return;

  const today = new Date();
  const difference = differenceInDays(expireTime, today);

  if (difference >= 10) return;

  const id = difference === 0 ? "14" : difference <= 4 ? "13" : "12";

  setShowingCtaETId(id);
}
