import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDraggable } from "@dnd-kit/core";

import mickeyPen from "../../../../assets/images/magicPen/mickeyPen2.png";
import minniePen from "../../../../assets/images/magicPen/minniePen2.png";
import useStore from "../../../../hooks/store/useStore";
import { penLeft, penTop } from "../constants";
import { PositionType } from "../types";

const menuWidth = 218;
const boardWidth = 600;
const penWidth = 110;

const gameHeight = 437; // dialog:40 + gap:32 + board:365
const dialogHeight = 40;
const gapHeight = 32;
const penHeight = 203;

export default function DraggablePen({
  penPosition,
  setPenPosition,
}: {
  penPosition: PositionType;
  setPenPosition: Dispatch<SetStateAction<PositionType>>;
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth);
  const [deviceHeight, setDeviceHeight] = useState<number>(window.innerHeight);

  const { selectedPen } = useStore((state) => state.global);

  const { attributes, listeners } = useDraggable({
    id: "pen",
  });

  const handleDragMove = (event: MouseEvent | TouchEvent) => {
    const { clientX, clientY } = "touches" in event ? event.touches[0] : event;

    const leftSpace = (deviceWidth - menuWidth - boardWidth) / 2;
    const topSpace = (deviceHeight - gameHeight) / 2 + dialogHeight + gapHeight;

    const x = clientX - menuWidth - leftSpace - penWidth / 3;
    const y = clientY - topSpace - penHeight / 3;

    setPenPosition({ x, y });
  };

  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
      setDeviceHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleDragMove);
    } else {
      window.removeEventListener("mousemove", handleDragMove);
    }

    return () => {
      window.removeEventListener("mousemove", handleDragMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const pen = selectedPen === "mickey" ? mickeyPen : minniePen;

  return (
    <div
      {...attributes}
      {...listeners}
      className="absolute z-50"
      style={{
        top: penTop,
        left: penLeft,
        touchAction: "none",
        transform: `translate(${penPosition.x}px, ${penPosition.y}px)`,
      }}
      // @ts-ignore
      onTouchMove={handleDragMove}
      onMouseDown={() => setIsDragging(true)}
      onMouseUp={() => setIsDragging(false)}
    >
      <img src={pen} alt="pen" className="w-[110px]" />
    </div>
  );
}
