import subtitle from "../../../assets/images/gameShared/subtitle.png";
import Text from "../../../components/ui/Text";
import cn from "../../../utils/cn";

export default function DemoGameDialog({
  text,
  className,
  blue,
}: {
  text: string;
  className?: string;
  blue?: boolean;
}) {
  const bgColor = blue ? "bg-skyBlue2" : "bg-white";

  return (
    <div
      className={cn(
        "flex items-center gap-1 rounded-lg px-3 py-1",
        bgColor,
        className,
      )}
    >
      <img src={subtitle} alt="subtitle" className="size-8" />

      <Text typography="h3" className="text-[#008EFA]">
        {text}
      </Text>
    </div>
  );
}
