import { MarketType } from "../../../types";
import openDownloadPage from "./openDownloadPage";

declare global {
  interface Window {
    MSStream: any;
  }
}

export default function openApp(market: MarketType) {
  const userAgent = navigator.userAgent;
  let deepLinkUrl;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    deepLinkUrl = "app://HomeStack";
  } else if (/android/i.test(userAgent)) {
    deepLinkUrl = "https://deeplink.worldfamilyenglish.com/app/HomeStack";
  }

  if (!deepLinkUrl) return;

  /**
   * If the app does not open, redirect to the download page
   */
  const delayTime = 2500;
  const timeout = setTimeout(() => {
    window.removeEventListener("blur", handleBlur);

    openDownloadPage(market);
  }, delayTime);

  window.location.href = deepLinkUrl;

  const handleBlur = () => {
    clearTimeout(timeout);

    window.removeEventListener("blur", handleBlur);
  };

  window.addEventListener("blur", handleBlur);
}
