import { webLoginStoragekey } from "../constants";

export default function getWebLoginTokenFromStorage() {
  const data = localStorage.getItem(webLoginStoragekey);

  if (!data) return null;

  const { expireTime, accessKey, jwtToken } = JSON.parse(data);
  const now = new Date();

  if (now.getTime() > expireTime) {
    localStorage.removeItem(webLoginStoragekey);

    return null;
  }

  return { accessKey, jwtToken };
}
