import playmate from "../../../../assets/images/gameShared/playmate.png";
import { showPlaymate } from "../../../qaCards/utils/judgements";
import { QACGameState } from "../../../qaCards/types";

export default function Playmate({ gameState }: { gameState: QACGameState }) {
  return showPlaymate(gameState) ? (
    <img
      src={playmate}
      alt="playmate"
      className="relative z-50 h-[88px] w-[308px]"
    />
  ) : (
    <div className="h-[88px] w-[308px]" />
  );
}
