import { MarketType } from "../../../types";

export default function navigateToLeadPage(
  market: MarketType,
  tokenId: string,
) {
  const url =
    market === "TW"
      ? process.env.REACT_APP_API_URL_LEAD_TW
      : market === "KR"
        ? process.env.REACT_APP_API_URL_LEAD_KR
        : process.env.REACT_APP_API_URL_LEAD_HK;

  const searchParams = new URLSearchParams(window.location.search);
  const advisorCode = searchParams.get("EA");
  const advisorCodeParam = advisorCode ? `&EA=${advisorCode}` : "";

  if (url)
    window.location.href = `${url}?installSource=digitalExperienceWeb&tokenId=${tokenId}${advisorCodeParam}`;
}
