import { useTranslation } from "react-i18next";

import help from "../../assets/images/gameShared/help.png";
import Text from "../ui/Text";
import Touchable from "../ui/Touchable";

export default function GameTitle({
  text,
  onHelpClick,
}: {
  text: string;
  onHelpClick?: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between px-6 py-2">
      <Text className="text-2xl">{text}</Text>

      {onHelpClick ? (
        <Touchable
          className="flex h-[26px] items-center justify-center gap-1 rounded-full bg-white py-[1px] pl-0.5 pr-2"
          onClick={onHelpClick}
        >
          <img src={help} alt="help" className="size-6" />

          <Text className="text-blue">{t("help")}</Text>
        </Touchable>
      ) : null}
    </div>
  );
}
