import { FlipCardFrameDuration } from "../../talkAlongCards/constants";

export const moveToLeft = (duration: number) => ({
  to: async (next: any) => {
    await next({
      transform: "translateX(0px) translateY(0px)",
      immediate: true,
    });
    await next({ transform: "translateX(-300px) translateY(0px)" });
  },
  from: { transform: "translateX(0px) translateY(0px)" },
  config: { duration },
});

export const circleToRight = {
  to: async (next: any) => {
    await next({
      transform: "translateX(-300px) translateY(0px)",
      immediate: true,
    });
    await next({ transform: "translateX(-262.5px) translateY(-100px)" });
    await next({ transform: "translateX(-150px) translateY(-100px)" });
    await next({ transform: "translateX(-37.5px) translateY(-100px)" });
    await next({ transform: "translateX(0px) translateY(0px)" });
  },
  from: { transform: "translateX(0px) translateY(0px)" },
  config: { duration: FlipCardFrameDuration },
};
