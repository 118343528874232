import { useQuery } from "react-query";

import useStore from "../../hooks/store/useStore";
import queryFunction from "./queryFunction";

export default function useQueryCustom(
  key: string,
  path: string,
  enabled: boolean = true,
) {
  const { accessKey, jwtToken } = useStore((state) => state.tokens);

  return useQuery({
    queryKey: [key, accessKey, jwtToken],
    queryFn: () => queryFunction(path)(accessKey, jwtToken),
    enabled,
  });
}
