export default function isValidPhoneNumber(number: string) {
  const koreaRegex = /^010\d{8}$/;
  const taiwanRegex = /^09\d{8}$/;
  const hongKongRegex = /^[69]\d{7}$/;

  return (
    koreaRegex.test(number) ||
    taiwanRegex.test(number) ||
    hongKongRegex.test(number)
  );
}
