import { RefObject } from "react";

import "./ProgressBar.css";

const formatTime = (time: number): string => {
  if (!isNaN(time)) {
    const minutes = Math.floor(time / 60);
    const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(time % 60);
    const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formatMinutes}:${formatSeconds}`;
  }

  return "00:00";
};

export default function ProgressBar({
  audioRef,
  progressBarRef,
  timeProgress,
}: {
  audioRef: RefObject<HTMLAudioElement>;
  progressBarRef: RefObject<HTMLInputElement>;
  timeProgress: number;
}) {
  const handleProgressChange = () => {
    if (audioRef.current && progressBarRef.current)
      audioRef.current.currentTime = Number(progressBarRef.current.value);
  };

  return (
    <div className="mt-2 flex flex-col gap-1.5">
      <input
        type="range"
        ref={progressBarRef}
        defaultValue="0"
        onChange={handleProgressChange}
      />

      <div className="flex justify-between text-grey8 opacity-70">
        <span>{formatTime(timeProgress)}</span>
        <span>{formatTime(audioRef?.current?.duration || 0)}</span>
      </div>
    </div>
  );
}
