import { animated, useSpring } from "@react-spring/web";

import playmate from "../../../assets/images/gameShared/playmate.png";
import { showPlaymate } from "../utils/judgements";
import { LandingPlaymateDuration } from "../constants";
import { QACGameState } from "../types";

function AnimatedPlaymate() {
  const animation = useSpring({
    from: {
      opacity: 0,
    },
    to: { opacity: 1 },
    config: {
      duration: LandingPlaymateDuration,
      delay: 2000,
    },
  });

  return (
    <animated.img
      src={playmate}
      alt="playmate"
      className="relative z-50 mt-40 w-[220px]"
      style={animation}
    />
  );
}

export default function Playmate({ gameState }: { gameState: QACGameState }) {
  if (gameState === "landing2") return <AnimatedPlaymate />;

  return showPlaymate(gameState) ? (
    <img src={playmate} alt="playmate" className="relative z-50 w-[220px]" />
  ) : (
    <div className="h-[62.73px] w-[220px]" />
  );
}
