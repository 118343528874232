import { Loader2 } from "lucide-react";

import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import cn from "../../../utils/cn";

export default function Button({
  text,
  onClick,
  isLoading,
  disabled,
}: {
  text: string;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}) {
  const bgColor = disabled ? "bg-black4" : "bg-grey8";
  const opacity = isLoading ? "opacity-0" : "";

  return (
    <div className="flex w-full justify-center">
      <Touchable
        className={cn(
          bgColor,
          "relative flex h-[54px] w-fit items-center rounded-full px-6",
        )}
        onClick={() => {
          if (!disabled) onClick();
        }}
      >
        <Text lg className={cn(opacity, "font-medium text-white")}>
          {text}
        </Text>

        {isLoading ? (
          <Loader2
            className="centered-absolute animate-spin text-white"
            size={24}
          />
        ) : null}
      </Touchable>
    </div>
  );
}
